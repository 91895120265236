import axios, { InternalAxiosRequestConfig } from "axios";
import authService from "./services/authService";

axios.interceptors.request.use(
  async (
    request: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const userToken = await authService.getToken();
    const authenticatedRequest = request;
    authenticatedRequest.headers.Authorization =
      userToken || request.headers?.authorization;
    return authenticatedRequest;
  },
  function (error) {
    return Promise.reject(error);
  }
);
