import _ from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Button, MultiSelect, Switch } from "@sumit-platforms/ui-bazar";
import { User, UserRole } from "@sumit-platforms/types";

import "./LinkUserModal.scss";

export interface LinkUserModalProps {
  confirm: (idUser: number, idRole: UserRole) => void;
  cancel: () => void;
  users: User[];
}

export const LinkUserModal: FC<LinkUserModalProps> = ({
  confirm,
  cancel,
  users,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number[]>([]);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const handleOnConfirm = async () => {
    if (!_.isEmpty(selectedUser) && !_.isEmpty(userRoles)) {
      setIsLoading(true);
      await confirm(selectedUser[0], userRoles[0]);
      setIsLoading(false);
    }
  };

  return (
    <Grid className={"LinkUserModal"} container width={"30rem"}>
      <Grid container px={4} pt={4} pb={3}>
        <Box width={"100%"}>
          <Box pb={3} display={"flex"} justifyContent={"center"}>
            <h4>{t("link_new_user")}</h4>
          </Box>
          <Box>
            <p>{t("select_user_to_link")}</p>
          </Box>
        </Box>
      </Grid>
      <Grid container px={4} pb={3}>
        <Grid item xs={8} pb={2}>
          <Box className={"usersSelect"} sx={{ position: "relative" }}>
            <MultiSelect
              isMulti={false}
              placeholder={t("select_user")}
              setSelected={setSelectedUser}
              selected={selectedUser}
              options={users.map((u: User) => ({
                label: u.email,
                value: u.idUser,
              }))}
            />
          </Box>
        </Grid>
        <Grid item xs={8} pb={2}>
          <Box className={"userRoleInput"}>
            <MultiSelect
              selected={userRoles}
              setSelected={(selected) => setUserRoles(selected)}
              placeholder={t("select_user_role")}
              isMulti={false}
              options={[
                UserRole.CLIENT_ADMIN,
                UserRole.CLIENT_USER,
                UserRole.CS_MANAGER,
              ].map((role) => ({
                label: t(role.toLowerCase()),
                value: role,
              }))}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          pb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <p>{t("define_as_a_contact")}:</p>
          </Box>
          <Box>
            <Switch />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          disabled={_.isEmpty(selectedUser) || _.isEmpty(userRoles)}
          onClick={handleOnConfirm}
          color="primary"
          loading={isLoading}
        >
          {t("link_user")}
        </Button>
      </Grid>
    </Grid>
  );
};
