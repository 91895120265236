import _ from "lodash";
import { Box, Grid } from "@mui/material";
import {
  Button,
  FileInput,
  Input,
  MultiSelect,
  Switch,
  TimerPicker,
} from "../../../index";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import {
  Diarize,
  DiarizeTypes,
  MulterFileInfo,
  UploadSettings,
} from "@sumit-platforms/types";

import "./UploadSettingsModal.scss";
import { getTimeNumberFromString } from "../../utils/formatters";

export interface UploadSettingsModalProps {
  settings: UploadSettings;
  save: (uploadSettings: UploadSettings) => Promise<void>;
  cancel: () => void;
  uploadFile: (file: File) => Promise<MulterFileInfo | null>;
  title?: string;
  isMultiple?: boolean;
}

type SettingField = {
  key: string;
  label: string;
  type: string;
  defaultValue?: any;
  onChange?: (value: any) => any;
  onUploadFile?: (file: File) => Promise<any>;
  onDeleteFile?: () => void;
  options?: SettingField[];
  hidden?: boolean;
};

type FileSettingField = SettingField & {
  type: "file";
  onUploadFile: (file: File) => Promise<any>;
  onDeleteFile: () => void;
};

export const UploadSettingsModal = ({
  settings,
  save,
  cancel,
  uploadFile,
  title,
  isMultiple,
}: UploadSettingsModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [fields] = useState<SettingField[]>([
    {
      key: "diarize",
      label: t("speaker_recognition"),
      type: "toggle",
      onChange: (value) => {
        let diarize: Diarize = {};
        if (value) {
          diarize = { diarize: DiarizeTypes.UNSUPRTVISED };
        }
        return diarize;
      },
      options: [
        {
          key: "diarize.diarize",
          label: t("diarize"),
          type: "select",
        },
        {
          key: "diarize.diarize_param.min_speakers",
          label: t("speaker_count_min"),
          type: "number",
        },
        {
          key: "diarize.diarize_param.max_speakers",
          label: t("speaker_count_max"),
          type: "number",
        },
      ],
    },
    {
      key: "aaf",
      label: t("attach_aaf"),
      type: "file",
      onUploadFile: async (file: File) => {
        const uploadedFile = await uploadFile(file);
        handleChange("aaf", uploadedFile);
        return uploadedFile;
      },
      onDeleteFile: async () => {
        handleChange("aaf", {});
      },
      hidden: isMultiple,
    },
  ]);
  const [updatedSettings, setUpdatedSettings] = useState(
    _.pick(
      settings,
      fields.filter((f) => !f.hidden).map((f) => f.key)
    )
  );

  const handleSave = async () => {
    setIsLoading(true);
    await save(updatedSettings);
    setIsLoading(false);
  };

  const handleChange = async (field: string, value: any) => {
    setUpdatedSettings((prevUploads) => {
      const _updatedUpload = _.clone(prevUploads);
      _.set(_updatedUpload, field, value);
      return _updatedUpload;
    });
  };

  const isToggleChecked = (field: SettingField): boolean | undefined => {
    const value = _.get(updatedSettings, field.key);
    if (typeof value === "object" && value !== null) {
      return !!value.diarize;
    }
    return value;
  };

  const SettingsFieldsForm = ({
    settingsFields,
    handleSettingsChange,
    className,
    disabled,
  }: {
    settingsFields: SettingField[];
    handleSettingsChange: (key: string, value: any) => void;
    className?: string;
    disabled?: boolean;
  }) => {
    return (
      <>
        {settingsFields.map((field) => (
          <Grid item width={"100%"} className={field.key} key={field.key}>
            {/* Select */}
            {field.type === "select" && !field.hidden && (
              <div className="settingsField">
                <span
                  className={classNames({
                    disabled: disabled,
                  })}
                >
                  {field.label}
                </span>
                <Box className={"selectWrapper"}>
                  <MultiSelect
                    hideSearch={true}
                    disabled={!_.get(updatedSettings, "diarize")?.diarize}
                    placeholder={t("select_transcript_method")}
                    isMulti={false}
                    selected={[_.get(updatedSettings, field.key)]}
                    setSelected={(selected) => {
                      handleSettingsChange(field.key, selected[0]);
                    }}
                    options={Object.values(DiarizeTypes).map((diarize) => ({
                      value: diarize,
                      label: t(diarize),
                    }))}
                  ></MultiSelect>
                </Box>
              </div>
            )}
            {/* String */}
            {field.type === "text" && !field.hidden && (
              <div className="settingsField text">
                <span>{field.label}</span>
                <Input
                  value={
                    field.onChange
                      ? field.onChange(_.get(updatedSettings, field.key))
                      : _.get(updatedSettings, field.key)
                  }
                  onChange={(e) =>
                    handleSettingsChange(field.key, e.target.value)
                  }
                />
              </div>
            )}
            {/* Timer */}
            {field.type === "timer" && !field.hidden && (
              <div className="settingsField timer">
                <span>{field.label}</span>
                <TimerPicker
                  value={_.get(updatedSettings, field.key) || 0}
                  step={1}
                  handleBlur={_.noop}
                  handleChange={(value) =>
                    handleSettingsChange(
                      field.key,
                      getTimeNumberFromString(value)
                    )
                  }
                  addTime={() =>
                    handleSettingsChange(
                      field.key,
                      (_.get(updatedSettings, field.key) || 0) + 1
                    )
                  }
                  deductTime={() =>
                    handleSettingsChange(
                      field.key,
                      (_.get(updatedSettings, field.key) || 0) - 1
                    )
                  }
                  showMillis={false}
                />
              </div>
            )}
            {/* Number */}
            {field.type === "number" && !field.hidden && (
              <div className={classNames("settingsField number", className)}>
                <span
                  className={classNames({
                    disabled: disabled,
                  })}
                >
                  {t(field.label)}
                </span>
                <Input
                  value={_.get(updatedSettings, field.key)}
                  defaultValue={field.defaultValue}
                  type="number"
                  disabled={disabled}
                  onChange={(e) =>
                    handleSettingsChange(field.key, Number(e.target.value))
                  }
                  style={{ height: 26 }}
                  min={0}
                  max={50}
                ></Input>
              </div>
            )}
            {/* Toggle / Section */}
            {field.type === "toggle" && !field.hidden && (
              <>
                <div className="settingsField toggle">
                  <span>{field.label}</span>
                  <Switch
                    onChange={(e) => {
                      let updatedValue = e.target.checked;
                      if (field.onChange) {
                        updatedValue = field.onChange(updatedValue);
                      }
                      handleSettingsChange(field.key, updatedValue);
                    }}
                    checked={isToggleChecked(field)}
                  />
                </div>
                <div className="settingsFieldOptions">
                  <SettingsFieldsForm
                    className="settingsFieldOption"
                    settingsFields={field.options || []}
                    handleSettingsChange={handleSettingsChange}
                    disabled={!_.get(updatedSettings, field.key)?.diarize}
                  />
                </div>
              </>
            )}
            {/* Toggle / Section */}
            {field.type === "file" && !field.hidden && (
              <div className="settingsField file">
                <span>{field.label}</span>
                <FileInput
                  disabled={isLoading}
                  onFileChange={(field as FileSettingField).onUploadFile}
                  onDeleteFile={(field as FileSettingField).onDeleteFile}
                  label={t("browse")}
                  color="primary"
                  file={_.get(updatedSettings, `${field.key}`)}
                  allowedFormats={[".aaf"]}
                  btnStyle={{ height: 28, width: 40 }}
                />
              </div>
            )}
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Grid
      className={"UploadSettingsModal"}
      container
      maxWidth={450}
      justifyContent={"center"}
      px={4}
      py={3}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        gap={2}
        pb={3}
      >
        <span className="title">{title}</span>
      </Grid>
      <Grid item pb={4} xs={12}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          rowGap={2}
        >
          <SettingsFieldsForm
            handleSettingsChange={handleChange}
            settingsFields={fields}
          />
        </Box>
      </Grid>
      <Grid item lg={12} display={"flex"} justifyContent={"center"} gap={2}>
        <Button onClick={handleSave} loading={isLoading}>
          {t("save")}
        </Button>
        <Button variant="outlined" onClick={cancel}>
          {t("cancel")}
        </Button>
      </Grid>
    </Grid>
  );
};
