import { TFunction } from "i18next";
import { NotesPage } from "@sumit-platforms/types";
import { alignOptions, decorationOptions, fontSizeOptions } from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getNotesPageInputs: (
  args: NotesPage,
  t: TFunction
) => PageSettings<{
  notes: NotesPage;
}> = (args, t) => ({
  settings: { notes: args },
  pageTab: t("settings_protocol_notes"),
  sections: [
    {
      title: t("settings_protocol_notes"),
      paragraph: t("settings_protocol_notes_paragraph_1"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "notes.notes.isActive",
        },
      ],
    },
    {
      disableKey: "notes.notes.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_notes_font")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "notes.notes.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "notes.notes.font.textDecoration",
        },
        {
          type: "toggle",
          buttons: alignOptions,
          exclusive: true,
          key: "notes.notes.font.align",
        },
        {
          type: "colorPicker",
          key: "notes.notes.font.color",
        },
      ],
    },
  ],
});
