import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";

import { Checkbox, Input, MultiSelect, SelectOption } from "../../..";
import { Box } from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/pro-light-svg-icons";

import "./Form.scss";

export type FormProps = {
  className?: string;
  form: {
    fields: {
      key: string;
      label: string | JSX.Element | JSX.Element[];
      type:
        | "text"
        | "number"
        | "password"
        | "email"
        | "date"
        | "select"
        | "checkbox"
        | "phonenumber";
      required?: boolean;
      validate?: (value: any) => string | true;
      options?: SelectOption[];
      multiSelect?: boolean;
      disabled?: boolean;
      pattern?: any;
    }[];
    useForm: UseFormReturn<any>;
  };
  onSubmit?: (formValues: any) => Promise<void>;
};

export const Form = ({ className, form, onSubmit }: FormProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    for (const field of form.fields.filter((f) => f.required)) {
      form.useForm.register(field.key, {
        required: { value: true, message: t("required_field") },
        validate: field.validate,
      });
    }
  }, []);

  const showFormErrors = useCallback(
    (field: any) => {
      const errors = form?.useForm?.formState?.errors;
      return errors && _.get(errors, `${field.key}`) ? (
        <div className="inputError">
          {_.get(errors as any, `${field.key}.message`)}
        </div>
      ) : (
        <></>
      );
    },
    [form?.useForm?.formState?.errors]
  );

  return (
    <form
      className={classNames("styledForm", className)}
      onSubmit={onSubmit && form.useForm.handleSubmit(onSubmit)}
    >
      {form.fields.map((field, i) => {
        switch (field.type) {
          case "select":
            return (
              <Box className={"inputWrapper"} key={`${i}-select`}>
                <MultiSelect
                  selected={form?.useForm?.getValues(field.key) || []}
                  options={field.options || []}
                  setSelected={(selected: any[]) => {
                    form.useForm.setValue(field.key, selected, {
                      shouldValidate: true,
                    });
                  }}
                  isMulti={field.multiSelect as boolean}
                  placeholder={field.label as string}
                />
              </Box>
            );
          case "checkbox":
            return (
              <Box className={"inputWrapper"} key={`${i}-checkbox`}>
                <label className="checkboxLabel">
                  <Checkbox
                    icon={<FontAwesomeIcon icon={faSquare} color={"#3C5069"} />}
                    checkedIcon={
                      <FontAwesomeIcon icon={faCheckSquare} color={"#3C5069"} />
                    }
                    checked={form?.useForm?.getValues(field.key) || false}
                    onChange={(e) => {
                      form.useForm.setValue(field.key, e.target.checked, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {field.label}
                </label>
                {showFormErrors(field)}
              </Box>
            );
          case "phonenumber":
            return (
              <Box className={"inputWrapper"} key={`${i}-phone`}>
                <PhoneInput
                  country={"il"}
                  value={form.useForm.getValues(field.key)?.phoneNumber}
                  onChange={(
                    phoneNumber,
                    data,
                    _countryData,
                    formattedValue
                  ) => {
                    form.useForm.setValue(
                      field.key,
                      {
                        phoneNumber,
                        countryCode: data.countryCode?.toUpperCase(),
                        formatted: formattedValue,
                      },
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                />
                {showFormErrors(field)}
              </Box>
            );
          case "text":
          case "number":
          case "password":
          default:
            return (
              <Box className={"inputWrapper"} key={`${i}-text`}>
                <Input
                  type={field.type}
                  disabled={field.disabled}
                  placeholder={field.label as string}
                  value={form.useForm.getValues(field.key) || ""}
                  onChange={(e) => {
                    form.useForm.setValue(field.key, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                />
                {showFormErrors(field)}
              </Box>
            );
        }
      })}
    </form>
  );
};
