import { Currency } from "../constants/currency";

export enum CommunityPaymentStatus {
  PENDING_QC = "PENDING_QC", // transcriber is waiting for QC to approve the work
  APPROVED = "APPROVED", // the user should get paid and waiting for the payment
  PAID = "PAID", // the user got the payment
  CANCELED = "CANCELED", // the payment is no longer relevant
}

export enum CommunityPaymentType {
  TRANSCRIBE = "TRANSCRIBE",
  QC = "QC",
  BONUS = "BONUS",
  QC_BONUS = "QC_BONUS",
  TRANSCRIBE_BONUS = "TRANSCRIBE_BONUS",
  SUPPLEMENTARY = "SUPPLEMENTARY",
}

type CommunityPayment = {
  idCommunityPayment?: number;
  idUser: number; // recipient. E.x: “203”
  date: Date; // date of the payment E.x: “December 31, 2023 at 5:15:56 PM UTC+3”
  idJob?: number; // E.x: “1004”
  amount: number; // E.x: 128
  paymentType: CommunityPaymentType; // E.x: “WORK”
  status: CommunityPaymentStatus; // E.x: “UNPAID”
  description: string; // E.x: “Transcribe - ${jobId}”
  currency: Currency; // E.x: ILS
  approvedDate?: Date | null; // E.x: “Oct 2, 2023 at 5:15:56 PM UTC+3”
  createdAt?: Date;
  canceledAt?: Date;
};
interface CommunityPaymentByMonth {
  totalPayments: number;
  jobIds: number[];
  paymentIds: number[];
  year: number;
  month: number;
}
type CommunityPaymentResponseData = CommunityPaymentByMonth[];

interface CommunityPaymentResponseMetaData {
  paymentAmountRangeByMonths?: { from: number; to: number };
}

interface CommunityPaymentResponse {
  data: CommunityPaymentResponseData;
  metaData: CommunityPaymentResponseMetaData;
}

interface WalletMonthMetaData {
  maxDuration?: number;
}

interface GetUserPaymentsQueryParams {
  search: string;
  offset: string;
  limit: string;
  month: string;
  year: string;
}

export type {
  CommunityPayment,
  CommunityPaymentResponse,
  CommunityPaymentResponseData,
  CommunityPaymentResponseMetaData,
  CommunityPaymentByMonth,
  WalletMonthMetaData,
  GetUserPaymentsQueryParams,
};
