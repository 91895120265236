import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { faTrash, faUpload } from "@fortawesome/pro-light-svg-icons";
import { SpinningLoader } from "../SpinningLoader/SpinningLoader";

import "./ImageUpload.scss";

interface ImageUploadProps {
  id: string;
  value: string;
  onChange: (file?: File) => Promise<string>;
  disabled?: boolean;
  accept?: string;
}

export const ImageUpload = ({
  id,
  value,
  disabled,
  onChange,
  accept,
}: ImageUploadProps) => {
  const [currentImage, setCurrentImage] = useState<string>(value);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnChange = async (e: any) => {
    setLoading(true);
    const file = e.target.files[0];
    const url = await onChange(file);
    setCurrentImage(url);
    setLoading(false);
  };

  const onImageClick = async () => {
    if (disabled) return;
    await onChange();
    setCurrentImage("");
  };

  return (
    <div className={classNames("ImageUpload", { disabled })}>
      <label htmlFor={id}>
        <div className="buttonWrpper">
          {loading ? <SpinningLoader /> : <FontAwesomeIcon icon={faUpload} />}
        </div>
        <input
          multiple={false}
          id={id}
          type="file"
          onChange={handleOnChange}
          accept={accept}
          disabled={disabled || loading}
        ></input>
      </label>
      {currentImage && (
        <div className="previewImageWrapper">
          <img className="previewImage" src={currentImage} alt="" />
          <div className="delete" onClick={onImageClick}>
            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
          </div>
        </div>
      )}
    </div>
  );
};
