import {
  faFilter,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/pro-light-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, InputBase, Paper } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Sort, SortOption } from "../Sort/Sort";

import "./SearchInput.scss";

export interface SearchInputProps {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  showTextInput: boolean;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  hasFilter?: boolean;
  toggleFilter?: (isOpen: boolean) => void;
  filterBtnText?: string;
  isFilterOpen?: boolean;
  showSearchIcon?: boolean;
  direction?: "ltr" | "rtl";
  className?: string;
  autoFocus?: boolean;
  forwardRef?: React.MutableRefObject<any>;
  sortOptions?: SortOption[];
  onSortChange?: (order: "ASC" | "DESC", orderBy: string) => void;
}

export const SearchInput = forwardRef(
  (
    {
      onChange,
      value,
      placeholder,
      style,
      showTextInput,
      children,
      hasFilter = false,
      toggleFilter,
      filterBtnText,
      isFilterOpen,
      showSearchIcon = true,
      direction,
      className,
      autoFocus = false,
      forwardRef,
      sortOptions,
      onSortChange,
    }: SearchInputProps,
    ref
  ) => {
    const inputRef = useRef(null);
    useImperativeHandle(
      ref,
      () => {
        return {
          inputRef: (inputRef?.current as unknown as HTMLDivElement)
            ?.firstChild as HTMLInputElement,
        };
      },
      [inputRef.current]
    );

    return (
      <Paper
        className={`BazarSearchInput ${className}`}
        component="form"
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: hasFilter ? "6px 0px" : "0",
          paddingInlineEnd: hasFilter ? "20px" : "0",
          boxShadow: "none",
          minHeight: hasFilter ? "auto" : style?.height || "52px",
        }}
        style={{
          minHeight: style?.height,
          backgroundColor: style?.backgroundColor,
          fontSize: style?.fontSize,
          display: hasFilter ? "" : "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: style?.justifyContent,
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: style?.justifyContent,
              width: "100%",
              alignContent: "center",
            }}
          >
            {showSearchIcon && (
              <IconButton type="submit" aria-label="search" disabled={true}>
                <FontAwesomeIcon icon={faSearch} color={style?.color} />
              </IconButton>
            )}
            {showTextInput && (
              <InputBase
                autoFocus={autoFocus}
                sx={{
                  width: "100%",
                  color: style?.color,
                  "& ::-webkit-input-placeholder": {
                    color: style?.color,
                    opacity: "1 !important",
                  },
                }}
                style={{
                  padding: showSearchIcon ? "0" : "0 15px",
                }}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={inputRef}
              />
            )}
          </div>
          {sortOptions && (
            <Sort
              options={sortOptions}
              onSortChange={onSortChange}
              direction={direction}
            ></Sort>
          )}
          {hasFilter && (
            <Button
              disableElevation
              disableRipple
              className={"filterBtn"}
              onClick={() => toggleFilter && toggleFilter(!isFilterOpen)}
              endIcon={
                <FontAwesomeIcon
                  icon={direction === "ltr" ? faAngleRight : faAngleLeft}
                />
              }
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
              >
                <Box>
                  <FontAwesomeIcon icon={faFilter} />
                </Box>
                <Box>{filterBtnText}</Box>
              </Box>
            </Button>
          )}
        </div>
        {hasFilter && isFilterOpen && children}
      </Paper>
    );
  }
);
