import config from "../../config";
import _ from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FilterRangeValue,
  FilterSingularValue,
  GeneralTable,
  SearchAndFilters,
} from "@sumit-platforms/ui-bazar";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@mui/material";
import {
  Client,
  ClientFilterOptions,
  QueryParams,
} from "@sumit-platforms/types";
import {
  useHeadCells,
  useQuery,
  useSortOptions,
} from "@sumit-platforms/ui-bazar/hooks";
import useClients from "../../hooks/useClients";
import { AddClientModal } from "./modals/AddClientModal/AddClientModal";
import clientService from "../../services/clientService";
import {
  CLIENTS_INITIAL_ORDER,
  CLIENTS_INITIAL_ORDER_BY,
  CLIENTS_INITIAL_QUERY_LIMIT,
  CLIENTS_INITIAL_QUERY_OFFSET,
} from "@sumit-platforms/ui-bazar/constants";

import "./Clients.scss";

interface ClientRow {
  id: number;
  client: Client;
  billingContact: string;
  activeJobs: number;
  yearlyTarget: number;
  context: any;
  name: string;
  disabled: boolean;
}

export const Clients: FC = () => {
  const { t } = useTranslation();
  const { setModalContent, clearModalContent, setModalType } = useModal();

  const {
    clients,
    getClients,
    clientsQueryMetaData,
    getClientsQueryMetaData,
    totalClients,
    hasMore,
    isLoading: isClientsLoading,
  } = useClients({ config });
  const [clientRows, setClientRows] = useState<ClientRow[]>([]);

  const { sortOptions } = useSortOptions(["yearly_target", "name"]);

  const {
    onScroll,
    onSearch,
    onFilterChange,
    onSort,
    loading: isQueryLoading,
  } = useQuery<ClientFilterOptions>({
    queryLimit: CLIENTS_INITIAL_QUERY_LIMIT,
    queryOffset: CLIENTS_INITIAL_QUERY_OFFSET,
    order: CLIENTS_INITIAL_ORDER,
    orderBy: CLIENTS_INITIAL_ORDER_BY,
    onBuildQuery,
    onResetOffset: () => {
      setClientRows([]);
    },
  });

  const createClientRow = (client: Client): ClientRow => {
    return {
      id: client.idClient,
      name: client.name,
      billingContact: client.contactEmail,
      activeJobs: client.activeJobs?.length || 0,
      yearlyTarget: client.yearlyTarget,
      context: null,
      client,
      disabled: client.disabled,
    };
  };

  const getRowContext = (row: ClientRow) => {
    return [
      {
        name: row.client.disabled ? t("enable") : t("disable"),
        action: () =>
          updateClient({ disabled: !row.client.disabled }, row.client.idClient),
      },
    ];
  };

  const updateClient = async (client: Partial<Client>, idClient: number) => {
    await clientService.update(client, idClient);
    const _clientRows = clientRows.map((clientRow) => {
      if (clientRow.id === idClient) {
        const _client = { ...clientRow.client, ...client };
        const _clientRow = createClientRow(_client);
        return _clientRow;
      }
      return clientRow;
    });
    setClientRows(_clientRows);
  };

  const addNewClient = async (clientData: Partial<Client>) => {
    const client = await clientService.createNewClient(clientData);
    setClientRows((prevClientRows) => [
      createClientRow(client),
      ...prevClientRows,
    ]);
    closeModal();
    return client;
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openAddClientModal = () => {
    setModalContent(
      <AddClientModal confirm={addNewClient} cancel={closeModal} />
    );
  };

  const { headCells } = useHeadCells({
    headCellsKeys: [
      "id",
      "name",
      "billingContact",
      "yearlyTarget",
      "contextMenu",
    ],
    tableContextCallBack: getRowContext,
    cellLink: { name: (row) => "/clients/" + row.id },
    labelIcon: {
      contextMenu: faPlusCircle,
    },
    labelAction: {
      contextMenu: openAddClientModal,
    },
    styles: {
      contextMenu: {
        padding: 0,
        width: 20,
      },
    },
  });

  const defaultQuery: ClientFilterOptions = useMemo<ClientFilterOptions>(() => {
    return {
      idClient: [],
      jobsCountStart: 0,
      jobsCountEnd: clientsQueryMetaData.maxJobsCount,
      yearlyTargetStart: 0,
      yearlyTargetEnd: clientsQueryMetaData.maxYearlyTargets,
    };
  }, [clientsQueryMetaData]);

  const filters: (FilterRangeValue | FilterSingularValue)[] = useMemo(() => {
    return [
      {
        key: ["jobsCountStart", "jobsCountEnd"],
        name: t("jobs"),
        type: "sliderFromTo",
        valueFrom: 0,
        valueTo: clientsQueryMetaData.maxJobsCount,
        min: 0,
        max: clientsQueryMetaData.maxJobsCount,
        title: t("set_jobs_amount"),
        formatChip: (f) => {
          return `${f.valueFrom} - ${f.valueTo}`;
        },
      },
      {
        key: ["yearlyTargetStart", "yearlyTargetEnd"],
        name: t("yearly_payments"),
        type: "sliderFromTo",
        valueFrom: 0,
        valueTo: clientsQueryMetaData.maxYearlyTargets,
        min: 0,
        max: clientsQueryMetaData.maxYearlyTargets,
        title: t("set_yearly_payments"),
        formatChip: (f) => {
          return `${f.valueFrom} - ${f.valueTo}`;
        },
      },
    ];
  }, [clientsQueryMetaData]);

  useEffect(() => {
    getClientsQueryMetaData();
  }, []);

  useEffect(() => {
    if (_.isArray(clients) && !isClientsLoading) {
      const newClientRows = clients.map(createClientRow);
      setClientRows(newClientRows);
    }
  }, [clients]);

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<ClientFilterOptions>;
  }) {
    await getClients(query);
  }

  return (
    <Grid
      className={"Clients Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("clients")}</h1>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${clients?.length} ${t("from")} ${totalClients}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          isLoading={isQueryLoading}
          direction={t("dir")}
          onSearch={onSearch}
          filters={filters}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          onSortChange={onSort}
          sortOptions={sortOptions}
        />
      </Grid>
      <Grid item xs={11}>
        <GeneralTable
          allowSelect={false}
          headCells={headCells}
          rows={clientRows}
          onLoadMore={onScroll}
          hasMore={hasMore}
          loading={isQueryLoading}
        />
      </Grid>
    </Grid>
  );
};
