import {
  QueryParams,
  Tag,
  User,
  UserFilterOptions,
  UsersMetadata,
} from "@sumit-platforms/types";
import { useEffect, useState } from "react";
import { useTags } from "@sumit-platforms/ui-bazar/hooks";
import { tagService } from "@sumit-platforms/ui-bazar/services";
import { UserService } from "@sumit-platforms/ui-bazar/services";
import config from "../config";
import _ from "lodash";

const userService = UserService({ config });

const useUsers = (): {
  users: User[];
  getUsers: (query?: QueryParams<UserFilterOptions>) => Promise<void>;
  metaData: Partial<UsersMetadata>;
  isLoading: boolean;
  hasMore: boolean;
  totalUsers: number;
} => {
  const { fetchTags } = useTags({
    entity: "users",
    tagService: tagService({
      endpoint: { host: config.server.host, tags: config.server.tags },
    }),
  });
  const [tags, setTags] = useState<Tag[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  useEffect(() => {
    const _fetchTags = async () => {
      const tags1 = await fetchTags();
      setTags(tags1);
    };
    _fetchTags();
  }, []);
  function getUsersCapacityRange(users: User[]) {
    // TODO: get users capacity range from users service
    return {
      from: 0,
      to: 1000,
    };
  }

  function getUsersPaymentsRange(users: User[]) {
    // TODO: get users payment range from users service
    return {
      from: 0,
      to: 1000,
    };
  }

  function getLevelsRange(users: User[]) {
    // TODO: get users levels ranges from users service
    return {
      from: 0,
      to: 1000,
    };
  }

  const getMetaData = () => {
    return {
      capacityRange: getUsersCapacityRange(users),
      paymentsRange: getUsersPaymentsRange(users),
      levelsRange: getLevelsRange(users),
      tags,
    };
  };

  const getUsers = async (query?: QueryParams<UserFilterOptions>) => {
    try {
      setIsLoading(true);
      const { users, totalUsers } = await userService.get(query);
      setUsers((prev: User[]) => {
        if (query && _.isNumber(query?.offset) && query.offset > 0) {
          const aggregatedUsers = [...prev, ...users];
          setHasMore(aggregatedUsers.length !== totalUsers);
          return aggregatedUsers;
        } else {
          setHasMore(users.length !== totalUsers);
          return users;
        }
      });
      setTotalUsers(totalUsers);
    } catch (err: any) {
      console.error("error on getUsers");
      throw new Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    users,
    getUsers,
    metaData: getMetaData(),
    isLoading,
    hasMore,
    totalUsers,
  };
};

export default useUsers;
