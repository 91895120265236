import { TFunction } from "i18next";
import { RemarksPage } from "@sumit-platforms/types";
import { alignOptions, decorationOptions, fontSizeOptions } from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getRemarksPageInputs: (
  args: RemarksPage,
  t: TFunction
) => PageSettings<{ remarks: RemarksPage }> = (args, t) => ({
  settings: { remarks: args },
  pageTab: t("settings_protocol_remarks"),
  sections: [
    {
      title: t("settings_protocol_remarks"),
      paragraph: t("settings_protocol_remarks_paragraph_1"),
    },
    {
      inputs: [
        {
          label: `${t("settings_protocol_remarks_start_end")}:`,
          type: "switch",
          key: "remarks.startEnd.isActive",
        },
      ],
    },
    {
      disableKey: "remarks.startEnd.isActive",
      inputs: [
        {
          type: "text",
          key: "remarks.startEnd.text1",
        },
        {
          type: "text",
          key: "remarks.startEnd.text2",
        },
      ],
    },
    {
      disableKey: "remarks.startEnd.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_remarks_font")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "remarks.startEnd.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "remarks.startEnd.font.textDecoration",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "remarks.startEnd.font.align",
        },
        {
          type: "colorPicker",
          key: "remarks.startEnd.font.color",
        },
      ],
    },
    {
      inputs: [
        {
          label: `${t("settings_protocol_remarks_greeting")}:`,
          type: "switch",
          key: "remarks.greeting.isActive",
        },
      ],
    },
    {
      disableKey: "remarks.greeting.isActive",
      inputs: [
        {
          type: "text",
          key: "remarks.greeting.text1",
        },
      ],
    },
    {
      disableKey: "remarks.greeting.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_remarks_font")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "remarks.greeting.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "remarks.greeting.font.textDecoration",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "remarks.greeting.font.align",
        },
        {
          type: "colorPicker",
          key: "remarks.greeting.font.color",
        },
      ],
    },
    {
      inputs: [
        {
          label: `${t("settings_protocol_remarks_signature")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "remarks.signature.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "remarks.signature.font.textDecoration",
        },
      ],
    },
    {
      inputs: [
        {
          type: "textArray",
          key: "remarks.signature.names",
        },
      ],
    },
  ],
});
