import { Languages } from "../constants/languages";
import { JobTypes } from "../entities/JobTypes";

export class STTConfigPage {
  sttModelId = "";
  splitSubtitles = false;
  constructor(args?: Partial<STTConfigPage>) {
    if (args && typeof args !== undefined) {
      Object.assign(this, args);
    }
  }
}

export type SttModelProps = { sttModelId: string; splitSubtitles?: boolean };

export type STTModel = Record<Languages, SttModelProps>;

export type ClientSTT = Partial<Record<keyof JobTypes, STTModel>>;

export const defaultClientSttSettings: ClientSTT = {
  protocol: {
    "ar-IL": { sttModelId: "multilang_gen", splitSubtitles: false },
    "en-US": { sttModelId: "multilang_gen", splitSubtitles: false },
    "es-ES": { sttModelId: "multilang_gen", splitSubtitles: false },
    "fr-FR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "he-IL": { sttModelId: "he_gen_v2", splitSubtitles: false },
    "hi-IN": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-BR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "ru-RU": { sttModelId: "multilang_gen", splitSubtitles: false },
    "de-DE": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-PT": { sttModelId: "multilang_gen", splitSubtitles: false },
  },
  interview: {
    "ar-IL": { sttModelId: "multilang_gen", splitSubtitles: false },
    "en-US": { sttModelId: "multilang_gen", splitSubtitles: false },
    "es-ES": { sttModelId: "multilang_gen", splitSubtitles: false },
    "fr-FR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "he-IL": { sttModelId: "he_gen_v2", splitSubtitles: false },
    "hi-IN": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-BR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "ru-RU": { sttModelId: "multilang_gen", splitSubtitles: false },
    "de-DE": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-PT": { sttModelId: "multilang_gen", splitSubtitles: false },
  },
  subtitles: {
    "ar-IL": { sttModelId: "multilang_gen", splitSubtitles: false },
    "en-US": { sttModelId: "multilang_gen", splitSubtitles: false },
    "es-ES": { sttModelId: "multilang_gen", splitSubtitles: false },
    "fr-FR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "he-IL": { sttModelId: "he_gen_v2", splitSubtitles: true },
    "hi-IN": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-BR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "ru-RU": { sttModelId: "multilang_gen", splitSubtitles: false },
    "de-DE": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-PT": { sttModelId: "multilang_gen", splitSubtitles: false },
  },
  brief: {
    "ar-IL": { sttModelId: "multilang_gen", splitSubtitles: false },
    "en-US": { sttModelId: "multilang_gen", splitSubtitles: false },
    "es-ES": { sttModelId: "multilang_gen", splitSubtitles: false },
    "fr-FR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "he-IL": { sttModelId: "he_gen_v2", splitSubtitles: true },
    "hi-IN": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-BR": { sttModelId: "multilang_gen", splitSubtitles: false },
    "ru-RU": { sttModelId: "multilang_gen", splitSubtitles: false },
    "de-DE": { sttModelId: "multilang_gen", splitSubtitles: false },
    "pt-PT": { sttModelId: "multilang_gen", splitSubtitles: false },
  },
};
