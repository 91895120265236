import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Breadcrumbs.scss";

export interface BreadcrumbsProps {
  crumbs: {
    label: string;
    path?: string;
  }[];
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  return (
    <MUIBreadcrumbs
      sx={{
        margin: "15px 0",
      }}
      aria-label="breadcrumb"
      className="BazarBreadcrumbs"
    >
      {crumbs.map(({ label, path }, index) => {
        const isLast = index === crumbs.length - 1;
        return isLast ? (
          <Typography className={"breadCrumbText"} key={`${path}-${label}`}>
            {" "}
            {label}{" "}
          </Typography>
        ) : label ? (
          <Link className={"breadCrumbLink"} to={path as string} key={label}>
            {label}
          </Link>
        ) : null;
      })}
    </MUIBreadcrumbs>
  );
};
