import config from "../config";
import axios from "axios";
import {
  Client,
  ClientFilterOptions,
  QueryParams,
} from "@sumit-platforms/types";

const clientsEndpoint = `${config.server.host}/${config.server.clients}`;

export interface GetClientsResponse {
  clients: Client[];
  totalClients: number;
}

const ClientService = () => {
  const get = async (
    query?: QueryParams<ClientFilterOptions>
  ): Promise<GetClientsResponse> => {
    try {
      const response = await axios.get<GetClientsResponse>(clientsEndpoint, {
        params: query,
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data as GetClientsResponse;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getClientById = async (idClient: number): Promise<Client> => {
    try {
      const response = await axios.get<Client>(
        `${clientsEndpoint}/${idClient}`
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data as Client;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createNewClient = async (client: Partial<Client>) => {
    try {
      const response = await axios.post<Client>(clientsEndpoint, client);
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const update = async (client: Partial<Client>, idClient: number) => {
    try {
      const response = await axios.put<Client>(
        `${clientsEndpoint}/${idClient}`,
        client
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateClientSettings = async (
    idClient: number,
    clientSettings: Partial<Client>
  ) => {
    try {
      const response = await axios.put<Client>(
        `${clientsEndpoint}/${idClient}/updateSettings`,
        clientSettings
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getClientsQueryMetaData = async () => {
    try {
      const response = await axios.get(
        `${clientsEndpoint}/clientsQueryMetaData`
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    getClientById,
    getClientsQueryMetaData,
    createNewClient,
    update,
    updateClientSettings,
  };
};

export default ClientService();
