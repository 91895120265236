import axios from "axios";
import {
  AssignmentTask,
  Attachment,
  AttachmentType,
  CreateNewJobsResponse,
  JobRevisionsRequestParams,
  Job,
  JobBonus,
  JobData,
  JobFilterOptions,
  JobRevision,
  JobSplitRequestParams,
  JobStatus,
  JobWithData,
  JobWithPayment,
  QueryParams,
  RestoreJobRequestParams,
  RestoreJobResponse,
  SaveTriggers,
  StatusPercents,
  Languages,
  JobQueryResponse,
} from "@sumit-platforms/types";
import { convertToFormData } from "../../utils";

const JobService = ({ config }: { config: any }) => {
  const jobsEndpoint = `${config.server.host}/${config.server.jobs}`;
  const ooonaEndpoint = `${config.server.host}/${config.server.ooona}`;

  const getJobs = async (
    query?: QueryParams<JobFilterOptions>
  ): Promise<JobQueryResponse> => {
    try {
      const jobs = await axios.get<JobQueryResponse>(
        `${jobsEndpoint}/getJobs`,
        {
          params: query,
        }
      );
      return jobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJob = async (
    idJob: number,
    includeJobData = true
  ): Promise<Job | JobWithData> => {
    const jobEndpoint = `${jobsEndpoint}/${idJob}`;
    try {
      const job = await axios.get<Job>(jobEndpoint, {
        params: {
          includeJobData,
        },
      });
      return job.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getUserClientJobs = async (
    query?: QueryParams<JobFilterOptions>
  ): Promise<{ jobs: Job[]; totalJobs: number }> => {
    try {
      const jobs = await axios.get<{ jobs: Job[]; totalJobs: number }>(
        `${jobsEndpoint}/getUserClientJobs`,
        {
          params: query,
        }
      );
      return jobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobsStatusPercents = async (
    status: JobStatus[]
  ): Promise<StatusPercents[]> => {
    const jobEndpoint = `${jobsEndpoint}/getJobsStatusPercents`;
    try {
      const job = await axios.get<StatusPercents[]>(jobEndpoint, {
        params: { status },
      });
      return job.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAssignedJobs = async (
    query?: QueryParams<JobFilterOptions>
  ): Promise<JobQueryResponse> => {
    try {
      const jobs = await axios.get<JobQueryResponse>(
        `${jobsEndpoint}/userAssignedJobs`,
        {
          params: query,
        }
      );
      return jobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobQueryMetaData = async (query?: QueryParams<JobFilterOptions>) => {
    try {
      const jobQueryMetaData = await axios.get(
        `${jobsEndpoint}/jobQueryMetaData`,
        {
          params: query,
        }
      );
      return jobQueryMetaData.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAssignedJobQueryMetaData = async (
    query?: QueryParams<JobFilterOptions>
  ) => {
    try {
      const jobQueryMetaData = await axios.get(
        `${jobsEndpoint}/userAssignedJobsMetaData`,
        {
          params: query,
        }
      );
      return jobQueryMetaData.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAvailableJobQueryMetaData = async (
    query?: QueryParams<JobFilterOptions>
  ) => {
    try {
      const jobQueryMetaData = await axios.get(
        `${jobsEndpoint}/availableJobsMetaData`,
        {
          params: query,
        }
      );
      return jobQueryMetaData.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobTypes = async () => {
    try {
      const response = await axios.get(jobsEndpoint + "/jobTypes");
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const update = async (jobIds: number[], job: Partial<Job>) => {
    try {
      const updatedJob = await axios.put<Job[]>(jobsEndpoint, {
        idJob: jobIds,
        job,
      });
      return updatedJob.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateJobStatus = async (idJobs: number[], newStatus: JobStatus) => {
    try {
      const response = await axios.put(jobsEndpoint + "/updateJobStatus", {
        idJobs,
        newStatus,
      });

      if (response.status !== 200) throw new Error(response.statusText);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const create = async (
    uploadIds: number[],
    onUploadProgress?: (data: any) => void
  ): Promise<CreateNewJobsResponse> => {
    try {
      const newJobs = await axios.post<CreateNewJobsResponse>(jobsEndpoint, {
        uploadIds,
      });
      return newJobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const addAttachmentToJob = async (
    type: AttachmentType,
    idJob: number[],
    idClient: number,
    attachment: File
  ): Promise<Attachment> => {
    try {
      const formData = convertToFormData({
        type,
        idJob,
        idClient,
        attachment,
      });
      const response = await axios.put(
        jobsEndpoint + "/addAttachment",
        formData
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removeAttachmentFromJob = async (
    idJob: number,
    idAttachment: number
  ): Promise<void> => {
    try {
      await axios.delete(jobsEndpoint + "/removeAttachment", {
        params: {
          idJob,
          idAttachment,
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteJob = async (jobIds: number[]) => {
    try {
      const deletedJob = axios.delete(jobsEndpoint, {
        params: {
          idJob: jobIds,
        },
      });
      return deletedJob;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getTotalAssignedJobsByQuery = async (
    query?: QueryParams<JobFilterOptions>
  ) => {
    try {
      const totalJobsByQuery = await axios.get(
        `${jobsEndpoint}/totalJobsByQuery`,
        {
          params: { ...query, onlyAssignedUserJobs: true },
        }
      );
      return totalJobsByQuery.data.totalJobs;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const saveJobData = async ({
    idJob,
    jobData,
    tcOffsets,
    saveTrigger,
    filename,
    isClientRevision,
  }: {
    idJob: number;
    jobData: JobData;
    tcOffsets: number[][];
    saveTrigger: SaveTriggers;
    filename?: string;
    isClientRevision?: boolean;
  }) => {
    try {
      await axios.post(`${jobsEndpoint}/${idJob}/saveJobData`, {
        filename,
        tcOffsets,
        jobData,
        saveTrigger,
        isClientRevision,
      });

      return { success: true };
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const setTags = async (idJob: number[], idTag: number[]): Promise<void> => {
    try {
      await axios.put(jobsEndpoint + "/setTags", {
        idJob,
        idTag,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAvailableJobs = async (query?: QueryParams<JobFilterOptions>) => {
    try {
      try {
        const jobs = await axios.get<JobQueryResponse>(
          `${jobsEndpoint}/availableJobs`,
          {
            params: query,
          }
        );
        return jobs.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getWalletMonthJobs = async (query?: QueryParams<JobFilterOptions>) => {
    try {
      try {
        const jobs = await axios.get<{
          jobs: JobWithPayment[];
          totalJobs: number;
          timestamp: number;
        }>(`${jobsEndpoint}/walletMonth`, {
          params: query,
        });
        return jobs.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCommunityMemberJobsByMonth = async (
    idUser: number,
    query: QueryParams<JobFilterOptions>
  ) => {
    try {
      const jobs = await axios.post<{
        jobs: JobWithPayment[];
        totalJobs: number;
        timestamp: number;
      }>(`${jobsEndpoint}/${idUser}/getCommunityMemberJobsByMonth`, query);
      return jobs.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const runAligner = async (idJob: number) => {
    try {
      try {
        await axios.post(`${jobsEndpoint}/${idJob}/runAligner`);
      } catch (err) {
        console.error(err);
        throw err;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getPriceByTask = ({ task, job }: { task: AssignmentTask; job: Job }) =>
    task === AssignmentTask.qc ? job.qcPrice : job.transcribePrice;

  const getSubtitlesValidationSettings = async (idJob: number) => {
    const jobEndpoint = `${jobsEndpoint}/${idJob}`;
    try {
      const subtitlesValidation = await axios.get(
        `${jobEndpoint}/getSubtitlesValidation`
      );
      return subtitlesValidation.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobPeaks = async (idJob: number) => {
    const jobEndpoint = `${jobsEndpoint}/${idJob}`;
    try {
      const peaks = await axios.get(`${jobEndpoint}/getPeaks`);
      return peaks.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const approveAutoTranscript = async (idJob: number) => {
    try {
      const response = await axios.get(
        `${jobsEndpoint}/${idJob}/approveAutoTranscript`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const orderFullTranscript = async (idJob: number) => {
    try {
      const response = await axios.get(
        `${jobsEndpoint}/${idJob}/orderFullTranscript`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const onTranslationJobRejectAutoStt = async (idJob: number) => {
    try {
      const response = await axios.put(
        `${jobsEndpoint}/${idJob}/rejectAutoStt`
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createJobsBonus = async (
    jobBonus: Partial<JobBonus>,
    idJobs: number[]
  ) => {
    try {
      const response = await axios.post(`${jobsEndpoint}/createJobsBonus`, {
        jobBonus,
        idJobs,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const updateJobBonus = async (
    idJobBonus: number,
    jobBonus: Partial<JobBonus>
  ) => {
    try {
      const response = await axios.put(`${jobsEndpoint}/updateJobBonus`, {
        jobBonus,
        idJobBonus,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const deleteJobBonus = async (idJobBonus: number) => {
    try {
      const response = await axios.delete(`${jobsEndpoint}/deleteJobBonus`, {
        data: {
          idJobBonus,
        },
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const split = async ({ splitCount, idJobs }: JobSplitRequestParams) => {
    try {
      const response = await axios.post<Job[]>(`${jobsEndpoint}/split`, {
        splitCount,
        idJobs,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(`Error in split job, with error: ${err}`);
      throw err;
    }
  };
  const restoreJob = async ({ idJobRevision }: RestoreJobRequestParams) => {
    try {
      const response = await axios.post<RestoreJobResponse>(
        `${jobsEndpoint}/restoreJob`,
        {
          idJobRevision,
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(`Error in restoreJob, with error: ${err}`);
      throw err;
    }
  };
  const getJobRevisions = async ({
    idJob,
    excludeSaveTriggers,
  }: JobRevisionsRequestParams) => {
    try {
      const response = await axios.post<JobRevision[]>(
        `${jobsEndpoint}/getJobRevisions`,
        {
          idJob,
          excludeSaveTriggers,
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(`Error in getJobRevisions, with error: ${err}`);
      throw err;
    }
  };

  const createJobTranslationOnDemand = async ({
    idJobs,
    languages,
  }: {
    idJobs: number[];
    languages: Languages[];
  }) => {
    try {
      const response = await axios.post<Job[]>(
        `${jobsEndpoint}/createJobTranslationOnDemand`,
        {
          idJobs,
          languages,
        }
      );
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(
        `Error in createJobTranslationOnDemand, with error: ${err}`
      );
      throw err;
    }
  };

  const getOoonaEditorUrl = async (idJob: number) => {
    try {
      const response = await axios.post(`${ooonaEndpoint}/openJobInEditorPro`, {
        idJob,
      });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data.url;
    } catch (err) {
      console.error(`Error in openJobInEditorPro, with error: ${err}`);
      throw err;
    }
  };

  return {
    getJobs,
    getJob,
    getUserClientJobs,
    getJobsStatusPercents,
    getAssignedJobs,
    getJobQueryMetaData,
    getAssignedJobQueryMetaData,
    getAvailableJobQueryMetaData,
    getJobTypes,
    getTotalAssignedJobsByQuery,
    saveJobData,
    addAttachmentToJob,
    removeAttachmentFromJob,
    create,
    deleteJob,
    update,
    getAvailableJobs,
    updateJobStatus,
    runAligner,
    getCommunityMemberJobsByMonth,
    getPriceByTask,
    getWalletMonthJobs,
    getSubtitlesValidationSettings,
    getJobPeaks,
    setTags,
    approveAutoTranscript,
    orderFullTranscript,
    onTranslationJobRejectAutoStt,
    createJobsBonus,
    updateJobBonus,
    deleteJobBonus,
    split,
    restoreJob,
    getJobRevisions,
    createJobTranslationOnDemand,
    getOoonaEditorUrl,
  };
};

export default JobService;
