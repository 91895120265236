import _ from "lodash";
import React from "react";
import { Popover } from "@mui/material";
import { IconDefinition, faA } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SketchPicker } from "react-color";
import classNames from "classnames";

import "./ColorPicker.scss";
export interface ColorPickerProps {
  value: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  icon?: IconDefinition;
}

export const ColorPicker = ({
  value,
  onChange,
  disabled,
  icon,
}: ColorPickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (colors: any) => {
    onChange(colors.hex);
  };

  const isDark = (color: any) => {
    let r, g, b;
    if (color.match(/^rgb/)) {
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );
      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    return hsp < 127.5;
  };

  return (
    <div className={classNames("ColorPicker", { disabled })}>
      <div
        className="colorPickerTrigger"
        style={{
          borderColor: value,
          background: value,
          color: value,
        }}
        onClick={(e: any) => {
          if (disabled) return;
          handleOpen(e);
        }}
      >
        <FontAwesomeIcon
          className={"indicationLetter"}
          icon={icon ? icon : faA}
          color={
            (value && !isDark(value)) || _.isEmpty(value) ? "black" : "white"
          }
        />
      </div>

      <Popover
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 0,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 0,
        }}
      >
        <SketchPicker
          className="sketchColorPicker"
          width="130px"
          disableAlpha={false}
          presetColors={[]}
          color={value}
          onChangeComplete={handleColorChange}
        />
      </Popover>
    </div>
  );
};
