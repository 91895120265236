import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  GeneralLang,
  ValidationGeneralPage,
  ValidationLang,
  ValidationLanguagePage,
  ValidationsConfig,
} from "@sumit-platforms/types";
import { PageSettings } from "../core/SettingsPage/SettingsPage";
import { getValidationPageInputs } from "./pageSettings/validation/validationPage";

export interface UseValidationsSettingsInput<TMetaData, TActions> {
  settings?: ValidationsConfig;
  metadata?: TMetaData;
  actions?: TActions;
}

export const useValidationsSettings = ({
  settings,
}: UseValidationsSettingsInput<any, any>) => {
  const _handleGetValidationPageInputs = ({
    key,
    value,
    t,
  }: {
    key: GeneralLang | ValidationLang;
    value: any;
    t: any;
  }) => {
    return getValidationPageInputs({
      lang: key,
      args:
        key === "general_lang"
          ? new ValidationGeneralPage(value)
          : new ValidationLanguagePage(value),
      t,
    });
  };

  const { t } = useTranslation();
  const [pages, setPages] = useState<PageSettings<ValidationsConfig>[]>(
    settings
      ? _.entries(settings).map((entry) =>
          _handleGetValidationPageInputs({
            key: entry[0] as GeneralLang | ValidationLang,
            value: entry[1],
            t,
          })
        )
      : []
  );

  const [settingsReplica, setSettingsReplica] =
    useState<ValidationsConfig | null>(null);

  const resetPages = () => {
    if (settingsReplica) {
      setPages(
        settingsReplica
          ? Object.entries(settingsReplica).map((entry) =>
              _handleGetValidationPageInputs({
                key: entry[0] as GeneralLang | ValidationLang,
                value: entry[1],
                t,
              })
            )
          : []
      );
    }
  };

  useEffect(() => {
    if (settings) {
      setPages(
        Object.entries(settings).map((entry) =>
          _handleGetValidationPageInputs({
            key: entry[0] as GeneralLang | ValidationLang,
            value: entry[1],
            t,
          })
        )
      );
      if (!settingsReplica) {
        setSettingsReplica({ ...settings });
      }
    }
  }, [settings]);

  return { pages, setPages, resetPages };
};
