import { Toast } from "@sumit-platforms/types";

const TOAST_IDLE_TIME = 3 * 1000;

export const useToast = ({
  setToast,
  idleTime = TOAST_IDLE_TIME,
}: {
  setToast: (toast: Toast | null) => void;
  idleTime?: number;
}): {
  toastInfo: typeof toastInfo;
  toastSuccess: typeof toastSuccess;
  toastError: typeof toastError;
} => {
  const toastInfo = (text: string) => {
    setToast({ text, severity: "info" });
    setTimeout(clearToast, idleTime);
  };

  const toastSuccess = (text: string) => {
    setToast({ text, severity: "success" });
    setTimeout(clearToast, idleTime);
  };

  const toastError = (text: string, error?: unknown) => {
    console.log(error || text);
    setToast({ text, severity: "error" });
    setTimeout(clearToast, idleTime);
  };

  const clearToast = () => setToast(null);

  return {
    toastInfo,
    toastSuccess,
    toastError,
  };
};
