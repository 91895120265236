import { SelectSimple, SelectSimpleOption } from "../SelectSimple/SelectSimple";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { Grid, IconButton, InputLabel, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Input/Input";
import { SxProps } from "@mui/system";

export const InputPair = ({
  keyValue,
  value,
  onKeyChange,
  onValueChange,
  icon,
  options,
  labels,
  showLabels,
  disable,
  autofocus,
  onInputBlur,
  onSelectBlur,
  inputWidth,
  selectWidth,
  optional,
  sx,
}: {
  keyValue: string;
  labels?: { key: string; value: string };
  value?: SelectSimpleOption | string;
  onKeyChange?: (e: any) => void;
  onValueChange?: (e: any) => void;
  options: SelectSimpleOption[];
  icon?: {
    icon: IconProp;
    action?: () => any;
    tooltip: string;
    disabled?: boolean;
  };
  showLabels?: boolean;
  disable?: { key?: true; type?: boolean };
  autofocus?: boolean;
  onInputBlur?: () => void | null;
  onSelectBlur?: () => void | null;
  inputWidth?: string;
  selectWidth?: string;
  optional?: { input?: boolean };
  sx?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  const _labels = useMemo(() => {
    return showLabels ? labels || { key: t("key"), value: t("type") } : null;
  }, [labels, showLabels]);

  return (
    <Grid
      item
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      gap={2}
      px={"35px"}
      pb={2}
      sx={sx}
    >
      {icon && (
        <Tooltip title={icon.tooltip} placement={"top"}>
          <IconButton
            onClick={icon.action}
            disabled={icon.disabled}
            sx={{
              top: showLabels ? "10px" : "",
              padding: 0,
            }}
          >
            <FontAwesomeIcon icon={icon.icon} size={"xs"} />
          </IconButton>
        </Tooltip>
      )}
      <Grid item display={"flex"} width={"100%"} className={"inputsWrapper"}>
        <Grid item flex={1}>
          <Input
            label={_labels?.key}
            className={"keyInput"}
            value={keyValue || ""}
            onChange={onKeyChange}
            disabled={disable?.key}
            autofocus={autofocus}
            onBlur={onInputBlur}
            style={{ width: inputWidth || "10rem" }}
            optional={showLabels && optional?.input}
          />
        </Grid>
        <Grid item flex={1}>
          <InputLabel shrink>{_labels?.value}</InputLabel>
          <SelectSimple
            className={"inputPairSelect"}
            value={value || ""}
            onChange={onValueChange}
            options={options}
            disabled={disable?.type}
            style={{ width: selectWidth || "10rem" }}
            onBlur={onSelectBlur}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
