import _ from "lodash";
import { useMemo } from "react";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, ThemeProvider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { bazarTheme, grayColor4 } from "../../bazar-theme";
import { SearchInput } from "../SearchInput/SearchInput";
import { PopoverAction } from "../../popovers/PopoverAction/PopoverAction";
import { Button } from "../Button/Button";
import { Chip } from "../Chip/Chip";
import { SpinningLoader } from "../SpinningLoader/SpinningLoader";

import { isNum } from "../../utils/regex";
import { Option } from "../MultiSelect/MultiSelect";
import { ListFilter } from "../Filters/ListFilter/ListFIlter";
import { SliderFilter } from "../Filters/SliderFilter/SliderFilter";
import { DateRange } from "../DateRange/DateRange";
import { SortOption } from "../Sort/Sort";

import "./SearchAndFilter.scss";

export interface BaseFilter {
  name: string;
  options?: Option[];
  min?: number;
  max?: number;
  format?: (value: number) => string;
  resetValue?: any;
  title?: string;
}

export interface FilterRangeValue extends BaseFilter {
  key: string[];
  valueFrom: any;
  valueTo: any;
  type: "dateFromTo" | "selectFromTo" | "sliderFromTo";
  formatChip: (f: FilterRangeValue) => string;
  titleFrom?: string;
  titleTo?: string;
  useDateShortcuts?: boolean;
}

export interface FilterSingularValue extends BaseFilter {
  key: string;
  value: any;
  type: "select";
  formatChip: (f: FilterSingularValue) => string;
}

export type Filter = FilterRangeValue | FilterSingularValue;

export interface SearchAndFiltersProp {
  onSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  defaultQuery: any;
  onFilterChange?: (query: any) => void;
  title?: string;
  filters?: Filter[];
  isLoading?: boolean;
  direction?: "rtl" | "ltr";
  searchPlaceholder?: string;
  filterBtnTitle?: string;
  sortOptions?: SortOption[];
  onSortChange?: (order: "ASC" | "DESC", orderBy: string) => void;
  deps?: any[];
}

export const SearchAndFilters: FC<SearchAndFiltersProp> = ({
  onSearch,
  onFilterChange,
  defaultQuery,
  title,
  filters,
  isLoading,
  direction,
  searchPlaceholder = "Search",
  filterBtnTitle = "Filters",
  sortOptions,
  onSortChange,
  deps = [],
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [query, setQuery] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const activeFilters = useMemo(() => {
    const _filters: Filter[] = [];
    Object.entries(query).map((e) => {
      const [queryKey] = e;
      const _filter = filters?.find(
        (filter) =>
          ("valueFrom" in filter && filter.key.includes(queryKey)) ||
          filter.key === queryKey
      );
      if (_filter) {
        if ("valueFrom" in _filter) {
          _filter.valueFrom = query[_filter.key[0]];
          _filter.valueTo = query[_filter.key[1]];
        } else {
          _filter.value = query[_filter.key as string];
        }
        _filters.push(_filter);
      }
    });
    return _filters;
  }, [filters, query]);
  const handleFilterChange = (key: string, value: any) => {
    setQuery((prev: any) => {
      let _query: any = {};
      _query[key] = value;
      _query = _.merge({ ...prev, ..._query });
      Object.entries(_query).map((e) => {
        const [key, value] = e;
        const currentQueryEqualToDefault = _.isEqual(value, defaultQuery[key]);
        const isQueryEmpty = _.isNil(_query[key]);
        if (currentQueryEqualToDefault || isQueryEmpty) {
          delete _query[key];
        }
      });
      return _query;
    });
  };
  useEffect(() => {
    setSearchParams(query);
    onFilterChange && onFilterChange(query);
  }, [query]);

  const handleFilterDelete = (key: string | string[]) => {
    setQuery((prev: any) => {
      const _query = { ...prev };
      if (_.isArray(key)) {
        key.map((k) => delete _query[k]);
      } else {
        delete _query[key];
      }
      setSearchParams(_query);
      onFilterChange && onFilterChange(_query);
      return _query;
    });
  };

  const resetAllFilters = () => {
    setQuery({});
    setSearchParams({});
    onFilterChange && onFilterChange({});
  };

  useEffect(() => {
    resetAllFilters();
  }, deps);

  useEffect(() => {
    const checkForSearchParamsInUrl = () => {
      const _query: any = {};
      const searchParamsAsKeyValue = [...searchParams];
      if (searchParamsAsKeyValue.length > 0) {
        searchParamsAsKeyValue
          .filter(
            ([key]) =>
              _.has(defaultQuery, key) && typeof _query[key] === "undefined"
          )
          .map(([key]) => {
            if (_.isArray(defaultQuery[key])) {
              _query[key] = searchParams
                .getAll(key)
                .map((v) => (isNum(v) ? Number(v) : v));
            } else {
              _query[key] = searchParams.get(key);
              _query[key] = isNum(_query[key])
                ? Number(_query[key])
                : _query[key];
            }
          });
        setIsFilterOpen(true);
      }
      setQuery(_query);
      setSearchParams(_query);
      onFilterChange && onFilterChange(_query);
    };
    checkForSearchParamsInUrl();
  }, []);
  return (
    <ThemeProvider theme={bazarTheme}>
      <Grid container className="BazarSearchAndFilters">
        <SearchInput
          direction={direction}
          onChange={onSearch}
          showTextInput={true}
          placeholder={searchPlaceholder}
          style={{
            color: grayColor4,
            fontSize: "16px",
            justifyContent: "space-between",
          }}
          hasFilter={filters && filters.length > 0}
          filterBtnText={filterBtnTitle}
          isFilterOpen={isFilterOpen}
          toggleFilter={setIsFilterOpen}
          sortOptions={sortOptions}
          onSortChange={onSortChange}
        >
          {filters && query && (
            <Grid container py={1} px={3}>
              <Grid
                item
                xs={1}
                display={"flex"}
                alignItems={"center"}
                className="filterBy"
              >
                {title}:
                {isLoading && (
                  <Box width={16} height={16} margin={"auto"}>
                    <SpinningLoader />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={11}
                display={"flex"}
                gap={2}
                flexWrap={"wrap"}
                alignItems={"center"}
              >
                {filters.map((f, i: number) => (
                  <PopoverAction
                    key={i}
                    trigger={
                      <Button variant="outlined" color={"primary"}>
                        {f.name}
                      </Button>
                    }
                    renderContent={() => (
                      <Box onClick={(e) => e.stopPropagation()}>
                        {f.type === "dateFromTo" && (
                          <Box className={"filterWrapper"} display="flex">
                            <DateRange
                              valueFrom={f.valueFrom}
                              valueTo={f.valueTo}
                              onValueFromChange={(valueFrom) => {
                                handleFilterChange(f.key[0], valueFrom);
                              }}
                              onValueToChange={(valueTo) => {
                                handleFilterChange(f.key[1], valueTo);
                              }}
                              title={f.title}
                              resetValue={f.resetValue}
                              useDateShortcuts={f.useDateShortcuts}
                            />
                          </Box>
                        )}
                        {f.type === "select" && (
                          <Box className={"filterWrapper"}>
                            <ListFilter
                              options={f.options || []}
                              selected={query[f.key as string] || f.value}
                              setSelected={(selected: any[]) => {
                                handleFilterChange(f.key as string, selected);
                              }}
                            />
                          </Box>
                        )}
                        {f.type === "selectFromTo" && (
                          <Box
                            className={"filterWrapper"}
                            display="flex"
                            gap={1}
                            pb={0.5}
                            px={0.5}
                          >
                            <ListFilter
                              isMulti={false}
                              options={f.options || []}
                              selected={query[f.key[0]] || f.valueFrom}
                              setSelected={(selected: any[]) => {
                                handleFilterChange(
                                  f.key[0],
                                  selected[0] === f.valueFrom[0] ? [] : selected
                                );
                              }}
                            />
                            <ListFilter
                              options={f.options || []}
                              selected={query[f.key[1]] || f.valueTo}
                              setSelected={(selected: any[]) => {
                                handleFilterChange(f.key[1], selected);
                              }}
                            />
                          </Box>
                        )}
                        {f.type === "sliderFromTo" && (
                          <Box className={"filterWrapper"} py={1} px={1}>
                            <SliderFilter
                              min={f.min}
                              max={f.max}
                              value={[
                                query[f.key[0]] || f.valueFrom || 0,
                                query[f.key[1]] || f.valueTo || 0,
                              ]}
                              setValue={(value: number[]) => {
                                handleFilterChange(f.key[0], value[0]);
                                handleFilterChange(f.key[1], value[1]);
                              }}
                              title={f.title}
                              format={f.format}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          {!_.isEmpty(activeFilters) && (
            <Grid container py={1} px={6}>
              <Grid item xs={11} display={"flex"} gap={2} alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  onClick={resetAllFilters}
                  style={{ cursor: "pointer" }}
                />
                {_.unionBy(activeFilters, "key").map((filter, i) => {
                  if (!filter) return null;
                  const label = filter?.formatChip(filter as any);
                  if (!label) return null;
                  return (
                    <Chip
                      key={i}
                      label={label}
                      action={() => handleFilterDelete(filter?.key)}
                    />
                  );
                })}
              </Grid>
            </Grid>
          )}
        </SearchInput>
      </Grid>
    </ThemeProvider>
  );
};
