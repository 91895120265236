import _ from "lodash";
import { addDays } from "date-fns";
import { Currency, JobWithPayment } from "@sumit-platforms/types";
import { TFunction } from "i18next";

const convertToFormData = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (_.isArray(data[key]) && data[key].length > 0) {
      data[key].forEach((item: any) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

const secondsToTimeString = (seconds: number, forceHours = false) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = (seconds % 60).toFixed(0);
  let timeString = "";

  if (forceHours || hrs > 0) {
    timeString += (hrs < 10 ? "0" : "") + hrs + ":";
  }
  timeString +=
    (mins < 10 ? "0" : "") + mins + ":" + (Number(secs) < 10 ? "0" : "");

  timeString += "" + secs;

  return timeString;
};

const getTimeNumberFromString = (time = "00:00:00"): number => {
  const [hours = 0, minutes = 0, seconds = 0] = time.split(":");
  const hourSeconds = Number(hours) * 3600;
  const minutesSeconds = Number(minutes) * 60;

  return hourSeconds + minutesSeconds + Number(seconds);
};

const idArrayToIdQuery = (ids: number[], key: string) => {
  return `?${key}=${ids.join(`&${key}=`)}`;
};

const formatBytesToBlob = (resultByte: any[], mimeType: string) => {
  const bytes = new Uint8Array(resultByte);
  return new Blob([bytes], { type: mimeType });
};

const getMinDeliveryDate = (minDeliveryDays: number) =>
  addDays(new Date().setHours(0, 0, 0, 0), minDeliveryDays);

const secondsToTC = (seconds: number) => {
  let hrs: any = Math.floor(seconds / 3600);
  hrs = hrs < 10 && hrs !== 0 ? "0" + hrs : hrs;
  let mins: any = Math.floor((seconds % 3600) / 60);
  mins = mins < 10 && mins !== 0 ? "0" + mins : mins;
  let secs: any = (seconds % 60).toFixed(0);
  secs = secs < 10 && secs !== 0 ? "0" + secs : secs;
  return [hrs || "00", mins || "00", secs || "00"].join(":");
};

const getPriceStringWithCurrency = ({
  price,
  currency,
}: {
  price?: number | null;
  currency: Currency;
}) => {
  if (price === undefined || price === null) {
    return "";
  }
  return new Intl.NumberFormat("he-IL", {
    style: "currency",
    currency: currency.toLowerCase(),
  }).format(price);
};

const formatLanguageString = (
  language: {
    input: string[];
    output: string[];
  },
  t: TFunction
): { shortString: string; longString: null | string } => {
  const { input, output } = language;

  const formattedLangs: { shortString: string; longString: null | string } = {
    shortString: t(input[0]),
    longString: null,
  };

  if (_.isEmpty(output)) {
    return formattedLangs;
  }

  const shortOutput =
    output.length > 1 ? `${t(output[0])} +${output.length - 1}` : t(output[0]);
  formattedLangs.shortString = `${t(input[0])} -> ${shortOutput}`;
  formattedLangs.longString =
    output.length > 1
      ? `${t(input[0])} -> ${t(output.map((l) => t(l)).join(", "))}`
      : null;

  return formattedLangs;
};

const getShortArrayString = (arr: string[], count = 3) => {
  if (arr.length <= count) {
    return arr.join(", ");
  }

  return `${arr.slice(0, count).join(", ")} +${arr.length - count}`;
};

const formatPaymentTypeFromJobWithPayment = (
  jobWithPayment: JobWithPayment,
  t: any
) => {
  const typeSuffix =
    jobWithPayment?.isBonus && jobWithPayment.task
      ? `(${t("bonus")})`
      : jobWithPayment?.isBonus && !jobWithPayment?.task
      ? t("bonus")
      : "";
  const paymentType = `${t(jobWithPayment?.task as any)} ${typeSuffix}`;
  return paymentType;
};

export {
  convertToFormData,
  idArrayToIdQuery,
  formatBytesToBlob,
  getMinDeliveryDate,
  getPriceStringWithCurrency,
  secondsToTimeString,
  getTimeNumberFromString,
  secondsToTC,
  formatLanguageString,
  getShortArrayString,
  formatPaymentTypeFromJobWithPayment,
};
