import { isValid, format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Upload } from "@sumit-platforms/types";
import Input from "../../core/Input/Input";
import { DATETIME_INPUT_FORMAT } from "../../constants/globals";
import { formatMinAndMaxForDatetimeLocale } from "../../utils/date";

import "./DatePickerPopover.scss";

export interface DatePickerPopoverProps {
  update: (ids: number[], partOfUpload: Partial<Upload>) => Promise<void>;
  selected: number[];
  row?: any;
  max?: Date;
  min?: Date;
  defaultDate?: Date;
}

export const DatePickerPopover = ({
  selected,
  update,
  row,
  max,
  min,
  defaultDate,
}: DatePickerPopoverProps) => {
  const { t } = useTranslation();
  const [delivery, setDelivery] = useState<string>(
    (row?.delivery && new Date(row.delivery)) ||
      defaultDate ||
      new Date().setHours(0, 0, 0, 0)
  );

  const handleClose = async () => {
    update && (await update(selected, { delivery: delivery as any }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedDate = new Date(e.target.value);
    if (isValid(parsedDate)) {
      setDelivery(parsedDate.toISOString());
    }
  };

  return (
    <div className="DatePickerPopover">
      <div className="action">
        <div className="title">{`${t("choose_date")}:`}</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Input
            type="datetime-local"
            value={format(new Date(delivery), DATETIME_INPUT_FORMAT)}
            onChange={handleOnChange}
            onBlur={handleClose}
            max={max && formatMinAndMaxForDatetimeLocale(max)}
            min={min && formatMinAndMaxForDatetimeLocale(min)}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};
