import { Job } from "./Job";

interface JobTranslation {
  idJobTranslation?: number;
  idSourceJob: number;
  idJob: number;
  sttType: SttType;
  autoSttRetake?: AutoSttRetakeStatus;
  sttRejectedAt?: Date;
  job?: Job;
  parentJob?: Job;
}
enum SttType {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

enum AutoSttRetakeStatus {
  REQUESTED = "REQUESTED",
  RECEIVED = "RECEIVED",
}

export { SttType, AutoSttRetakeStatus };
export type { JobTranslation };
