import { useSearchParams } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { WalletMonth, WalletOverview } from "@sumit-platforms/ui-bazar";
import { usePayments } from "../../hooks/usePayments";
import useJobs from "../../hooks/useJobs";
import _ from "lodash";
import { JobWithPayment, WalletMonthMetaData } from "@sumit-platforms/types";

const defaultQuery = {
  month: null,
  payments: null,
  year: null,
  type: null,
  languages: null,
  length: null,
  uploaded: null,
  inputLanguage: null,
  outputLanguage: null,
  durationStart: null,
  durationEnd: null,
  uploadedStart: null,
  uploadedEnd: null,
};
const Wallet = () => {
  const [month, setMonth] = useState<null | string>(null);
  const [year, setYear] = useState<null | string>(null);
  const {
    jobs,
    getJobs,
    setJobs,
    isLoading: isJobsLoading,
  } = useJobs({ jobType: "walletMonth" });
  const [searchParams] = useSearchParams();
  const { getUserCommunityPayments, communityPayments, metaData, isLoading } =
    usePayments();
  const [walletMonthMetaData, setWalletMonthMetaData] =
    useState<WalletMonthMetaData>({});

  useEffect(() => {
    getUserCommunityPayments();
  }, []);

  useEffect(() => {
    const monthFromUrl = searchParams.get("month") || null;
    setMonth(monthFromUrl);
    const yearFromUrl = searchParams.get("year") || null;
    setYear(yearFromUrl);
  }, [searchParams]);

  useEffect(() => {
    getChosenPaymentJobs();
  }, [year, month, communityPayments]);

  useEffect(() => {
    getMetadataFromJobs();
  }, [jobs?.length]);

  const getChosenPaymentJobs = () => {
    if (!communityPayments.length) return;
    if (year && month) {
      const chosenPaymentPeriod = communityPayments?.find(
        (payment) => payment.year === +year && payment.month === +month
      );
      if (chosenPaymentPeriod) {
        getJobs({
          query: {
            filters: {
              idJob: chosenPaymentPeriod.jobIds,
              paymentIds: chosenPaymentPeriod.paymentIds,
            },
          },
        });
      }
    } else {
      setJobs([]);
    }
  };

  const getMetadataFromJobs = () => {
    const durationArray = jobs?.map((job) => {
      if (job?.duration) return job.duration;
    });
    setWalletMonthMetaData({
      maxDuration: _.max(durationArray),
    });
  };

  return month !== null && year !== null ? (
    <WalletMonth
      month={month}
      year={year}
      metaData={walletMonthMetaData}
      defaultQuery={defaultQuery}
      jobs={jobs as JobWithPayment[]}
      isJobsLoading={isJobsLoading}
    />
  ) : (
    <WalletOverview
      communityPayments={communityPayments}
      metaData={metaData}
      isLoading={isLoading}
    />
  );
};

export default Wallet;
