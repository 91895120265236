import React, { useEffect, useState } from "react";
import {
  CommunityPaymentResponseData,
  CommunityPaymentResponseMetaData,
  User,
} from "@sumit-platforms/types";
import { GeneralTable, PaymentsOverviewRow } from "@sumit-platforms/ui-bazar";
import { useHeadCells } from "@sumit-platforms/ui-bazar/hooks";
import {
  getPriceStringWithCurrency,
  monthNames,
} from "@sumit-platforms/ui-bazar/utils";
import { useTranslation } from "react-i18next";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";

interface Props {
  user: User;
  communityPayments: CommunityPaymentResponseData;
  metaData: CommunityPaymentResponseMetaData;
  isLoading: boolean;
  openCommunityBonusModal: () => void;
}

const CommunityUserPaymentsOverview = ({
  user,
  metaData,
  isLoading,
  communityPayments,
  openCommunityBonusModal,
}: Props) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<PaymentsOverviewRow[]>([]);
  const { headCells } = useHeadCells({
    headCellsKeys: ["month", "jobsAmount", "totalPayments", "actions"],
    labelIcon: { actions: faPlusCircle },
    labelAction: {
      actions: openCommunityBonusModal,
    },
    labelTooltip: {
      actions: t("add_community_bonus"),
    },
    cellLink: {
      month: (row) =>
        `/community/${
          user.idUser
        }?year=${row.year.toString()}&month=${row.month.toString()}`,
    },
    styles: {
      month: { width: "35%" },
      jobsAmount: { width: "30%" },
      totalPayments: { width: "30%" },
      actions: { width: "5%" },
    },
  });

  useEffect(() => {
    const walletOverviewRows = communityPayments
      ? createPaymentsOverviewRow(communityPayments)
      : [];
    setRows(walletOverviewRows);
  }, [communityPayments]);

  const createPaymentsOverviewRow = (
    responseData: CommunityPaymentResponseData
  ) => {
    const rows: PaymentsOverviewRow[] = [];
    for (const paymentItem of responseData) {
      const row: PaymentsOverviewRow = {
        key: `${paymentItem.year}/${paymentItem.month}`,
        jobsAmount: paymentItem.jobIds.length,
        month: paymentItem.month,
        monthString: `${t(monthNames[paymentItem.month])} ${paymentItem.year}`,
        totalPaymentsString: getPriceStringWithCurrency({
          price: paymentItem.totalPayments,
          currency: "ILS",
        }),
        totalPayments: paymentItem.totalPayments,
        sortableMonth: paymentItem.year * 100 + paymentItem.month,
        year: paymentItem.year,
      };
      rows.push(row);
    }
    return rows;
  };

  return (
    <GeneralTable
      headCells={headCells}
      rows={rows}
      allowSelect={false}
      loading={isLoading}
    />
  );
};

export default CommunityUserPaymentsOverview;
