import {
  AssignmentRequest,
  JobFilterOptions,
  MyAssignment,
  QueryParams,
} from "@sumit-platforms/types";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import assignmentService from "../services/assignmentService";

const useAssignments = (): {
  myAssignmentRequests: MyAssignment[];
  setMyAssignmentRequests: Dispatch<SetStateAction<MyAssignment[]>>;
  getAssignedJobsByIdUser: () => void;
  assignmentRequests: AssignmentRequest[];
  setAssignmentRequests: Dispatch<SetStateAction<AssignmentRequest[]>>;
  getAssignmentRequests: (
    query: QueryParams<JobFilterOptions>
  ) => Promise<void>;
  isLoading: boolean;
} => {
  const [myAssignmentRequests, setMyAssignmentRequests] = useState<
    MyAssignment[]
  >([]);
  const [assignmentRequests, setAssignmentRequests] = useState<
    AssignmentRequest[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAssignmentRequests = useCallback(
    async (query: QueryParams<JobFilterOptions>) => {
      setIsLoading(true);
      const _assignmentRequests = await assignmentService.getAssignmentRequests(
        query
      );
      setAssignmentRequests([..._assignmentRequests]);
      setIsLoading(false);
    },
    []
  );

  const getAssignedJobsByIdUser = useCallback(async () => {
    setIsLoading(true);
    const _assignmentRequestsByUserId =
      await assignmentService.getAssignedJobsByIdUser();
    setMyAssignmentRequests([..._assignmentRequestsByUserId]);
    setIsLoading(false);
  }, []);

  return {
    myAssignmentRequests,
    setMyAssignmentRequests,
    getAssignedJobsByIdUser,
    assignmentRequests,
    setAssignmentRequests,
    getAssignmentRequests,
    isLoading,
  };
};

export default useAssignments;
