import axios from "axios";
import { Invoice, JobInvoice } from "@sumit-platforms/types";

const InvoiceService = ({ config }: { config: any }) => {
  const invoiceUrl = `${config.server.host}/${config.server.invoices}`;

  const getInvoices = async (idClient: number) => {
    try {
      const response = await axios.get<Invoice[]>(
        `${invoiceUrl}/getClientInvoices`,
        {
          params: { idClient },
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createNewInvoice = async ({
    invoice,
  }: {
    invoice: Partial<Invoice>;
  }) => {
    try {
      const response = await axios.post(`${invoiceUrl}/createNewInvoice`, {
        invoice,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateInvoice = async ({
    invoice,
    idInvoice,
  }: {
    invoice?: Partial<Invoice>;
    idInvoice: number;
  }) => {
    try {
      const response = await axios.put(`${invoiceUrl}/updateInvoice`, {
        invoice,
        idInvoice,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteInvoice = async (idInvoice: number) => {
    try {
      const response = await axios.delete<Invoice>(
        `${invoiceUrl}/${idInvoice}`
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getJobInvoice = async (idJob: number) => {
    try {
      const response = await axios.get<JobInvoice>(
        `${invoiceUrl}/getJobInvoice`,
        {
          params: idJob,
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const updateJobInvoice = async ({
    idJobs,
    jobInvoice,
  }: {
    idJobs: number[];
    jobInvoice: Partial<JobInvoice>;
  }) => {
    try {
      const response = await axios.put<JobInvoice>(
        `${invoiceUrl}/getJobInvoice`,
        {
          idJobs,
          jobInvoice,
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const upsertJobsInvoice = async ({
    idJobs,
    jobInvoice,
  }: {
    idJobs: number[];
    jobInvoice: Partial<JobInvoice>;
  }) => {
    try {
      const response = await axios.post<JobInvoice>(
        `${invoiceUrl}/upsertJobsInvoice`,
        {
          idJobs,
          jobInvoice,
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getInvoices,
    createNewInvoice,
    upsertJobsInvoice,
    updateInvoice,
    deleteInvoice,
    getJobInvoice,
    updateJobInvoice,
  };
};

export default InvoiceService;
