import config from "../config";
import axios from "axios";
import {
  DocxFormatType,
  SpeakersDocxConfig,
  TableDocxConfig,
} from "@sumit-platforms/types";

const docxTemplatesEndpoint = `${config.server.host}/${config.server.docxTemplates}`;

const DocxTemplateService = () => {
  const addDocxTemplate = async ({
    settings,
    idClient,
    docxFormatType,
  }: {
    settings: SpeakersDocxConfig | TableDocxConfig;
    idClient: number;
    docxFormatType: DocxFormatType;
  }) => {
    try {
      const respones = await axios.post(docxTemplatesEndpoint, {
        settings,
        idClient,
        docxFormatType,
      });
      return respones.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const saveDocxTemplate = async (
    settings: SpeakersDocxConfig,
    idDocxTemplate: number
  ) => {
    try {
      const respones = await axios.put(docxTemplatesEndpoint, {
        settings,
        idDocxTemplate,
      });
      return respones.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteDocxTemplate = async (idDocxTemplate: number) => {
    try {
      return axios.delete(`${docxTemplatesEndpoint}/${idDocxTemplate}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return { addDocxTemplate, saveDocxTemplate, deleteDocxTemplate };
};

export default DocxTemplateService();
