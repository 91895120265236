import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { grayColor3 } from "../../bazar-theme";

import "./InformationBox.scss";
import React from "react";

export const InformationBox = ({
  primaryTitle,
  primarySubtitleStack,
  leftBottomDetailsStack,
  rightSectionStack,
  imageSrc,
  boxAction,
}: {
  primaryTitle: string;
  primarySubtitleStack?: { label: string }[];
  rightSectionStack: { title: string; value: string }[];
  leftBottomDetailsStack?: { label: string }[];
  boxAction?: { onClick: () => void; icon: IconDefinition }[];
  imageSrc?: string;
}) => {
  return (
    <Box
      className={"InformationBox"}
      p={2}
      display={"flex"}
      flexDirection="column"
      justifyContent={"center"}
    >
      <Grid container py={3}>
        <Grid item xs={5}>
          <Box pb={2} display={"flex"}>
            {imageSrc ? (
              <Box px={2} display={"flex"} alignItems={"center"}>
                <img
                  className={"Image"}
                  src={imageSrc}
                  alt={"information_box_image"}
                />
              </Box>
            ) : null}
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Box>
                <h3>{primaryTitle}</h3>
              </Box>
              {primarySubtitleStack && (
                <Stack
                  className="contact"
                  color={grayColor3}
                  direction="row"
                  divider={
                    <Box
                      component="hr"
                      sx={{
                        border: `0.5px solid ${grayColor3}`,
                      }}
                    />
                  }
                  spacing={2}
                >
                  {primarySubtitleStack?.length
                    ? primarySubtitleStack?.map((info) => (
                        <p key={info.label}>{info.label}</p>
                      ))
                    : null}
                </Stack>
              )}
            </Box>
          </Box>
          <Box px={2} display={"flex"}>
            {leftBottomDetailsStack?.length ? (
              <Stack
                className="contact"
                direction="row"
                divider={
                  <Box
                    component="hr"
                    sx={{
                      border: `0.5px solid ${grayColor3}`,
                    }}
                  />
                }
                spacing={2}
              >
                {leftBottomDetailsStack.map((info, i) =>
                  info.label ? <p key={i}>{info.label}</p> : null
                )}
              </Stack>
            ) : null}
          </Box>
        </Grid>
        <Divider color={grayColor3} orientation="vertical" flexItem />
        <Grid item xs={6} display={"flex"}>
          <Box
            px={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            ml={2}
            sx={{ width: "100%" }}
          >
            {rightSectionStack?.length
              ? rightSectionStack.map((section) => (
                  <div
                    key={`${section.title}-${section.value}`}
                    className={"rightSectionPair"}
                  >
                    <Box className={"keyOf"}>
                      <span>{section.title}: </span>
                    </Box>
                    <Box>
                      <p>{section.value}</p>
                    </Box>
                  </div>
                ))
              : null}
            {boxAction?.length ? (
              <Box
                display={"flex"}
                py={2}
                className={"actions"}
                gap={2}
                justifyContent={"flex-end"}
              >
                {boxAction?.map((action) => (
                  <div
                    key={action.onClick.toString()}
                    onClick={action.onClick && action.onClick}
                  >
                    <FontAwesomeIcon icon={action.icon} color={grayColor3} />
                  </div>
                )) || null}
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
