import { TFunction } from "i18next";
import { faAlignJustify } from "@fortawesome/pro-light-svg-icons";
import { Align, GeneralPage } from "@sumit-platforms/types";
import { alignOptions, fontFamilyOptions, fontSizeOptions } from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getGeneralPageInputs: (
  args: GeneralPage,
  t: TFunction
) => PageSettings<{ general: GeneralPage }> = (
  args: GeneralPage,
  t: TFunction
) => {
  return {
    settings: { general: args },
    pageTab: t("settings_protocol_general"),
    sections: [
      {
        title: t("settings_protocol_general"),
        paragraph: t("settings_protocol_general_paragraph_1"),
      },
      {
        inputs: [
          {
            isColumn: true,
            label: `${t("settings_protocol_general_preset_name")}:`,
            type: "text",
            key: "general.presetName",
          },
        ],
      },
      {
        inputs: [
          {
            isColumn: true,
            label: `${t("settings_protocol_general_format")}:`,
            type: "toggle",
            exclusive: true,
            key: "general.format",
            buttons: [
              {
                value: "pages",
                label: t("pages"),
              },
              {
                value: "audio",
                label: t("audio"),
              },
            ],
          },
        ],
      },
      {
        inputs: [
          {
            label: `${t("settings_protocol_general_font")}:`,
            type: "select",
            options: fontFamilyOptions,

            key: "general.font.fontFamily",
          },
          {
            type: "select",
            options: fontSizeOptions,

            key: "general.font.fontSize",
          },
          {
            type: "toggle",
            exclusive: true,
            buttons: [
              ...alignOptions,
              {
                value: Align.justify,
                label: faAlignJustify,
              },
            ],
            key: "general.font.align",
          },
        ],
      },
      {
        inputs: [
          {
            label: `${t("settings_protocol_general_row_count")}:`,
            type: "switch",
            key: "general.rowCount",
          },
        ],
      },
    ],
  };
};
