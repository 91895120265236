import { JobSpeaker } from "@sumit-platforms/types";
import React, { FC } from "react";
import stc from "string-to-color";

import SpeakerNameEditor from "../SpeakerNameEditor/SpeakerNameEditor";

interface Props {
  speakers: JobSpeaker[];
  renameSpeaker: (
    speaker: string,
    newSpeaker: string,
    speakers: JobSpeaker[],
    mergeSpeakerRanges: boolean
  ) => boolean;
}

const JobSpeakersPanel: FC<Props> = ({ speakers, renameSpeaker }) => {
  return (
    <div className="JobSpeakersPanel">
      <div className="speakersContainer">
        {speakers.map((speaker, i) => (
          <span
            className="speaker"
            style={{
              color: stc(speaker),
            }}
            key={i}
          >
            <SpeakerNameEditor
              speakerName={speaker.name}
              speakers={speakers}
              autocomplete={false}
              handleSetNewSpeakerName={(newSpeaker) =>
                renameSpeaker(speaker.name, newSpeaker.name, speakers, true)
              }
              placeholder={speaker.name}
              isEditing={false}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default JobSpeakersPanel;
