import _ from "lodash";
import { TFunction } from "i18next";
import {
  Align,
  GeneralLang,
  ValidationLang,
  ValidationGeneralPage,
  ValidationLanguagePage,
  ValidationsConfig,
  ReadSpeed,
} from "@sumit-platforms/types";
import { PageSettings, Section } from "../../../core/SettingsPage/SettingsPage";
import {
  alignOptions,
  decorationOptions,
  fontFamilyOptions,
  fontSizeOptions,
} from "../shared";
import { faAlignJustify, faMarker } from "@fortawesome/pro-light-svg-icons";

type ValidationPage = ValidationGeneralPage | ValidationLanguagePage;

export const getValidationPageInputs = ({
  lang,
  args,
  t,
}: {
  lang: GeneralLang | ValidationLang;
  args: ValidationPage;
  t: TFunction;
}): PageSettings<ValidationsConfig> => {
  const settings = {} as ValidationsConfig;
  if (lang === "general_lang") {
    settings[lang] = args as ValidationGeneralPage;
  } else {
    settings[lang] = args as ValidationLanguagePage;
  }

  let generalSections: Partial<Section>[] = [];
  if (lang === "general_lang") {
    generalSections = [
      {
        title: t("settings_validation_general"),
        paragraph: t("settings_validation_general_description"),
      },
      {
        inputs: [
          {
            isColumn: true,
            label: `${t("settings_validation_preset_name")}:`,
            type: "text",
            key: `${lang}.presetName`,
          },
        ],
      },
      {
        label: t("settings_validation_subtitles_length"),
        //   titleHasActivate: true,
        //   inputs: [
        //     {
        //       type: "switch",
        //       key: `${lang}.subtitlesLengthIsActive`,
        //     },
        //   ],
      },
      {
        // disableKey: `${lang}.subtitlesLengthIsActive`,

        inputs: [
          {
            isColumn: true,
            label: `${t("settings_validation_min")}:`,
            type: "number",
            min: 0,
            key: `${lang}.subtitlesLength.min`,
          },
          {
            isColumn: true,
            label: `${t("settings_validation_max")}:`,
            type: "number",
            min: 0,
            key: `${lang}.subtitlesLength.max`,
          },
        ],
      },
      // {
      //   label: t("Read speed"),
      //   titleHasActivate: true,
      //   inputs: [
      //     {
      //       type: "switch",
      //       key: `${lang}.readSpeedIsActive`,
      //     },
      //   ],
      // },
      {
        // disableKey: `${lang}.readSpeedIsActive`,
        inputs: [
          {
            type: "toggle",
            key: `${lang}.readSpeed.readSpeed`,
            buttons: [
              { value: ReadSpeed.CPS, label: "CPS" },
              { value: ReadSpeed.WPM, label: "WPM" },
            ],
            exclusive: true,
          },
        ],
      },
      {
        // disableKey: `${lang}.readSpeedIsActive`,
        inputs: [
          {
            isColumn: true,
            label: t("settings_validation_chars_per_second"),
            type: "number",
            min: 0,
            key: `${lang}.readSpeed.charsPerSecond`,
            style: {
              width: 170,
            },
            isDisabled: () =>
              _.get(settings, `${lang}.readSpeed.readSpeed`) !== ReadSpeed.CPS,
          },
          {
            isColumn: true,
            label: t("settings_validation_words_per_minute"),
            type: "number",
            min: 0,
            key: `${lang}.readSpeed.wordsPerMinute`,
            style: {
              width: 170,
            },
            isDisabled: () =>
              _.get(settings, `${lang}.readSpeed.readSpeed`) !== ReadSpeed.WPM,
          },
        ],
      },
      {
        label: t("settings_validation_frames_between_subtitles"),
        // titleHasActivate: true,
        // inputs: [
        //   {
        //     type: "switch",
        //     key: `${lang}.framesBetweenSubtitlesIsActive`,
        //   },
        // ],
      },
      {
        // disableKey: `${lang}.framesBetweenSubtitlesIsActive`,
        inputs: [
          {
            isColumn: true,
            type: "number",
            min: 0,
            key: `${lang}.framesBetweenSubtitles`,
            style: {
              width: 170,
            },
          },
        ],
      },
      {
        label: t("settings_validation_frames_between_subtitles_to_cutline"),
        //   titleHasActivate: true,
        //   inputs: [
        //     {
        //       type: "switch",
        //       key: `${lang}.framesBetweenSubtitlesToCutlineIsActive`,
        //     },
        //   ],
      },
      {
        // disableKey: `${lang}.framesBetweenSubtitlesToCutlineIsActive`,
        inputs: [
          {
            isColumn: true,
            type: "number",
            min: 0,
            key: `${lang}.framesBetweenSubtitlesToCutline`,
          },
        ],
      },
    ];
  }

  return {
    settings,
    pageTab: t(lang),
    sections: [
      ...generalSections,
      {
        title:
          lang === "general_lang"
            ? t("settings_validation_default_settings")
            : t(lang),
        paragraph:
          lang === "general_lang"
            ? t("settings_validation_default_settings_description")
            : t("settings_validation_default_settings_description_lang"),
      },
      {
        isColumn: false,
        inputs: [
          {
            isColumn: true,
            label: t("settings_validation_limit_subtitles_line"),
            type: "number",
            min: 0,
            key: `${lang}.limitSubtitlesLine`,
            style: {
              width: 170,
            },
          },
          {
            isColumn: true,
            label: t("settings_validation_limit_line_length"),
            type: "number",
            min: 0,
            key: `${lang}.limitLineLength`,
            style: {
              width: 170,
            },
          },
        ],
      },
      {
        paragraph: `${t("settings_validation_font")}:`,
      },
      {
        inputs: [
          {
            type: "select",
            options: fontFamilyOptions,
            key: `${lang}.font.fontFamily`,
          },
          {
            type: "select",
            options: fontSizeOptions,
            key: `${lang}.font.fontSize`,
          },
          {
            type: "colorPicker",
            key: `${lang}.font.color`,
          },
          {
            type: "colorPicker",
            key: `${lang}.font.backgroundColor`,
            icon: faMarker,
          },
        ],
      },
      {
        inputs: [
          {
            type: "toggle",
            buttons: [
              ...alignOptions,
              {
                value: Align.justify,
                label: faAlignJustify,
              },
            ],
            key: `${lang}.font.align`,
            exclusive: true,
          },
          {
            type: "toggle",
            exclusive: false,
            buttons: decorationOptions,
            key: `${lang}.font.textDecoration`,
          },
        ],
      },
    ],
  };
};
