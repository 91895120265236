import { t } from "i18next";
import {
  FilterRangeValue,
  FilterSingularValue,
} from "../core/SearchAndFilters/SearchAndFilters";
import { secondsToTimeString } from "./formatters";
import { format } from "date-fns";
import { TODAY_MIDNIGHT } from "../constants/globals";
import { JobStatus } from "@sumit-platforms/types";
import { getMustStatusVisual } from "./helpers";

export const formatBasicChip = (f: FilterSingularValue) => {
  return f?.value?.join(",");
};

export const formatLanguagesChip = (f: FilterRangeValue) => {
  return `${f.valueFrom && f.valueFrom[0] ? t(f.valueFrom[0]) : ""} ${
    f.valueTo && f.valueTo[0] ? "- " + t(f.valueTo[0]) : ""
  }`;
};

export const formatDurationChip = (f: FilterRangeValue) => {
  return `${secondsToTimeString(f.valueFrom || 1)} - ${secondsToTimeString(
    f.valueTo || 1
  )}`;
};

export const formatDateChip = (f: FilterRangeValue) => {
  return `${format(
    (f.valueFrom && new Date(f.valueFrom)) || TODAY_MIDNIGHT,
    "dd/MM/yyyy"
  )} - ${format(
    (f.valueTo && new Date(f.valueTo)) || TODAY_MIDNIGHT,
    "dd/MM/yyyy"
  )}`;
};
export const formatClientJobStatusChip = (f: FilterSingularValue) => {
  return f.value
    ?.map((s: JobStatus) => t(getMustStatusVisual(s as JobStatus).statusTitle))
    .join(",");
};
