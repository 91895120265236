import { ThemeProvider } from "@emotion/react";
import React, { FC, useEffect, useMemo } from "react";
import { bazarTheme } from "../../bazar-theme";
import { Box } from "@mui/material";
import { Button } from "../Button/Button";
import { useSearchParams } from "react-router-dom";

export interface View {
  tab: string;
  view: JSX.Element;
}

export type Views = Record<string, View>;

interface TabViewProps {
  views: Views;
  defaultTab?: string;
}

export const TabView: FC<TabViewProps> = ({ views, defaultTab }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = useMemo(() => {
    return searchParams.get("tab") || defaultTab;
  }, [searchParams, defaultTab]);

  const handleChangeView = (key: string) => {
    setSearchParams({ tab: key });
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box display={"flex"} flexDirection={"column"}>
        <Box py={3} width={"100%"} display={"flex"} gap={2}>
          {Object.entries(views).map((entry) => {
            const [key, value] = entry;
            return (
              <Button
                variant={key === tab ? "contained" : "text"}
                onClick={() => handleChangeView(key)}
                key={key}
                color={key === tab ? "secondary" : "primary"}
                sx={{
                  boxShadow: "none",
                  borderRadius: 5,
                  fontSize: 19,
                  paddingY: 1.2,
                  paddingX: 2,
                  lineHeight: 1,
                }}
              >
                {value.tab}
              </Button>
            );
          })}
        </Box>
        <Box>{tab && views[tab]?.view}</Box>
      </Box>
    </ThemeProvider>
  );
};
