import { ClientSTT, defaultClientSttSettings } from "@sumit-platforms/types";

export const supportedLangs = defaultClientSttSettings;

export const sttDefaultSTTSettings: ClientSTT = {
  protocol: supportedLangs.protocol,
  interview: supportedLangs.interview,
  subtitles: supportedLangs.subtitles,
  brief: supportedLangs.brief,
};
