import React, { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Tag } from "@sumit-platforms/types";

import { TagOption, TagsSelect } from "../../core/TagsSelect/TagsSelect";
import { Button } from "../../core/Button/Button";

import { useTags } from "../../hooks/useTags";

import "./TagsModal.scss";

export interface TagsModalProps {
  submit: (tags: Tag[]) => void;
  cancel: () => void;
  tags: Tag[];
  tagService: any;
  entity: "job";
  idClient: number;
}

export const TagsModal = ({
  submit,
  cancel,
  tags,
  entity,
  tagService,
  idClient,
}: TagsModalProps) => {
  const { t } = useTranslation();
  const {
    searchTags,
    tags: _tags,
    submitTag,
  } = useTags({ entity, tagService });

  const [selectedTags, setSelectedTags] = useState<Tag[]>(tags);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSelect = (tagOptions: TagOption[]) => {
    const _selectedTags = tagOptions.map((t) => t.valueParent);
    setSelectedTags(_selectedTags);
  };

  const handleOnCreate = async (value: string, idClient: number) => {
    try {
      setIsLoading(true);
      const _tag = await submitTag(value, idClient);
      if (_tag) {
        setSelectedTags((prev) => [...prev, _tag]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await submit(selectedTags);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Box className="TagsModal" px={3} py={2}>
      <Box pt={2}>{t("tags_instructions")}</Box>
      <Box py={3}>
        <TagsSelect
          selected={selectedTags.map((s) => ({
            label: s.name,
            value: s.idTag,
            valueParent: s,
          }))}
          options={_tags.map((s) => ({
            label: s.name,
            value: s.idTag,
            valueParent: s,
          }))}
          search={searchTags}
          onSelect={handleOnSelect}
          onCreate={handleOnCreate}
          idClient={idClient}
        />
      </Box>
      <Box display="flex" justifyContent={"center"} gap={1}>
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button loading={isLoading} onClick={handleSave} color="primary">
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};
