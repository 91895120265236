import { TFunction } from "i18next";
import { HeaderFooterPage } from "@sumit-platforms/types";
import { alignOptions } from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getHeaderFooterPageInputs: (
  args: HeaderFooterPage,
  t: TFunction
) => PageSettings<{
  headerFooter: HeaderFooterPage;
}> = (args, t) => ({
  settings: { headerFooter: args },
  pageTab: t("settings_protocol_header_footer"),
  sections: [
    {
      title: t("settings_protocol_header_footer_first_page_header"),
      paragraph: t("settings_protocol_header_footer_paragraph_1"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "headerFooter.firstPageHeader.isActive",
        },
      ],
    },
    {
      disableKey: "headerFooter.firstPageHeader.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_logo")}:`,
          type: "switch",
          key: "headerFooter.firstPageHeader.logo.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.firstPageHeader.logo.align",
        },
        {
          type: "imageUpload",
          key: "headerFooter.firstPageHeader.logo.url",
          fileKey: "headerFooter.firstPageHeader.logo.file",
        },
      ],
    },
    {
      disableKey: "headerFooter.firstPageHeader.isActive",
      inputs: [
        {
          type: "slider",
          key: "headerFooter.firstPageHeader.logo.size",
        },
      ],
    },
    {
      disableKey: "headerFooter.firstPageHeader.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_job_name")}:`,
          type: "switch",
          key: "headerFooter.firstPageHeader.jobName.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.firstPageHeader.jobName.align",
        },
      ],
    },
    {
      disableKey: "headerFooter.firstPageHeader.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_job_date")}:`,
          type: "switch",
          key: "headerFooter.firstPageHeader.jobDate.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.firstPageHeader.jobDate.align",
        },
      ],
    },
    {
      title: `${t("settings_protocol_header_footer_other_pages_header")}:`,
      paragraph: t("settings_protocol_header_footer_paragraph_2"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "headerFooter.otherPagesHeader.isActive",
        },
      ],
    },
    {
      disableKey: "headerFooter.otherPagesHeader.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_logo")}:`,
          type: "switch",
          key: "headerFooter.otherPagesHeader.logo.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.otherPagesHeader.logo.align",
        },
        {
          type: "imageUpload",
          key: "headerFooter.otherPagesHeader.logo.url",
          fileKey: "headerFooter.otherPagesHeader.logo.file",
        },
      ],
    },
    {
      disableKey: "headerFooter.otherPagesHeader.isActive",
      inputs: [
        {
          type: "slider",
          key: "headerFooter.otherPagesHeader.logo.size",
        },
      ],
    },
    {
      disableKey: "headerFooter.otherPagesHeader.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_job_name")}:`,
          type: "switch",
          key: "headerFooter.otherPagesHeader.jobName.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.otherPagesHeader.jobName.align",
        },
      ],
    },
    {
      disableKey: "headerFooter.otherPagesHeader.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_job_date")}:`,
          type: "switch",
          key: "headerFooter.otherPagesHeader.jobDate.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.otherPagesHeader.jobDate.align",
        },
      ],
    },
    {
      title: `${t("settings_protocol_header_footer_footer")}`,
      paragraph: t("settings_protocol_header_footer_paragraph_3"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "headerFooter.footer.isActive",
        },
      ],
    },
    {
      disableKey: "headerFooter.footer.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_logo")}:`,
          type: "switch",
          key: "headerFooter.footer.logo.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.footer.logo.align",
        },
        {
          type: "imageUpload",
          key: "headerFooter.footer.logo.url",
          fileKey: "headerFooter.footer.logo.file",
        },
      ],
    },
    {
      disableKey: "headerFooter.footer.isActive",
      inputs: [
        {
          type: "slider",
          key: "headerFooter.footer.logo.size",
        },
      ],
    },
    {
      disableKey: "headerFooter.footer.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_page_numbering")}:`,
          type: "switch",
          key: "headerFooter.footer.pageNumbering.isActive",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "headerFooter.footer.pageNumbering.align",
        },
      ],
    },
    {
      disableKey: "headerFooter.footer.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_header_footer_page_footer_text")}:`,
          type: "text",
          key: "headerFooter.footer.footerText",
        },
      ],
    },
  ],
});
