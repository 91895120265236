import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-solid-svg-icons";

import "./ScrollerContainer.scss";
import { Button } from "../Button/Button";
import { blackColor } from "../../bazar-theme";
import _ from "lodash";
interface ScrollerContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const ScrollerContainer = ({
  children,
  style,
}: ScrollerContainerProps) => {
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const handleScroll = (direction: "left" | "right") => {
    if (!cardsContainerRef.current) return;
    const scrollDistance = 650;
    if (direction === "left") {
      cardsContainerRef.current.scrollLeft! -= scrollDistance;
    } else if (direction === "right") {
      cardsContainerRef.current.scrollLeft! += scrollDistance;
    }
  };

  useEffect(() => {
    if (cardsContainerRef.current?.scrollWidth) {
      const isScrollable =
        cardsContainerRef.current.scrollWidth >
        cardsContainerRef.current.clientWidth;
      setShowRightArrow(isScrollable);
    }
  }, [cardsContainerRef.current?.scrollWidth]);

  const onScroll = _.throttle(() => {
    if (!cardsContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = cardsContainerRef.current;
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    const showLeft = scrollLeft > 0;
    setShowLeftArrow(showLeft);
  }, 200);
  if (!children || _.isEmpty(children)) return null;
  return (
    <div className={"scrollerContainerWrapper"}>
      <div
        className={"scrollerContainer"}
        onScroll={onScroll}
        ref={cardsContainerRef}
        style={style}
      >
        {children}
      </div>
      {showRightArrow && (
        <Button
          sx={{
            height: "40px",
            outline: "none",
            position: "absolute",
            top: "49%",
            right: "-60px",
            border: "none",
            "&:hover": {
              border: "none",
              backgroundColor: "transparent",
            },
          }}
          variant={"outlined"}
          onClick={() => handleScroll("right")}
        >
          <FontAwesomeIcon
            size={"xl"}
            onClick={() => handleScroll("right")}
            icon={faChevronsRight}
          />
        </Button>
      )}
      {showLeftArrow && (
        <Button
          variant={"outlined"}
          sx={{
            height: "40px",
            outline: "none",
            position: "absolute",
            top: "49%",
            left: "-60px",
            border: "none",
            "&:hover": {
              border: "none",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => handleScroll("left")}
        >
          <FontAwesomeIcon
            size={"xl"}
            onClick={() => handleScroll("left")}
            icon={faChevronsLeft}
          />
        </Button>
      )}
    </div>
  );
};
