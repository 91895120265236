import _ from "lodash";
import React, { useCallback } from "react";
import { Box, ListItemButton } from "@mui/material";
import { JobRevisionsRequestParams, JobRevision } from "@sumit-platforms/types";
import { VirtualizedList } from "../../components/VirtualizedList/VirtualizedList";
import { RestoreJobItem } from "./RestoreJobItem";
import { useTranslation } from "react-i18next";
import { RestoreJobHeader } from "./RestoreJobHeader";

import "./RestoreJob.scss";

interface RestoreJobProps {
  idJob: number;
  revisions: JobRevision[];
  isLoading: boolean;
  onRevisionClick: (revision: JobRevision) => void;
  chosenRevision: JobRevision | null;
  handleToggleAutoSaves: () => void;
  autoSaveChecked: boolean;
}
const itemHeight = 55;

export const RestoreJob = ({
  onRevisionClick,
  revisions,
  isLoading,
  handleToggleAutoSaves,
  chosenRevision,
  autoSaveChecked,
}: RestoreJobProps) => {
  const { t } = useTranslation();

  const onClick = useCallback(
    (revision: JobRevision) => {
      onRevisionClick(revision);
    },
    [onRevisionClick]
  );

  return (
    <Box>
      <Box>
        <RestoreJobHeader
          isLoading={isLoading}
          autoSaveChecked={autoSaveChecked}
          handleToggleAutoSaves={handleToggleAutoSaves}
        />
        {_.isEmpty(revisions) && !isLoading ? (
          <ListItemButton disabled sx={{ margin: "14px 0", padding: 0 }}>
            {t("no_options")}
          </ListItemButton>
        ) : (
          <VirtualizedList
            items={revisions}
            itemCount={revisions.length}
            itemHeight={itemHeight}
            maxHeight={300}
            render={(revision: JobRevision) => (
              <RestoreJobItem
                disabled={isLoading}
                revision={revision}
                onClick={onClick}
                height={itemHeight}
                selected={
                  chosenRevision?.idJobRevision === revision.idJobRevision
                }
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};
