import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { DateCalendar } from "@mui/x-date-pickers";
import { Box, ThemeProvider } from "@mui/material";
import { faFilterCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { bazarTheme } from "../../../bazar-theme";
import Input from "../../Input/Input";
import { format } from "date-fns";

import "./DateFilter.scss";

export interface DateFilterProps {
  value: Date;
  setValue?: (value: Date) => void;
  title?: string;
  resetValue?: Date;
  position?: "start" | "end";
  maxDate?: Date;
  minDate?: Date;
  useHour?: boolean;
}

export const DateFilter = ({
  value,
  setValue = (value: Date) => _.noop(value),
  title,
  resetValue,
  position = "start",
  maxDate,
  minDate,
  useHour,
}: DateFilterProps) => {
  const reset = () => {
    resetValue && setValue(resetValue);
  };

  const hour = useMemo(() => {
    return format(
      value ? new Date(value)?.getTime() : new Date().setTime(0),
      "HH:mm"
    );
  }, [value]);

  const handleOnChange = (newDate: Date | null) => {
    if (!newDate) return;
    if (!useHour) {
      const isStart = position === "start";
      newDate.setHours(isStart ? 0 : 23);
      newDate.setMinutes(isStart ? 0 : 59);
      newDate.setSeconds(isStart ? 0 : 59);
    }

    setValue(newDate);
  };

  const handleOnHourChange = (e) => {
    const _newValue = new Date(value);
    if (!_.isUndefined(e.target.value)) {
      const [hour, minute] = e.target.value.split(":");
      _newValue.setHours(parseInt(hour));
      _newValue.setMinutes(parseInt(minute));
    }
    handleOnChange(_newValue);
  };

  const formattedDate = useMemo<Date>(() => {
    return value
      ? new Date(value)
      : resetValue
      ? new Date(resetValue)
      : new Date();
  }, [value]);

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="BazarDateFilter">
        <span>{title && title}</span>
        {!_.isUndefined(resetValue) && (
          <FontAwesomeIcon
            icon={faFilterCircleXmark}
            onClick={reset}
            style={{ cursor: "pointer" }}
          />
        )}
        <Box className={"datePickerWrapper"}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateCalendar
              value={formattedDate}
              onChange={handleOnChange}
              maxDate={maxDate}
              minDate={minDate}
            />
            {useHour && (
              <Box mb={2} width={"50%"}>
                <Input
                  type="time"
                  value={hour}
                  onChange={handleOnHourChange}
                  style={{ fontSize: 12 }}
                />
              </Box>
            )}
          </LocalizationProvider>
        </Box>
      </div>
    </ThemeProvider>
  );
};
