import { TFunction } from "i18next";
import { IntervalsAndTimingPage } from "@sumit-platforms/types";
import { PageSettings } from "./../../../../core/SettingsPage/SettingsPage";

export const getIntervalsAndTiming: (
  args: IntervalsAndTimingPage,
  t: TFunction
) => PageSettings<{ intervalsAndTiming: IntervalsAndTimingPage }> = (
  args,
  t
) => ({
  settings: { intervalsAndTiming: args },
  pageTab: t("settings_intervals_and_timing"),
  sections: [
    {
      title: t("settings_intervals_and_timing"),
    },
    {
      inputs: [
        {
          isColumn: true,
          label: `${t("settings_intervals_intervals")}:`,
          type: "toggle",
          exclusive: true,
          key: "intervalsAndTiming.intervals",
          buttons: [
            {
              value: 10,
              label: `10 ${t("sec")}`,
            },
            {
              value: 20,
              label: `20 ${t("sec")}`,
            },
            {
              value: 30,
              label: `30 ${t("sec")}`,
            },
          ],
        },
      ],
    },
  ],
});
