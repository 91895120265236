import React, { useState } from "react";
import { ClientServicesAccess } from "@sumit-platforms/types";
import { SettingsPage } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const EditClientServicesAccess = ({
  servicesAccess,
  onCancel,
  onConfirm,
}: {
  servicesAccess?: ClientServicesAccess;
  onCancel: () => void;
  onConfirm: (servicesAccess: ClientServicesAccess) => void;
}) => {
  const { t } = useTranslation();
  const [servicesAccessSettings, setServicesAccessSettings] =
    useState<ClientServicesAccess>(servicesAccess || { extractVer3: false });

  const handleOnChange = (settings: ClientServicesAccess) => {
    setServicesAccessSettings(settings);
  };
  const handleOnConfirm = () => {
    onConfirm(servicesAccessSettings);
  };

  return (
    <Box className={"EditClientServicesAccess"} px={5}>
      <SettingsPage<ClientServicesAccess>
        onSettingsChange={handleOnChange}
        settings={servicesAccessSettings}
        sections={[
          {
            title: t("client_services_access"),
            paragraph: t("extract_access"),
            inputs: [
              {
                key: "extractVer3",
                type: "toggle",
                buttons: [
                  {
                    value: true,
                    label: t("enable"),
                  },
                  {
                    value: false,
                    label: t("disable"),
                  },
                ],
              },
            ],
          },
          {
            isBottom: true,
            inputs: [
              {
                key: "",
                type: "button",
                onClick: onCancel,
                variant: "outlined",
                buttonLabel: t("cancel"),
                color: "primary",
              },
              {
                key: "",
                type: "button",
                onClick: handleOnConfirm,
                variant: "contained",
                buttonLabel: t("confirm"),
                color: "primary",
              },
            ],
          },
        ]}
      />
    </Box>
  );
};
