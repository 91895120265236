import React, { useCallback, useMemo, useState } from "react";
import { Shortcut, ShortcutType } from "@sumit-platforms/types";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { KeyboardShortcutGroup } from "./KeyboardShortcutGroup";
import { Button } from "../Button/Button";

import "./KeyboardShortcutsModal.scss";

interface KeyboardShortcutsModalProps {
  userShortcuts: Shortcut[];
  onShortcutEdit: (shortcut: Shortcut[]) => Promise<void>;
  closeModal: () => void;
  closeAfterSubmit?: boolean;
}

export const KeyboardShortcutsModal = ({
  userShortcuts,
  onShortcutEdit,
  closeModal,
  closeAfterSubmit = true,
}: KeyboardShortcutsModalProps) => {
  const { t } = useTranslation();
  const [localShortcutsEdit, setLocalShortcutsEdit] = useState<Shortcut[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const groupedShortcuts = useMemo(
    () =>
      userShortcuts
        .filter((s) => s.hide !== true)
        .reduce((acc, shortcut) => {
          const type = shortcut.type as ShortcutType;
          const editorType = shortcut.editorType || "global";
          if (acc[type]) {
            if (!acc[type][editorType]) {
              acc[type][editorType] = [shortcut];
            } else {
              acc[type][editorType].push(shortcut);
            }
          }

          if (!acc[type]) {
            acc[type] = { [editorType]: [shortcut] };
          }
          return acc;
        }, {} as any),
    [userShortcuts]
  );

  const updateLocalUpdates = useCallback(
    (shortcut: Shortcut) => {
      const newUpdates = _.uniqBy([shortcut, ...localShortcutsEdit], "action");
      setLocalShortcutsEdit(newUpdates);
    },
    [onShortcutEdit, localShortcutsEdit]
  );

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true);
      await onShortcutEdit(localShortcutsEdit);
      if (closeAfterSubmit) closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [localShortcutsEdit]);

  const onCancel = useCallback(() => {
    setLocalShortcutsEdit([]);
    closeModal();
  }, [closeModal]);

  return (
    <Box className={"KeyboardShortcutsModal"}>
      <Typography variant="h6" gutterBottom align="center" my={1}>
        {t("keyboard_shortcuts")}
      </Typography>
      <Box className={"groupsWrapper"}>
        {_.entries(groupedShortcuts).map(([type, list]) => (
          <KeyboardShortcutGroup
            key={type}
            type={type}
            list={list as Record<string, Shortcut[]>}
            updateLocalUpdates={updateLocalUpdates}
            userShortcuts={_.uniqBy(
              [...localShortcutsEdit, ...userShortcuts],
              "action"
            )}
          />
        ))}
      </Box>
      <Box className={"buttonsWrapper"} gap={4}>
        <Button variant="outlined" onClick={onCancel}>
          {t("cancel") as string}
        </Button>
        <Button onClick={onSave} loading={isLoading}>
          {t("save") as string}
        </Button>
      </Box>
    </Box>
  );
};
