import { Client } from "./Client";
import {
  AssignStatus,
  AssignmentTask,
  Job,
  TaskStatus,
  AssignMethod,
} from "./Job";
import { Shortcut } from "../common/KeyboardShortcuts";

interface User {
  idUser: number;
  uid: string;
  country: string;
  languages: string[];
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  roles: { idClient: number; idRole: UserRole }[];
  clients: Client[];
  assignedJobs?: Job[];
  firebase?: any;
  loading?: any;
  error?: any;
  status: UserStatus;
  settings: UserSettings;
  createdAt?: Date;
}

type AssignedUser = User & {
  task: AssignmentTask;
  assignStatus: AssignStatus;
  taskStatus: TaskStatus;
  assignMethod: AssignMethod;
  assignCreatedAt: Date;
  idUsersJobs: number;
};

enum UserStatus {
  ACTIVE = "ACTIVE",
  NEW_MEMBER = "NEW_MEMBER",
  TRAINING = "TRAINING",
  DISABLED = "DISABLED",
  REJECTED = "REJECTED",
}

interface UsersMetadata {
  capacityRange: { from: number; to: number };
  jobsDoneRange?: { from: number; to: number };
  paymentsRange: { from: number; to: number };
  levelsRange: { from: number; to: number };
  invoices: {
    idInvoice: number;
    invoiceNumber: string;
  }[];
  roles?: UserRole[];
  tags: any[];
}
enum UserRole {
  GUEST = "GUEST",
  ADMIN = "ADMIN",
  CLIENT_ADMIN = "CLIENT_ADMIN",
  CLIENT_USER = "CLIENT_USER",
  COMMUNITY_MEMBER = "COMMUNITY_MEMBER",
  CS_MANAGER = "CS_MANAGER",
}
type UserFilterOptions = {
  idUser?: number[] | number;
  communityMemberStatus?: UserStatus[];
};

type UserSettings = {
  autoSave?: boolean;
  keyboardShortcuts?: Shortcut[];
};

export type {
  User,
  UserFilterOptions,
  AssignedUser,
  UsersMetadata,
  UserSettings,
};
export { UserStatus, UserRole };
