import {
  faBriefcase,
  faClipboardListCheck,
  faRightFromBracket,
  faStore,
  faUserPlus,
  faUsers,
  faUserTie,
  faWallet,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InviteNewUserModal, MenuItem } from "@sumit-platforms/ui-bazar";
import { NavigateFunction } from "react-router-dom";
import authService from "../../services/authService";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import userInvitationService from "../../services/userInvitationService";
import { UserRole, UserStatus, OperaAuthMap } from "@sumit-platforms/types";
import { useUser } from "../../store/user";
import { isAuthorized } from "@sumit-platforms/ui-bazar/utils";

export const authMap: OperaAuthMap = {
  "/": { roles: [UserRole.CS_MANAGER, UserRole.ADMIN] },
  availableJobs: {
    roles: [UserRole.COMMUNITY_MEMBER, UserRole.ADMIN],
    status: [UserStatus.TRAINING, UserStatus.ACTIVE],
  },
  myJobs: { roles: [UserRole.COMMUNITY_MEMBER, UserRole.ADMIN] },
  clients: { roles: [UserRole.CS_MANAGER, UserRole.ADMIN] },
  community: { roles: [UserRole.ADMIN, UserRole.CS_MANAGER] },
  wallet: { roles: [UserRole.COMMUNITY_MEMBER] },
  addNewUser: { roles: [UserRole.ADMIN, UserRole.CS_MANAGER] },
};

export const useMenuItems: (
  t: any,
  onNavigationMenuItemClick: (to: string) => void
) => MenuItem[] = (t, onNavigationMenuItemClick) => {
  const { setModalContent, clearModalContent } = useModal();
  const { user, roles } = useUser();
  const menuItemConfig = [
    {
      path: "",
      label: t("jobs"),
      icon: <FontAwesomeIcon icon={faClipboardListCheck} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap["/"],
      onClick: () => {
        onNavigationMenuItemClick("/");
      },
    },
    {
      path: "available-jobs",
      label: t("available_jobs"),
      icon: <FontAwesomeIcon icon={faStore} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.availableJobs,
      onClick: () => {
        onNavigationMenuItemClick("/available-jobs");
      },
    },
    {
      path: "my-jobs",
      label: t("my_jobs"),
      icon: <FontAwesomeIcon icon={faBriefcase} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.myJobs,
      onClick: () => {
        onNavigationMenuItemClick("/my-jobs");
      },
    },
    {
      path: "clients",
      label: t("clients"),
      icon: <FontAwesomeIcon icon={faUserTie} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.clients,
      onClick: () => {
        onNavigationMenuItemClick("/clients");
      },
    },
    {
      path: "community",
      label: t("community"),
      icon: <FontAwesomeIcon icon={faUsers} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.community,
      onClick: () => {
        onNavigationMenuItemClick("/community");
      },
    },
    {
      label: t("add_new_user"),
      isBottom: true,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.addNewUser,
      onClick: () => {
        setModalContent(
          <InviteNewUserModal
            onCancel={clearModalContent}
            onInvite={async (email, role) => {
              await userInvitationService.inviteNewUser(email, role);
              clearModalContent();
            }}
          />
        );
      },
    },
    {
      label: t("menu_logout"),
      icon: <FontAwesomeIcon icon={faRightFromBracket} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.menuLogout,
      onClick: async () => {
        await authService.logout();
      },
      isBottom: true,
    },
    {
      path: "wallet",
      label: t("wallet"),
      icon: <FontAwesomeIcon icon={faWallet} />,
      render: (children: React.ReactNode) => children,
      authConfig: authMap.wallet,
      onClick: () => {
        onNavigationMenuItemClick("/wallet");
      },
    },
  ];
  if (!user) return [];
  return menuItemConfig.filter((menuItem) => {
    return isAuthorized({
      authConfig: menuItem.authConfig,
      userRoles: roles,
      userStatus: user?.status,
    });
  }) as MenuItem[];
};
