import React from "react";
import { Box, Slider, ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";

export interface SliderInputProps {
  onChange?: any;
  disabled?: boolean;
  value?: number;
}

export const SliderInput = ({
  onChange,
  disabled,
  value,
}: SliderInputProps) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <Box sx={{ width: 400 }}>
        <Slider
          onChange={onChange}
          disabled={disabled}
          value={value}
          min={50}
          max={250}
          valueLabelDisplay="auto"
          step={10}
          marks
        />
      </Box>
    </ThemeProvider>
  );
};
