import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import {
  AssignMethod,
  AssignStatus,
  AssignedUser,
  AssignmentTask,
  Job,
  JobCommunityMembers,
  TaskStatus,
  UserStatus,
} from "@sumit-platforms/types";
import { Badge, Button, MultiSelect, Option } from "@sumit-platforms/ui-bazar";
import assignmentService from "../../../services/assignmentService";
import { useAlert } from "@sumit-platforms/ui-bazar/store";
import {
  getUserStatusVisual,
  isUnassignAllowed,
} from "@sumit-platforms/ui-bazar/utils";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { ConfirmUnassign } from "../ConfirmUnassign/ConfirmUnassign";
import { archiveColor } from "@sumit-platforms/ui-bazar/constants";

import "./AssignJobModal.scss";

export interface AssignJobModalProps {
  jobs: Job[];
  members?: JobCommunityMembers;
  afterSubmit?: (idJobs: number[]) => void;
  afterCancel?: () => void;
}

export const AssignJobModal: FC<AssignJobModalProps> = ({
  members,
  jobs,
  afterSubmit,
  afterCancel,
}) => {
  const { t } = useTranslation();
  const [relevantQcs, setRelevantQcs] = useState<AssignedUser[]>([]);
  const [relevantTranscribers, setRelevantTranscribers] = useState<
    AssignedUser[]
  >([]);
  const [previousQc] = useState<AssignedUser | undefined>(members?.qc?.user);
  const [previousTranscriber] = useState<AssignedUser | undefined>(
    members?.transcriber?.user
  );
  const idJobs: number[] = useMemo(
    () => jobs?.map((job) => job.idJob) || [],
    [jobs]
  );
  const singularJob: Job | null = useMemo(
    () => (jobs?.length === 1 ? jobs[0] : null),
    [jobs]
  );
  const [payPreviousTranscriber, setPayPreviousTranscriber] = useState(false);
  const [payPreviousQc, setPayPreviousQc] = useState(false);

  const [selectedTranscriberId, setSelectedTranscriberId] = useState<number[]>(
    []
  );
  const [selectedQcId, setSelectedQcId] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setAlert } = useAlert();

  //INITIATE SELECT INPUTS WITH CURRENT SELECTED USERS IF THEY ARE EXISTS
  useEffect(() => {
    if (
      previousQc &&
      relevantQcs?.find((qc) => qc.idUser === previousQc.idUser)
    ) {
      setSelectedQcId([previousQc?.idUser]);
    }

    if (
      previousTranscriber &&
      relevantTranscribers?.find(
        (transcriber) => transcriber.idUser === previousTranscriber.idUser
      )
    ) {
      setSelectedTranscriberId([previousTranscriber?.idUser]);
    }
  }, [relevantQcs, previousQc, relevantTranscribers, previousTranscriber]);

  const selectedQcUser = useMemo(() => {
    if (selectedQcId) {
      return relevantQcs?.find((qc) => qc.idUser === selectedQcId[0]);
    }
  }, [selectedQcId]);

  const selectedTranscriberUser = useMemo(() => {
    if (selectedTranscriberId) {
      return relevantQcs?.find((qc) => qc.idUser === selectedTranscriberId[0]);
    }
  }, [selectedTranscriberId]);

  const isTranscriberChangeAllowed = useMemo(() => {
    if (!previousTranscriber) return true;
    return isUnassignAllowed({
      jobStatus: singularJob?.status,
      task: AssignmentTask.transcriber,
      taskStatus: previousTranscriber.taskStatus,
    });
  }, [singularJob, previousTranscriber]);

  const isQcChangeAllowed = useMemo(() => {
    if (!previousQc) return true;
    return isUnassignAllowed({
      jobStatus: singularJob?.status,
      task: AssignmentTask.qc,
      taskStatus: previousQc.taskStatus,
    });
  }, [singularJob, previousQc]);

  const transcribeActions = useMemo(() => {
    if (previousTranscriber?.idUser !== selectedTranscriberUser?.idUser)
      return [];
    return (
      previousTranscriber && [
        {
          icon: faTimes,
          onClick: () => setSelectedTranscriberId([]),
          disabled: isLoading || !isTranscriberChangeAllowed,
        },
      ]
    );
  }, [
    isLoading,
    previousTranscriber,
    isTranscriberChangeAllowed,
    selectedTranscriberUser,
  ]);

  const qcActions = useMemo(() => {
    if (previousQc?.idUser !== selectedQcUser?.idUser) return [];
    return (
      previousQc && [
        {
          icon: faTimes,
          onClick: () => setSelectedQcId([]),
          disabled: isLoading || !isQcChangeAllowed,
        },
      ]
    );
  }, [isLoading, previousQc, isQcChangeAllowed, selectedQcUser]);

  useEffect(() => {
    const getCommunityMembersHandler = async () => {
      try {
        setIsLoading(true);
        const _relevantQcs =
          await assignmentService.getCommunityMembersToAssign(
            singularJob?.idJob ? [singularJob.idJob] : idJobs,
            AssignmentTask.qc
          );
        const _relevantTranscribers =
          await assignmentService.getCommunityMembersToAssign(
            singularJob?.idJob ? [singularJob.idJob] : idJobs,
            AssignmentTask.transcriber
          );
        setRelevantQcs(_relevantQcs);
        setRelevantTranscribers(_relevantTranscribers);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setAlert({
          severity: "error",
          message: t("failed_fetching_community_members"),
        });
      }
    };
    getCommunityMembersHandler();
  }, [jobs]);

  const isValid = useMemo(() => {
    return (
      previousTranscriber?.idUser !== selectedTranscriberUser?.idUser ||
      previousQc?.idUser !== selectedQcUser?.idUser
    );
  }, [
    previousTranscriber,
    selectedTranscriberUser,
    previousQc,
    selectedQcUser,
  ]);

  const isBulk = useMemo(() => {
    return jobs?.length > 1;
  }, [jobs]);

  //Checking that every job under selectedJobs does not have this assignedUser already assigned with the specific task.
  //The specific task is defined on the AssignedUser the function get as a property.
  const isUserValidForBulkAssign = useCallback(
    (assignedUser: AssignedUser) => {
      if (!isBulk || assignedUser.assignedJobs?.length === 0) return true;
      return jobs?.every((job) => {
        const activeAssignmentsOnUser = job?.assignments?.filter(
          (assignment) =>
            assignedUser.task === assignment.task &&
            assignment.idUser === assignedUser.idUser &&
            [
              AssignStatus.rejected,
              AssignStatus.removed,
              AssignStatus.resigned,
            ].includes(assignment.assignStatus)
        );
        return _.isUndefined(activeAssignmentsOnUser);
      });
    },
    [jobs, isBulk]
  );

  const assignJobToCommunityMember = async () => {
    if (previousTranscriber?.idUser !== selectedTranscriberUser?.idUser)
      await assignmentService.assignJobToCommunityMember({
        idJobs,
        task: AssignmentTask.transcriber,
        idUser: selectedTranscriberUser?.idUser,
        payPreviousCommunityMember: payPreviousTranscriber,
        previousCommunityMember: previousTranscriber,
      });

    if (previousQc?.idUser !== selectedQcUser?.idUser)
      await assignmentService.assignJobToCommunityMember({
        idJobs,
        task: AssignmentTask.qc,
        idUser: selectedQcUser?.idUser,
        payPreviousCommunityMember: payPreviousQc,
        previousCommunityMember: previousQc,
      });
  };

  const handleSubmit = async () => {
    try {
      if (!isValid) return;
      setIsLoading(true);
      await assignJobToCommunityMember();
      setIsLoading(false);
      setAlert({
        severity: "success",
        message: t("assign_success"),
      });
      if (afterSubmit) afterSubmit(idJobs);
    } catch (err) {
      setIsLoading(false);
      setAlert({
        severity: "error",
        message: t("assign_failed"),
      });
    }
  };

  const handleCancel = async () => {
    if (afterCancel) afterCancel();
  };

  const formatAssigedUserToOption = (u: AssignedUser) => {
    let iconElem: JSX.Element | null = null;
    if (
      u.status === UserStatus.NEW_MEMBER ||
      u.status === UserStatus.TRAINING
    ) {
      const { statusTitle, statusColor } = getUserStatusVisual(u.status);
      iconElem = (
        <Badge
          label={t(statusTitle as any)}
          style={{
            backgroundColor: statusColor,
          }}
        />
      );
    }
    if (u.assignMethod === AssignMethod.request) {
      iconElem = (
        <Badge
          label={t("requested_by")}
          style={{
            backgroundColor: archiveColor,
          }}
        />
      );
    }
    const isValidForBulk = isUserValidForBulkAssign(u);
    return {
      label: `${u.firstName} ${u.lastName}`,
      key: u.idUser,
      value: u.idUser,
      disabled: u.assignMethod === AssignMethod.request || !isValidForBulk,
      iconElem,
      toolTip:
        isBulk && !isValidForBulk ? t("user_already_assigned") : undefined,
    } as Option;
  };

  const formatAssignedUserToPlaceholder = (user?: AssignedUser) => {
    return user ? `${user.firstName} ${user.lastName}` : t("select_user");
  };

  return (
    <Grid className={"AssignJobModal"} container width={"30rem"}>
      <Grid container px={2} pt={2}>
        <Box width={"100%"}>
          <Box pb={3} pt={1} display={"flex"} justifyContent={"center"}>
            <h3 className="title">
              {t("assign_job_modal_title", {
                jobName: singularJob
                  ? singularJob.name
                  : `${idJobs.length} jobs`,
              })}
            </h3>
          </Box>
        </Box>
      </Grid>
      <Grid container px={4}>
        <Grid item xs={12} pb={2}>
          <Box pb={2}>{t("transcript_by") + ":"}</Box>
          <Box className={"usersSelect"} sx={{ position: "relative" }}>
            <MultiSelect
              actions={transcribeActions}
              disabled={
                _.isEmpty(relevantTranscribers) || !isTranscriberChangeAllowed
              }
              isMulti={false}
              placeholder={formatAssignedUserToPlaceholder(
                selectedTranscriberUser
              )}
              setSelected={setSelectedTranscriberId}
              selected={selectedTranscriberId}
              options={relevantTranscribers.map((u) =>
                formatAssigedUserToOption(u)
              )}
            />
          </Box>
          <Box className={"confirmAssignChange"}>
            {singularJob &&
              relevantTranscribers?.length > 0 &&
              previousTranscriber?.idUser !== selectedTranscriberUser?.idUser &&
              previousTranscriber &&
              previousTranscriber?.taskStatus !== TaskStatus.pending && (
                <ConfirmUnassign
                  job={singularJob}
                  newCommunityMember={selectedTranscriberUser}
                  previousCommunityMember={previousTranscriber}
                  setPayPreviousCommunityMember={setPayPreviousTranscriber}
                  payPreviousCommunityMember={payPreviousTranscriber}
                />
              )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box pb={2}>{t("quality_check_by") + ":"}</Box>
          <Box className={"usersSelect"} sx={{ position: "relative" }}>
            <MultiSelect
              actions={qcActions}
              disabled={_.isEmpty(relevantQcs) || !isQcChangeAllowed}
              isMulti={false}
              placeholder={formatAssignedUserToPlaceholder(selectedQcUser)}
              setSelected={setSelectedQcId}
              selected={selectedQcId}
              options={relevantQcs.map((u) => formatAssigedUserToOption(u))}
            />
          </Box>
          <Box className={"confirmAssignChange"}>
            {singularJob &&
              relevantQcs?.length > 0 &&
              previousQc?.idUser !== selectedQcUser?.idUser &&
              previousQc &&
              previousQc?.taskStatus !== TaskStatus.pending && (
                <ConfirmUnassign
                  job={singularJob}
                  newCommunityMember={selectedQcUser}
                  previousCommunityMember={previousQc}
                  setPayPreviousCommunityMember={setPayPreviousQc}
                  payPreviousCommunityMember={payPreviousQc}
                />
              )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pt={2}
        pb={2}
        gap={2}
      >
        <Button onClick={handleCancel} variant="outlined" color="primary">
          {t("cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!isValid}
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </Grid>
    </Grid>
  );
};
