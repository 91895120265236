import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { Box, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useEllipsisObserver } from "../../../hooks";

import "./TableCell.scss";

export interface TableCellToolTip {
  icon: JSX.Element;
  content: JSX.Element;
  color: string;
}
interface TableCellProps {
  primaryText?: string | null;
  primarySmallText?: string | null;
  primaryLink?: string | null;
  secondaryText?: string | null;
  secondarySmallText?: string | null;
  icon?: string;
  boldTitle?: boolean;
  toolTip?: TableCellToolTip;
}

export const TableCell: FC<TableCellProps> = ({
  primaryText = "",
  primarySmallText,
  primaryLink,
  secondaryText = "",
  secondarySmallText,
  icon,
  boldTitle = false,
  toolTip,
}) => {
  const primaryTextRef = useRef<HTMLDivElement>(null);
  const { isEllipsised } = useEllipsisObserver(primaryTextRef);

  return (
    <Box className={"TableCell ellipsis"}>
      {icon && (
        <Box className={"briefImageWrapper"}>
          <img alt="icon" src={icon}></img>
        </Box>
      )}
      <Tooltip
        title={
          isEllipsised ? (
            <span>
              {primaryText || ""} {primarySmallText || ""} <br />
              {secondaryText || ""} {secondarySmallText || ""}
            </span>
          ) : null
        }
      >
        <Box className={"briefTextWrapper"}>
          <Box display={"flex"} gap={1} alignItems={"flex-start"}>
            <Box ref={primaryTextRef} className={"ellipsis"}>
              {primaryLink ? (
                <Link
                  className={"primaryText primaryLinkText"}
                  to={primaryLink}
                >
                  {primaryText}
                </Link>
              ) : (
                <Typography
                  fontWeight={boldTitle ? 600 : 400}
                  className="primaryText"
                >
                  {primaryText}
                  {primarySmallText && (
                    <span className="primarySmallText">{primarySmallText}</span>
                  )}
                </Typography>
              )}
              {(secondaryText || primarySmallText) && (
                <>
                  <br />
                  <Typography className="secondaryText">
                    {secondaryText}
                    {primarySmallText && (
                      <span className="secondarySmallText">
                        {secondarySmallText}
                      </span>
                    )}
                  </Typography>
                </>
              )}
            </Box>
            {toolTip && (
              <Box
                display={"display"}
                justifyContent={"center"}
                alignContent={"center"}
                mt={0.3}
              >
                <Tooltip placement="bottom-start" title={toolTip.content}>
                  <span style={{ color: toolTip.color }}>{toolTip.icon}</span>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
};
