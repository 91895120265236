import { TFunction } from "i18next";
import { faAlignJustify } from "@fortawesome/pro-light-svg-icons";
import { Align, TableOfSubjectsPage } from "@sumit-platforms/types";
import {
  alignOptions,
  decorationOptions,
  fontSizeOptions,
  getListTypeOptions,
} from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getTableOfSubjectsPageInputs: (
  args: TableOfSubjectsPage,
  t: TFunction
) => PageSettings<{
  tableOfSubjects: TableOfSubjectsPage;
}> = (args, t) => ({
  settings: { tableOfSubjects: args },
  pageTab: t("settings_protocol_table_of_subjects"),
  sections: [
    {
      title: t("settings_protocol_table_of_subjects"),
      paragraph: t("settings_protocol_table_of_subjects_paragraph_1"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "tableOfSubjects.tableOfSubjects.isActive",
        },
      ],
    },
    {
      disableKey: "tableOfSubjects.tableOfSubjects.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_table_of_subjects_new_page")}:`,
          type: "switch",
          key: "tableOfSubjects.newPage",
        },
      ],
    },
    {
      disableKey: "tableOfSubjects.tableOfSubjects.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_table_of_subjects_sign")}:`,
          type: "toggle",
          buttons: getListTypeOptions(t),
          exclusive: true,
          key: "tableOfSubjects.sign",
          isColumn: true,
        },
      ],
    },
    {
      disableKey: "tableOfSubjects.tableOfSubjects.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_table_of_subjects_font")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "tableOfSubjects.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "tableOfSubjects.font.textDecoration",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: [
            ...alignOptions,
            {
              value: Align.justify,
              label: faAlignJustify,
            },
          ],
          key: "tableOfSubjects.font.align",
        },
        {
          type: "colorPicker",
          key: "tableOfSubjects.font.color",
        },
      ],
    },
  ],
});
