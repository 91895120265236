import _ from "lodash";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AssignmentRequest,
  AssignmentTask,
  JobStatus,
} from "@sumit-platforms/types";
import { useAlert } from "./../../store/alert";
import { Button } from "../../core/Button/Button";
import { DATE_FORMAT_1 } from "../../constants/globals";
import { secondsToTimeString } from "../../utils/formatters";
import { grayColor3, jobRequestTitleColor } from "../../bazar-theme";
import { SelectSimple } from "../../core/SelectSimple/SelectSimple";

interface JobRequestProps {
  assignmentRequest: AssignmentRequest;
  onJobRequestChange: (
    action: "accept" | "reject",
    idUsersJobs: number
  ) => void;
  afterJobRequestChange: (idJob: number) => void;
}

export const JobRequest: FC<JobRequestProps> = ({
  assignmentRequest,
  onJobRequestChange,
  afterJobRequestChange,
}) => {
  const { t } = useTranslation();
  const [selectedRequestId, setSelectedRequestId] = useState<string | number>(
    ""
  );
  const { setAlert } = useAlert();
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState<boolean>(false);

  const selectedRequest = useMemo(() => {
    return assignmentRequest.requests.find(
      (r) => r.assignment.idUsersJobs === selectedRequestId
    );
  }, [selectedRequestId]);

  const isValid = useMemo(() => {
    if (!selectedRequest) return false;
    const { task } = selectedRequest.assignment;
    const { status: jobStatus } = assignmentRequest.job;
    return (
      (task === AssignmentTask.qc && jobStatus === JobStatus.pending_review) ||
      (task === AssignmentTask.transcriber && jobStatus === JobStatus.ready)
    );
  }, [selectedRequest]);

  useEffect(() => {
    if (assignmentRequest.requests[0].assignment.idUsersJobs) {
      setSelectedRequestId(
        assignmentRequest.requests[0].assignment.idUsersJobs
      );
    }
  }, [assignmentRequest.requests]);

  const handleAcccept = async () => {
    try {
      if (!isValid) return;
      setIsAcceptLoading(true);
      await onJobRequestChange("accept", selectedRequestId as number);
      setIsAcceptLoading(false);
      if (afterJobRequestChange)
        await afterJobRequestChange(assignmentRequest.job.idJob);
    } catch (err) {
      setIsAcceptLoading(false);
      setAlert({
        severity: "error",
        message: t("reject_job_success"),
      });
    }
  };

  const handleReject = async () => {
    try {
      if (!isValid) return;
      setIsRejectLoading(true);
      await onJobRequestChange("reject", selectedRequestId as number);
      setIsRejectLoading(false);
      if (afterJobRequestChange)
        await afterJobRequestChange(assignmentRequest.job.idJob);
    } catch (err) {
      setIsRejectLoading(false);
      setAlert({
        severity: "error",
        message: t("reject_job_fail"),
      });
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Card
          sx={{
            width: "20rem",
            minHeight: "16rem",
            borderRadius: 3,
            boxShadow: "0px 0px 20px lightgray",
          }}
        >
          <CardContent sx={{ paddingX: 1 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                mb={2}
                sx={{ position: "relative" }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    left: "-5px",
                    fontSize: 10,
                    color: grayColor3,
                  }}
                >
                  #{assignmentRequest.job.idJob}
                </span>
                <Tooltip title={assignmentRequest.job.name} placement={"top"}>
                  <Typography
                    my={1}
                    sx={{
                      fontSize: 12,
                      background: jobRequestTitleColor,
                      padding: ".4rem .8rem",
                      borderRadius: 1,
                      maxWidth: "100%",
                    }}
                    color="text.secondary"
                    className={"ellipsis"}
                    gutterBottom
                  >
                    {assignmentRequest.job.name}
                  </Typography>
                </Tooltip>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >{`${t("c")} - ${format(
                  new Date(assignmentRequest.job.delivery),
                  DATE_FORMAT_1
                )}`}</Typography>
              </Grid>
              <Grid container paddingX={2}>
                <Grid item xs={12} md={6} my={1}>
                  <Typography sx={{ color: grayColor3 }}>
                    {t("type")}:
                  </Typography>
                  <Typography>{`${assignmentRequest?.job?.type.typeName} (${[
                    assignmentRequest.job.inputLanguage[0],
                    assignmentRequest.job.outputLanguage[0],
                  ]
                    .filter((l) => !_.isUndefined(l))
                    .join(" - ")})`}</Typography>
                </Grid>
                <Grid item xs={12} md={6} my={1}>
                  <Typography sx={{ color: grayColor3 }}>
                    {" "}
                    {t("duration")}:
                  </Typography>
                  <Typography>
                    {secondsToTimeString(assignmentRequest?.job.duration || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} my={1}>
                  <Typography sx={{ color: grayColor3 }}>
                    {t("client")}:
                  </Typography>
                  <Typography>
                    {assignmentRequest?.job?.client?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} my={1}>
                  <Typography sx={{ color: grayColor3 }}>
                    {t("income")}:
                  </Typography>
                  <Typography>0</Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card
          sx={{
            width: "20rem",
            borderRadius: 3,
            boxShadow: "0px 0px 20px lightgray",
          }}
        >
          <CardContent>
            <CardActions>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid
                  item
                  xs={12}
                  pb={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  {assignmentRequest.requests && (
                    <SelectSimple
                      value={selectedRequestId}
                      options={assignmentRequest.requests?.map((r) => ({
                        label: `
                          ${r.requestedBy.firstName}
                          ${r.requestedBy.lastName}
                           -
                          ${r.assignment.task}`,
                        value: r.assignment.idUsersJobs,
                      }))}
                      onChange={(e) => {
                        setSelectedRequestId(e.target.value as string);
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <Button
                    loading={isRejectLoading}
                    variant={"outlined"}
                    onClick={handleReject}
                    disabled={!isValid || isAcceptLoading}
                  >
                    {t("reject")}
                  </Button>
                  <Button
                    loading={isAcceptLoading}
                    variant={"contained"}
                    onClick={handleAcccept}
                    disabled={!isValid || isRejectLoading}
                  >
                    {t("accept")}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
