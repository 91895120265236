import _ from "lodash";
import { AR, EN, HE } from "@sumit-platforms/common-assets/i18n";
import * as EN_delivery from "./delivery-EN.json";
import * as HE_delivery from "./delivery-HE.json";
import * as AR_delivery from "./delivery-AR.json";

const resources = {
  en: {
    translation: _.merge(EN, EN_delivery),
  },
  he: {
    translation: _.merge(HE, HE_delivery),
  },
  ar: {
    translation: _.merge(AR, AR_delivery),
  },
};
export default resources;
