import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { SettingsPager } from "../../core/SettingsPager/SettingsPager";
import { cloudinaryService } from "../../services/cloudinaryService";
import { useTranslation } from "react-i18next";
import { Button } from "../../core/Button/Button";

import "./SettingsModal.scss";

export interface SettingsModalProps {
  useSettings: any;
  defaultSettings: any;
  onDone: (settings: any) => void;
  onCancel?: () => void;
  showActions?: boolean;
  config?: any;
  steps?: { options: string[] }[];
  metadata?: any;
}

export const SettingsModal = ({
  useSettings,
  defaultSettings,
  onDone,
  onCancel,
  showActions = true,
  config,
  steps,
  metadata,
}: SettingsModalProps) => {
  const { t } = useTranslation();

  const { pages, setPages, resetPages } = useSettings({
    settings: defaultSettings,
    metadata,
    actions: {
      onConfirm: () => onDone(outputSettings.current),
      onCancel: () => {
        resetPages();
        onCancel && onCancel();
      },
    },
  });

  const outputSettings = useRef<any>();
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    let combinedSettings: any = {};
    pages.map((p: any) => {
      if (p.path) {
        _.set(combinedSettings, p.path, _.get(p.settings, p.path));
      } else {
        combinedSettings = { ...combinedSettings, ...p.settings };
      }
    });
    outputSettings.current = combinedSettings;
  }, [pages]);

  return (
    <Grid className={"SettingsModal"} container justifyContent={"center"}>
      <SettingsPager
        pages={pages}
        setPages={setPages}
        imageUploadHandler={
          config ? cloudinaryService(config).upload : undefined
        }
        setIsValid={setIsValid}
        steps={steps}
      />
      {showActions && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
          padding={2}
          gap={2}
        >
          <Button
            disabled={!isValid}
            onClick={() => {
              isValid && onDone(outputSettings.current);
            }}
            variant="contained"
          >
            {t("done")}
          </Button>
          <Button onClick={resetPages} variant="outlined">
            {t("reset")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
