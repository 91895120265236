import _ from "lodash";
import { t } from "i18next";
import React, { createRef, useEffect, useMemo, useState } from "react";

import { Box, Button, ThemeProvider } from "@mui/material";

import {
  ExtractQuery,
  ExtractSegmentField,
  FieldOperator,
  TextualIndexType,
  ExtractField,
  IndexType,
  Portrait,
} from "@sumit-platforms/types";

import { useKeyPress } from "../../hooks/useKeyPress";
import { bazarTheme } from "../../bazar-theme";
import { ExtractFieldRow } from "../ExtractFieldRow/ExtractFieldRow";

import "./ExtractQueryBuilder.scss";

export const defaultField: ExtractSegmentField = {
  type: "exact_text",
  operator: FieldOperator.IS,
  textualIndexType: TextualIndexType.PARAGRAPH,
};

interface ExtractQueryBuilderProps {
  speakers: string[];
  tags: string[];
  portraits: Portrait[];
  onSearch: (extractQuery: ExtractQuery) => void;
  inputFields: ExtractField[];
  isLoading?: boolean;
}

const DEFAULT_EXTRACT_QUERY = [[defaultField]];

export const ExtractQueryBuilder = ({
  speakers,
  tags,
  portraits,
  onSearch,
  inputFields,
  isLoading,
}: ExtractQueryBuilderProps) => {
  const [extractQuery, setExtractQuery] = useState<ExtractQuery>(
    DEFAULT_EXTRACT_QUERY
  );

  const queryInputRefs = useMemo(
    () => extractQuery.map((segment) => segment.map((_field) => createRef())),
    [extractQuery]
  );

  const enterPressed = useKeyPress("Enter", _.flatten(queryInputRefs));

  useEffect(() => {
    if (enterPressed) handleOnSearch();
  }, [enterPressed]);

  const numOfEmptyFields = useMemo<number>(
    () =>
      _.sum(
        extractQuery.map(
          (segment) => segment.filter((field) => _.isEmpty(field.value)).length
        )
      ),
    [extractQuery]
  );

  const isSubmitDisabled = useMemo(() => {
    return (
      isLoading ||
      _.isEmpty(extractQuery) ||
      _.isEmpty(extractQuery[0]) ||
      numOfEmptyFields > 0
    );
  }, [isLoading, extractQuery, numOfEmptyFields]);

  const getIndexSearchData = (extractQuery: ExtractQuery): ExtractQuery => {
    return extractQuery.map((segment) =>
      segment.map((segmentField) => {
        const extractField = inputFields.find(
          //Input label is the name of the type which is the search function in Algo
          (f) => segmentField.type === f.inputLabel
        );
        if (extractField) {
          segmentField.indexSearchData = {
            indexToSearchIn: extractField.indexToSearchIn,
            indexSearchField: extractField.indexSearchField,
            indexSearchParams: extractField.indexSearchParams,
            indexSearchType: extractField.indexSearchType,
          };
          if (segmentField.textualIndexType === TextualIndexType.PARAGRAPH) {
            segmentField.indexSearchData.indexSearchField = [
              TextualIndexType.PARAGRAPH,
            ];
            segmentField.indexSearchData.indexToSearchIn = IndexType.PARAGRAPH;
          }
        }
        return {
          ...segmentField,
          //If input type is select(not multi-select) we will send the first value in the array
          value:
            extractField?.inputType === "select" &&
            _.isArray(segmentField.value) &&
            !_.isEmpty(segmentField.value)
              ? segmentField.value[0]
              : segmentField.value,
        };
      })
    );
  };

  function handleOnSearch() {
    if (isSubmitDisabled) return;
    onSearch(getIndexSearchData(extractQuery));
  }

  const handleFieldChange = ({
    segmentIndex,
    fieldIndex,
    field,
  }: {
    segmentIndex: number;
    fieldIndex: number;
    field: ExtractSegmentField;
  }) => {
    setExtractQuery((prevExtractQuery) => {
      const newExtractQuery = prevExtractQuery.map((p) => [...p]);
      newExtractQuery[segmentIndex][fieldIndex] = field;
      return newExtractQuery;
    });
  };

  const handleFieldAdd = ({ segmentIndex }: { segmentIndex?: number }) => {
    setExtractQuery((prevExtractQuery) => {
      const newExtractQuery = prevExtractQuery.map((p) => [...p]);
      if (!_.isUndefined(segmentIndex)) {
        newExtractQuery[segmentIndex].push(defaultField);
      } else {
        newExtractQuery.push([defaultField]);
      }
      return newExtractQuery;
    });
  };

  const handleFieldDelete = ({
    segmentIndex,
    fieldIndex,
  }: {
    segmentIndex: number;
    fieldIndex: number;
  }) => {
    setExtractQuery((prevExtractQuery) => {
      const newExtractQuery = prevExtractQuery.map((p) => [...p]);
      newExtractQuery[segmentIndex].splice(fieldIndex, 1);
      if (
        newExtractQuery.length > 1 &&
        _.isEmpty(newExtractQuery[segmentIndex])
      ) {
        newExtractQuery.splice(segmentIndex, 1);
      }
      return newExtractQuery;
    });
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box pb={2} className={"ExtractQueryBuilder"}>
        {extractQuery.map((segment, segmentIndex) => (
          <Box key={`segment-${segmentIndex}`}>
            <Box className="segmentLayerWrapper">
              <>
                {segment.map((field, fieldIndex) => (
                  <Box key={`field-${fieldIndex}`}>
                    <Box className={"fieldLayerWrapper"}>
                      <ExtractFieldRow
                        inputFields={inputFields}
                        disabled={isLoading}
                        tags={tags}
                        speakers={speakers}
                        portraits={portraits}
                        field={field}
                        onFieldChange={(field) =>
                          handleFieldChange({
                            segmentIndex,
                            fieldIndex,
                            field,
                          })
                        }
                        deleteField={() =>
                          handleFieldDelete({ segmentIndex, fieldIndex })
                        }
                        inputRef={
                          queryInputRefs
                            ? queryInputRefs[segmentIndex][fieldIndex]
                            : undefined
                        }
                      />
                    </Box>
                    {fieldIndex < segment.length - 1 && (
                      <Box className="textBetweenFields">{t("query_and")}</Box>
                    )}
                  </Box>
                ))}
                <Button
                  disabled={isLoading}
                  onClick={() => handleFieldAdd({ segmentIndex })}
                  className="textBetweenFields"
                >
                  {t("query_add_and")}
                </Button>
              </>
            </Box>
            {segmentIndex < extractQuery.length - 1 ? (
              <Box className="textBetweenFields">{t("query_or")}</Box>
            ) : (
              !_.isEmpty(extractQuery[0]) && (
                <Button
                  disabled={isLoading}
                  className="textBetweenFields"
                  onClick={() => handleFieldAdd({ segmentIndex: undefined })}
                >
                  {t("query_add_or")}
                </Button>
              )
            )}
          </Box>
        ))}

        <Button
          disabled={isSubmitDisabled}
          variant={"contained"}
          onClick={handleOnSearch}
        >
          {t("search")}
        </Button>
      </Box>
    </ThemeProvider>
  );
};
