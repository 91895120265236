import _ from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SignUpForm } from "@sumit-platforms/ui-bazar";
import userInvitationService from "../../services/userInvitationService";
import authService from "../../services/authService";

import "./Auth.scss";

const SignUpPage: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const invitationToken = useMemo(() => {
    return searchParams.get("token");
  }, [searchParams]);

  const [invitationDetails, setInvitationDetails] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInvitationData = async () => {
      if (invitationToken) {
        setLoading(true);
        const _invitationDetails =
          await userInvitationService.getInvitationByToken(invitationToken);
        setLoading(false);
        setInvitationDetails(_invitationDetails);
      }
    };
    getInvitationData();
  }, [invitationToken]);

  return (
    <div className="SignUpPage">
      {invitationDetails ? (
        <SignUpForm
          authService={authService}
          invitationDetails={invitationDetails}
        />
      ) : (
        <div>{loading ? "" : t("invitation_link_is_not_valid")}</div>
      )}
    </div>
  );
};

export default SignUpPage;
