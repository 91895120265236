import React from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import { DefaultTFuncReturn } from "i18next";
import { primaryColor, whiteColor } from "../../bazar-theme";

interface Tooltip {
  children: React.ReactElement<any, any>;
  title: string | JSX.Element | DefaultTFuncReturn;
  placement?: TooltipProps["placement"];
  style?: React.CSSProperties;
}

export const Tooltip = ({ children, title, placement, style }: Tooltip) => {
  const tooltipContent =
    typeof title === "string" ? (
      <div style={{ whiteSpace: "pre-line", fontSize: "0.75rem" }}>{title}</div>
    ) : (
      title
    );

  return (
    <MuiTooltip
      className={"tooltip"}
      title={tooltipContent}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: primaryColor,
            color: whiteColor,
            ...style,
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};
