import { TFunction } from "i18next";
import { InformationPage } from "@sumit-platforms/types";
import {
  alignOptions,
  decorationOptions,
  fontSizeOptions,
  getCalendarOptions,
  getFormatOptions,
} from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getInformationPageInputs: (
  args: InformationPage,
  t: TFunction
) => PageSettings<{ information: InformationPage }> = (args, t) => ({
  settings: { information: args },
  pageTab: t("settings_protocol_information"),
  sections: [
    {
      title: t("settings_protocol_information"),
      paragraph: t("settings_protocol_information_paragraph_1"),
    },
    {
      inputs: [
        {
          label: `${t("settings_protocol_information_job_name")}:`,
          type: "switch",
          key: "information.jobName.isActive",
        },
      ],
    },
    {
      disableKey: "information.jobName.isActive",
      inputs: [
        {
          type: "select",
          options: fontSizeOptions,
          key: "information.jobName.fontSize",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "information.jobName.align",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "information.jobName.textDecoration",
        },
        {
          type: "colorPicker",
          key: "information.jobName.color",
        },
      ],
    },
    {
      inputs: [
        {
          label: `${t("settings_protocol_information_job_date")}:`,
          type: "switch",
          key: "information.jobDate.isActive",
        },
      ],
    },
    {
      disableKey: "information.jobDate.isActive",
      inputs: [
        {
          type: "toggle",
          exclusive: false,
          buttons: getCalendarOptions(t),
          key: "information.jobDate.calendars",
        },
      ],
    },
    {
      disableKey: "information.jobDate.isActive",
      inputs: [
        {
          type: "select",
          options: fontSizeOptions,
          key: "information.jobDate.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "information.jobDate.textDecoration",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "information.jobDate.align",
        },
        {
          type: "colorPicker",
          key: "information.jobDate.color",
        },
      ],
    },
    {
      title: t("settings_protocol_information_attendees"),
      paragraph: t("settings_protocol_information_paragraph_2"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "information.attendees.isActive",
        },
      ],
    },
    {
      disableKey: "information.attendees.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_information_format")}:`,
          type: "toggle",
          isColumn: true,
          exclusive: true,
          buttons: getFormatOptions(t),
          key: "information.format",
        },
      ],
    },
    {
      disableKey: "information.attendees.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_information_show_title")}:`,
          type: "switch",
          key: "information.showTitle",
        },
      ],
    },
    {
      disableKey: "information.attendees.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_information_show_role_type")}:`,
          type: "switch",
          key: "information.showRoleType",
        },
      ],
    },
  ],
});
