import { TFunction } from "i18next";
import { SpeakersPage } from "@sumit-platforms/types";
import { decorationOptions, fontSizeOptions } from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getSpeakersPageInputs: (
  args: SpeakersPage,
  t: TFunction
) => PageSettings<{ speakers: SpeakersPage }> = (args, t) => ({
  settings: { speakers: args },
  pageTab: t("settings_protocol_speakers"),
  sections: [
    {
      title: t("settings_protocol_speakers"),
      paragraph: t("settings_protocol_speakers_paragraph_1"),
    },
    {
      inputs: [
        {
          label: t("settings_protocol_speakers_show_title"),
          type: "switch",
          key: "speakers.showTitle",
        },
      ],
    },
    {
      inputs: [
        {
          label: t("settings_protocol_speakers_timing"),
          type: "switch",
          key: "speakers.timing",
        },
      ],
    },
    {
      inputs: [
        {
          label: t("settings_protocol_speakers_new_line"),
          type: "switch",
          key: "speakers.newLine",
        },
      ],
    },
    {
      inputs: [
        {
          label: t("settings_protocol_speakers_indentation"),
          type: "switch",
          key: "speakers.indentation",
        },
      ],
    },
    {
      inputs: [
        {
          label: t("settings_interviewer_in_bold"),
          type: "switch",
          key: "speakers.interviewerInBold",
        },
      ],
    },
    {
      inputs: [
        {
          label: `${t("settings_protocol_speakers_font")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "speakers.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "speakers.font.textDecoration",
        },
        {
          type: "colorPicker",
          key: "speakers.font.color",
        },
      ],
    },
  ],
});
