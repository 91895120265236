import _ from "lodash";
import {
  AssignMethod,
  AssignedUser,
  AssignmentTask,
  Job,
  JobCommunityMembers,
  TaskStatus,
  UserRole,
} from "@sumit-platforms/types";

const getLatestAsssignedUserOrWorkingUser = (assignedUsers: AssignedUser[]) => {
  return (
    assignedUsers.find((a) => a.taskStatus === TaskStatus.work) ||
    _.orderBy(assignedUsers, ["assignCreatedAt"], ["desc"])[0]
  );
};

export const getMethodStringByAssignedUser = (user: AssignedUser) => {
  if ([TaskStatus.work, TaskStatus.done].includes(user.taskStatus)) return "";
  switch (user.assignMethod) {
    case AssignMethod.assign:
      return "assigned_to";

    case AssignMethod.request:
      return "requested_by";

    case AssignMethod.retake:
      return "retaked_by";

    default:
      return "";
  }
};

export const extractFileNameWithoutExtension = (fileName: string) => {
  const parts = fileName.split(".");
  const fileNameWithoutExtension = parts.slice(0, -1).join(".");
  return fileNameWithoutExtension;
};
export const extractFileExtension = (fileName: string) =>
  _.last(fileName?.split(".") || "");

export const getJobCommunityMembers: (
  job?: Job
) => JobCommunityMembers | undefined = (job: Job) => {
  if (!job.assignedTo || job.assignedTo.length === 0 || !job) return;

  const transcribers = job?.assignedTo?.filter(
    (u) => u.task === AssignmentTask.transcriber
  );

  const qcs = job?.assignedTo?.filter((u) => u.task === AssignmentTask.qc);

  if (transcribers.length === 0 && qcs.length === 0) return;

  const transcriber = getLatestAsssignedUserOrWorkingUser(transcribers);

  const qc = getLatestAsssignedUserOrWorkingUser(qcs);

  return {
    transcriber: transcriber
      ? {
          user: transcriber,
          method: getMethodStringByAssignedUser(transcriber), //Add the method string is too long getMethodStringByAssignedUser(transcriber)
        }
      : undefined,
    qc: qc
      ? {
          user: qc,
          method: getMethodStringByAssignedUser(qc), //Add the method string is too long getMethodStringByAssignedUser(qc)
        }
      : undefined,
  };
};

export const getUserRolesString = (role: UserRole): string => {
  switch (role) {
    case UserRole.CLIENT_ADMIN: {
      return "client_admin";
    }
    case UserRole.CLIENT_USER: {
      return "client_user";
    }
    case UserRole.COMMUNITY_MEMBER: {
      return "community_member";
    }
    case UserRole.CS_MANAGER: {
      return "cs_manager";
    }
    default: {
      return "";
    }
  }
};
