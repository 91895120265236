import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Shortcut } from "@sumit-platforms/types";
import { useTranslation } from "react-i18next";
import { useDetectDevice } from "../../../hooks";
import classNames from "classnames";
import { useKeyPressRecorder } from "../../hooks/useKeypressRecorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faLock,
  faUnlock,
} from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "../Tooltip/Tooltip";
import _ from "lodash";
import { macosShortcuts, windowsShortcuts } from "../../../constants";

export const KeyboardShortcutItem = ({
  shortcut,
  updateLocalUpdates,
  userShortcuts,
}: {
  shortcut: Shortcut;
  updateLocalUpdates: (shortcut: Shortcut) => void;
  userShortcuts: Shortcut[];
}) => {
  const { t } = useTranslation();
  const { isMacOS } = useDetectDevice();
  const [listenToKeyStroke, setListenToKeyStroke] = useState(false);
  const [conflict, setConflict] = useState<"user" | "os" | null>(null);
  const { allKeysReleased, keysPressed } = useKeyPressRecorder({
    listen: listenToKeyStroke,
  });
  const [updatedShortcut, setUpdatedShortcut] = useState(_.cloneDeep(shortcut));

  const otherUserShortcuts = useMemo(
    () => userShortcuts.filter((s) => s.action !== shortcut.action),
    [userShortcuts]
  );
  const updatedUserShortcuts = useMemo(
    () => [...otherUserShortcuts, updatedShortcut],
    [otherUserShortcuts, userShortcuts, updatedShortcut]
  );

  const replaceModifiers = (keys: string[]) => {
    return keys.map((key) => {
      if (key.includes("$mod")) {
        return key.replace("$mod", isMacOS ? "Command" : "Control");
      } else if (key.includes("Meta")) {
        return key.replace("Meta", isMacOS ? "Command" : "Windows");
      }
      return key;
    });
  };

  useEffect(() => {
    const osShortcuts = isMacOS ? macosShortcuts : windowsShortcuts;
    const userConflict = otherUserShortcuts.some((otherShortcut) => {
      if (
        otherShortcut.editorType !== updatedShortcut.editorType &&
        otherShortcut.editorType !== "global" &&
        !_.isNil(otherShortcut.editorType)
      )
        return false;

      const conflict =
        _.intersection(
          replaceModifiers(otherShortcut.keys),
          replaceModifiers(updatedShortcut.keys)
        ).length > 0;
      return conflict;
    });

    const osConflict = updatedUserShortcuts.some((us) => {
      const keys = replaceModifiers(us.keys).map((k) => k.toLowerCase());
      const conflict = _.intersection(keys, osShortcuts).length > 0;
      return conflict;
    });

    if (osConflict) {
      setConflict("os");
    }
    if (userConflict) {
      setConflict("user");
    }
    if (!userConflict && !osConflict) {
      setConflict(null);
    }
  }, [updatedShortcut]);

  const prettifyKey = useCallback(
    (keys: string[]) => {
      return keys
        .map((combo) => {
          return combo
            .split("+")
            .map((key) => {
              if (key === "$mod") {
                return isMacOS ? "Command" : "Control";
              }
              if (key === "Meta") {
                return isMacOS ? "Command" : "Windows";
              }
              if (key === "Alt") {
                return isMacOS ? "Option" : "Alt";
              }
              return key;
            })
            .map((key) => {
              if (key === "Command") {
                key = "Cmd";
              }
              if (key === "Windows") {
                key = "Win";
              }
              if (key === "Option") {
                key = "Opt";
              }
              if (key === "Control") {
                key = "Ctrl";
              }
              return key;
            })
            .join("+");
        })
        .join(", ");
    },
    [isMacOS]
  );

  const toggleListenToUserKeystroke = useCallback(() => {
    if (updatedShortcut.disabled || updatedShortcut.editable === false) return;

    setListenToKeyStroke(!listenToKeyStroke);
  }, [listenToKeyStroke, updatedShortcut]);

  const toggleDisable = useCallback(() => {
    setUpdatedShortcut({
      ...updatedShortcut,
      disabled: !updatedShortcut.disabled,
    });
  }, [updatedShortcut]);

  useEffect(() => {
    if (allKeysReleased) {
      setUpdatedShortcut({
        ...updatedShortcut,
        action: shortcut.action,
        keys: [keysPressed],
      });
      setListenToKeyStroke(false);
    }
  }, [allKeysReleased]);

  useEffect(() => {
    if (
      updatedShortcut.keys[0] !== shortcut.keys[0] ||
      updatedShortcut.disabled !== shortcut.disabled
    ) {
      updateLocalUpdates(updatedShortcut);
    }
  }, [updatedShortcut]);

  return (
    <Box className={"shortcutActionItem"}>
      <p
        className={classNames("actionTitle", {
          disabled: updatedShortcut.disabled === true,
        })}
      >
        {t(shortcut.action.toLowerCase())}
      </p>
      <Box
        className={classNames("keysCombinationWrapper", {
          warningBorder: conflict === "os",
          errorBorder: conflict === "user",
          disabled:
            updatedShortcut.editable === false ||
            updatedShortcut.disabled === true,
        })}
        onClick={toggleListenToUserKeystroke}
      >
        <Tooltip
          title={t(
            conflict === "user"
              ? "user_shortcut_conflict"
              : "os_shortcut_conflict"
          )}
        >
          <span
            className={classNames("conflict", {
              user: conflict === "user",
              os: conflict === "os",
              hide: !conflict,
            })}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
        </Tooltip>
        <div
          className={classNames("keysCombinationInput", {
            grayPlaceholder: listenToKeyStroke,
          })}
        >
          {listenToKeyStroke
            ? t("set_keys")
            : prettifyKey(updatedShortcut.keys)}
        </div>
      </Box>
      <Tooltip
        title={
          updatedShortcut.disabled
            ? t("enable_shortcut")
            : t("disable_shortcut")
        }
      >
        <span
          className={classNames("lock", {
            hide: updatedShortcut.editable === false,
          })}
          onClick={toggleDisable}
        >
          <FontAwesomeIcon
            icon={updatedShortcut.disabled ? faUnlock : faLock}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
