enum JobStatus {
  pending = "PENDING", // processing
  stt = "STT", // processing
  ready = "READY", // in work
  transcribe = "TRANSCRIBE", // in work
  pending_review = "PENDING_REVIEW", // in work
  review = "REVIEW", // under review

  kit_recording = "KIT_RECORDING",

  aligning = "ALIGNING",
  splitting = "SPLITTING",

  pending_source_split = "PENDING_SOURCE_SPLIT", // job is pending to source job to be splitted
  pending_source_transcription = "PENDING_SOURCE_TRANSCRIPTION", // job is pending to source job to be manually transcript after auto stt rejection
  pending_splits_transcription = "PENDING_SPLITS_TRANSCRIPTION", // job is pending to child splits to finish

  done = "DONE",
  archive = "ARCHIVE",

  stt_fail = "STT_FAIL",
  kit_fail = "KIT_FAIL",

  create_wav_fail = "CREATE_WAV_FAIL",
  import_source_fail = "IMPORT_SOURCE_FAIL",

  inactive = "INACTIVE",
  deleted = "DELETED",
}

enum UploadStatus {
  success = "SUCCESS",
  attaching_files = "ATTACHING_FILES",
  pending = "PENDING",
  uploading = "UPLOADING",
  error = "ERROR",
  merging = "MERGING",
  mergeSuccess = "MERGE_SUCCESS",
  mergeFail = "MERGE_FAIL",
  convert = "CONVERT",
  convertFail = "CONVERT_FAIL",
}

enum FileStatus {
  resize = "RESIZE",
}

enum JobTranslationType {
  SOURCE_HIDDEN = "SOURCE_HIDDEN",
  SOURCE_FOR_SEED = "SOURCE_FOR_SEED",
  SOURCE_REQUESTED = "SOURCE_REQUESTED",
  TARGET = "TARGET",
}

type StatusPercents = { status: JobStatus; percents: number; duration: number };

const ResolvedJobStatuses = [JobStatus.done, JobStatus.archive];

export {
  JobStatus,
  UploadStatus,
  FileStatus,
  StatusPercents,
  JobTranslationType,
  ResolvedJobStatuses,
};
