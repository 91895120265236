import communityPaymentService from "../services/communityPaymentsService";
import { useState } from "react";
import {
  CommunityPaymentResponseData,
  CommunityPaymentResponseMetaData,
} from "@sumit-platforms/types";

export const usePayments = () => {
  const [communityPayments, setCommunityPayments] =
    useState<CommunityPaymentResponseData>([]);
  const [metaData, setMetaData] = useState<CommunityPaymentResponseMetaData>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);

  const getUserCommunityPayments = async (idUser?: number) => {
    setIsLoading(true);
    const userPayments = idUser
      ? await communityPaymentService.getCommunityPaymentsByUser(idUser)
      : await communityPaymentService.getUserPayments();
    setCommunityPayments(userPayments.data);
    setMetaData(userPayments.metaData);
    setIsLoading(false);
  };

  return {
    getUserCommunityPayments,
    setCommunityPayments,
    communityPayments,
    metaData,
    isLoading,
  };
};
