import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Modal } from "../../core/Modal/Modal";
import { Job, Languages } from "@sumit-platforms/types";
import { MultiSelect } from "../../core/MultiSelect/MultiSelect";

import "./CreateTranslationModal.scss";

export interface CreateTranslationModalProps {
  jobs: Job[];
  languages: Languages[];
  onApprove: ({
    idJobs,
    languages,
  }: {
    idJobs: number[];
    languages: Languages[];
  }) => Promise<void>;
  onCancel: () => void;
  restrictOneJobPerLanguage?: boolean;
}

export const CreateTranslationModal = ({
  jobs,
  languages,
  onApprove,
  onCancel,
  restrictOneJobPerLanguage,
}: CreateTranslationModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<Languages[]>([]);

  const isValid = useMemo(
    () => selectedLanguages.length > 0,
    [selectedLanguages]
  );

  const idJobs = useMemo(() => {
    return jobs.map((job) => job.idJob);
  }, [jobs]);

  const handleOnApprove = async () => {
    setLoading(true);
    try {
      await onApprove({ idJobs: idJobs, languages: selectedLanguages });
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
    }
  };

  const getTargetLangugaeDisableData = useCallback(
    (language: Languages) => {
      const isTargetLanguageMatchInputLanguage =
        jobs.length === 1 && jobs[0].inputLanguage?.includes(language);
      const isTargetLanguageAlreadyExits =
        restrictOneJobPerLanguage &&
        jobs.length === 1 &&
        jobs[0].jobsTranslations?.some((jobTranslation) =>
          jobTranslation.job?.outputLanguage?.includes(language)
        );
      const disabled =
        isTargetLanguageMatchInputLanguage || isTargetLanguageAlreadyExits;

      return {
        isTargetLanguageMatchInputLanguage,
        isTargetLanguageAlreadyExits,
        disabled,
      };
    },
    [jobs]
  );

  return (
    <Modal type={"info"} closeModal={onCancel}>
      <Grid
        className="CreateTranslationModal"
        container
        display={"flex"}
        flexDirection={"column"}
        py={3}
        px={4}
        width={"25rem"}
        textAlign={"center"}
      >
        <Grid item pt={1}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("create_translation")}
          </Typography>
        </Grid>
        <Grid item textAlign={"start"} className="description">
          <Typography>{t("create_translation_description")}</Typography>
        </Grid>
        <Grid item py={3} width={"50%"} margin={"auto"}>
          <MultiSelect
            isMulti={true}
            placeholder={t("select_target_language")}
            selected={selectedLanguages}
            setSelected={setSelectedLanguages}
            options={languages.map((l) => {
              const lang = l.replace("-", "_");
              const {
                disabled,
                isTargetLanguageAlreadyExits,
                isTargetLanguageMatchInputLanguage,
              } = getTargetLangugaeDisableData(l);
              return {
                toolTip: isTargetLanguageAlreadyExits
                  ? t("target_laguage_already_exists")
                  : isTargetLanguageMatchInputLanguage
                  ? t("target_language_match_job_language")
                  : undefined,
                label: t(lang),
                value: l,
                disabled,
              };
            })}
          />
        </Grid>
        <Grid item py={3} width={"50%"} margin={"auto"}>
          <Grid item display="flex" justifyContent={"center"} gap={3}>
            <Button onClick={onCancel} variant="outlined">
              {t("cancel") as string}
            </Button>
            <Button
              disabled={!isValid}
              loading={loading}
              onClick={handleOnApprove}
              color="primary"
            >
              {t("submit") as string}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
