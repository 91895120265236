import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Client,
  ClientFilterOptions,
  QueryParams,
} from "@sumit-platforms/types";
import clientService from "./../services/clientService";
import { clientStore } from "../store/client";
import { InvoiceService } from "@sumit-platforms/ui-bazar/services";

const useClients = ({
  idClient,
  config,
}: {
  idClient?: number;
  config?: any;
}): {
  clients: Client[];
  getClients: (query?: QueryParams<ClientFilterOptions>) => Promise<void>;
  client: Client | null;
  setClient: Dispatch<SetStateAction<Client | null>>;
  clientsQueryMetaData: any;
  getClientsQueryMetaData: () => Promise<void>;
  totalClients: number;
  hasMore: boolean;
  isLoading: boolean;
} => {
  const invoicesService = InvoiceService({ config });
  const { setClient: setClientStore } = clientStore();
  const [totalClients, setTotalClients] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [clientsQueryMetaData, setClientQueryMetaData] = useState<{
    maxJobsCount: number;
    maxYearlyTargets: number;
  }>({
    maxJobsCount: 0,
    maxYearlyTargets: 0,
  });
  const [clients, setClients] = useState<Client[]>([]);
  const [client, setClient] = useState<Client | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getClientsQueryMetaData = async () => {
    const _clientsQueryMetaData = await clientService.getClientsQueryMetaData();
    setClientQueryMetaData(_clientsQueryMetaData);
  };

  const getClients = async (query?: QueryParams<ClientFilterOptions>) => {
    try {
      setIsLoading(true);
      const { clients, totalClients } = await clientService.get(query);
      setClients((prev: Client[]) => {
        if (query && _.isNumber(query?.offset) && query.offset > 0) {
          const aggregatedClients = [...prev, ...clients];
          setHasMore(aggregatedClients.length !== totalClients);
          return aggregatedClients;
        } else {
          setHasMore(clients.length !== totalClients);
          return clients;
        }
      });
      setTotalClients(totalClients);
      setIsLoading(false);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getClientById = async (idClient: number) => {
    const _client = await clientService.getClientById(idClient);
    const invoices = await invoicesService.getInvoices(idClient);
    _client.invoices = invoices;
    setClient(_client);
    setClientStore(_client);
  };

  useEffect(() => {
    if (idClient) {
      getClientById(idClient);
    }
  }, [idClient]);

  return {
    clients,
    getClients,
    client,
    setClient,
    clientsQueryMetaData,
    getClientsQueryMetaData,
    totalClients,
    hasMore,
    isLoading,
  };
};

export default useClients;
