import React, { ChangeEvent, useMemo, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Modal } from "../../core/Modal/Modal";
import Input from "../../core/Input/Input";

import "./SplitJobModal.scss";
import { JobSplitRequestParams } from "@sumit-platforms/types";

export interface SplitJobModalProps {
  idJobs: number[];
  jobLength: number;
  onApprove: ({ splitCount, idJobs }: JobSplitRequestParams) => Promise<void>;
  onCancel: () => void;
}

export const SplitJobModal = ({
  idJobs,
  jobLength,
  onApprove,
  onCancel,
}: SplitJobModalProps) => {
  const { t } = useTranslation();
  const [splitCount, setSplitCount] = useState(2);
  const [loading, setLoading] = useState(false);

  const maxSplitCount = useMemo(
    () => Number((jobLength / 60 - 1).toFixed(0)),
    [jobLength]
  );

  const handleOnSplit = async () => {
    setLoading(true);
    try {
      await onApprove({ idJobs: idJobs, splitCount });
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
    }
  };
  const handleOnSplitChange = (e: Partial<ChangeEvent<HTMLInputElement>>) => {
    const allowedValue = _.min([e.target?.value, maxSplitCount]) || "";
    setSplitCount(parseInt(allowedValue.toString()));
  };

  return (
    <Modal type={"info"} closeModal={onCancel}>
      <Grid
        container
        display={"flex"}
        flexDirection={"column"}
        py={3}
        px={2}
        width={"25rem"}
        textAlign={"center"}
      >
        <Grid item pt={1}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("split_this_job")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{t("split_this_job_description")}</Typography>
        </Grid>
        <Grid item py={3} width={"50%"} margin={"auto"}>
          <Input
            min={2}
            value={splitCount}
            max={maxSplitCount}
            type="number"
            onChange={handleOnSplitChange}
          />
        </Grid>
        <Grid item display="flex" justifyContent={"center"} gap={3}>
          <Button onClick={onCancel} variant="outlined">
            {t("cancel") as string}
          </Button>
          <Button loading={loading} onClick={handleOnSplit} color="primary">
            {t("split") as string}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
