import { useMemo } from "react";
import { Box } from "@mui/material";
import { Shortcut } from "@sumit-platforms/types";
import { KeyboardShortcutTitle } from "./KeyboardShortcutTitle";
import { useTranslation } from "react-i18next";
import { KeyboardShortcutItem } from "./KeyboardShortcutItem";

export const KeyboardShortcutGroup = ({
  type,
  list,
  updateLocalUpdates,
  userShortcuts,
}: {
  type: string;
  list: Record<string, Shortcut[]>;
  updateLocalUpdates: (shortcut: Shortcut) => void;
  userShortcuts: Shortcut[];
}) => {
  const { t } = useTranslation();

  const globalGroup = useMemo(() => list["global"], [list]);

  const protocolBriefGroup = useMemo(
    () => [...(list["protocol"] || []), ...(list["brief"] || [])],
    [list]
  );

  const subtitlesGroup = useMemo(
    () => [
      ...(list["subtitles"] || []),
      ...(list["subtitlesTranslation"] || []),
    ],
    [list]
  );

  return (
    <Box className={"shortcutGroup"}>
      <KeyboardShortcutTitle type={type} />
      <Box className={"shortcutGroupListWrapper"}>
        {globalGroup.map((shortcut) => (
          <KeyboardShortcutItem
            key={shortcut.action}
            shortcut={shortcut}
            updateLocalUpdates={updateLocalUpdates}
            userShortcuts={userShortcuts}
          />
        ))}
        {protocolBriefGroup.length ? (
          <>
            <p className={"subgroup"}>{t("protocol_brief")}:</p>
            {protocolBriefGroup.map((shortcut) => (
              <KeyboardShortcutItem
                key={shortcut.action}
                shortcut={shortcut}
                updateLocalUpdates={updateLocalUpdates}
                userShortcuts={userShortcuts}
              />
            ))}
          </>
        ) : null}
        {subtitlesGroup.length ? (
          <>
            <p className={"subgroup"}>
              {t("subtitles_subtitles_translations")}:
            </p>
            {subtitlesGroup.map((shortcut) => (
              <KeyboardShortcutItem
                key={shortcut.action}
                shortcut={shortcut}
                updateLocalUpdates={updateLocalUpdates}
                userShortcuts={userShortcuts}
              />
            ))}
          </>
        ) : null}
      </Box>
    </Box>
  );
};
