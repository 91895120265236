import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { LoginEmailForm } from "@sumit-platforms/ui-bazar";

import authService from "./../../services/authService";

import "./Auth.scss";

const LoginPage: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const links = [
    {
      to: "/password-reset",
      label: t("forgot_password"),
    },
  ];

  return (
    <div className="LoginPage">
      <LoginEmailForm authService={authService} links={links} />
    </div>
  );
};

export default LoginPage;
