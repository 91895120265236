import React, { useCallback, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { faPlusCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { SelectSimpleOption } from "../SelectSimple/SelectSimple";
import _ from "lodash";
import { InputPair } from "../InputPair/InputPair";
import {
  ClientJobMetadata,
  clientJobMetadataOptions,
} from "@sumit-platforms/types";

import "./ClientJobMetadataForm.scss";

interface CustomMetadataFormProps {
  metadata?: ClientJobMetadata;
  onChange: React.Dispatch<React.SetStateAction<any | undefined>>;
}

export const ClientJobMetadataForm = ({
  metadata = [],
  onChange,
}: CustomMetadataFormProps) => {
  const { t } = useTranslation();
  const [newPairKey, setNewPairKey] = useState("");
  const [newPairValue, setNewPairValue] = useState<SelectSimpleOption | string>(
    ""
  );

  const inputsHasValues = useMemo(
    () => newPairKey && newPairValue,
    [newPairKey, newPairValue]
  );

  const handleRemovePair = useCallback(
    (index: number) =>
      onChange((prevArray: any[]) => prevArray.filter((_, ix) => index !== ix)),
    [onChange]
  );

  const handleAddPair = useCallback(() => {
    if (!inputsHasValues) return;
    onChange((prevArray: any[]) => {
      const newObject = {
        key: newPairKey.trim(),
        type: newPairValue,
      };
      return [...prevArray, newObject];
    });
    setNewPairKey("");
    setNewPairValue("");
  }, [inputsHasValues, newPairKey, newPairValue, onChange]);

  const onKeyChange = (e: any, i: number) => {
    onChange((prevArray: any[]) =>
      prevArray.map((obj, ix) => {
        return ix === i ? { ...obj, key: e.target.value } : obj;
      })
    );
  };

  const renderMetadataInputs = useCallback(() => {
    return metadata?.map(({ key, type }, i) => {
      return (
        <InputPair
          disable={{ type: true }}
          onKeyChange={(e) => onKeyChange(e, i)}
          keyValue={key}
          value={type}
          key={`${type}-${i}`}
          options={clientJobMetadataOptions}
          icon={{
            action: () => handleRemovePair(i),
            icon: faXmarkCircle,
            tooltip: t("remove"),
          }}
          showLabels={i === 0}
          inputWidth={"200px"}
          selectWidth={"200px"}
        />
      );
    });
  }, [metadata]);

  const onNewPairKeyChange = (e: any) => {
    setNewPairKey(e.target.value);
  };
  const onNewPairValueChange = (option: SelectSimpleOption) => {
    setNewPairValue(option);
  };

  return (
    <Grid container width={"100%"} py={2} maxHeight={"300px"} overflow={"auto"}>
      {renderMetadataInputs()}
      <InputPair
        keyValue={newPairKey}
        value={newPairValue}
        onKeyChange={onNewPairKeyChange}
        onValueChange={onNewPairValueChange}
        options={clientJobMetadataOptions}
        showLabels={_.isEmpty(metadata)}
        autofocus={true}
        selectWidth={"200px"}
        inputWidth={"200px"}
        icon={{
          tooltip: t("add"),
          disabled: !inputsHasValues,
          action: handleAddPair,
          icon: faPlusCircle,
        }}
      />
    </Grid>
  );
};
