import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { format } from "date-fns";

import { Grid } from "@mui/material";

import {
  ConfirmModal,
  ContextOption,
  GeneralTable,
  ModalType,
  SearchAndFilters,
} from "@sumit-platforms/ui-bazar";

import { getTaskByJobProgress } from "@sumit-platforms/ui-bazar/utils";

import {
  AssignmentTask,
  AssignStatus,
  Client,
  Job,
  JobFilterOptions,
  JobStatus,
  JobTypeMeta,
  QueryParams,
  TaskStatus,
  UsersJobs,
} from "@sumit-platforms/types";

import AssignmentService from "../../services/assignmentService";

import {
  availableStatusesMap,
  DATE_FORMAT_1,
  JOBS_INITIAL_ORDER,
  JOBS_INITIAL_ORDER_BY,
  JOBS_INITIAL_QUERY_LIMIT,
  JOBS_INITIAL_QUERY_OFFSET,
  TODAY_MIDNIGHT,
} from "@sumit-platforms/ui-bazar/constants";
import {
  AvailableFilters,
  AvailableHeadCellsKeys,
  useFilters,
  useHeadCells,
  useQuery,
  useSortOptions,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { getJobIncomeByStatus } from "@sumit-platforms/ui-bazar/utils";
import useJobs from "../../hooks/useJobs";

import { useModal } from "@sumit-platforms/ui-bazar/store";
import { useGlobalData } from "../../store/globalData";
import { useUser } from "../../store/user";

import "./AvailableJobs.scss";

interface AvailableJobRow {
  id: number;
  name: string;
  client: Client;
  type: JobTypeMeta["typeName"];
  status: JobStatus;
  duration: number;
  income: string;
  uploaded: Date;
  delivery: Date;
  contextMenu: any;
  job: Job;
  task?: AssignmentTask;
  contextDisabled?: boolean;
  disabled?: boolean;
}

const filterKeys: AvailableFilters[] = [
  "jobStatus",
  "projectName",
  "type",
  "languages",
  "length",
  "dueBy",
  "uploaded",
  "client",
  "payments",
];
const headCellsKeys = [
  "id",
  "name",
  "client",
  "type",
  "status",
  "duration",
  "income",
  "transcribeDelivery",
  "contextMenu",
] as AvailableHeadCellsKeys[];

const availableStatuses = availableStatusesMap.opera.availableJobs;
const AvailableJobs = () => {
  const { t } = useTranslation();
  const { jobTypes, setToast } = useGlobalData();
  const { user } = useUser();
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const { toastError, toastSuccess } = useToast({ setToast });
  const {
    jobs,
    getJobs,
    hasMore,
    isLoading: isJobsLoading,
    totalJobs,
    jobQueryMetaData,
    getJobQueryMetaData,
  } = useJobs({ jobType: "availableJobs" });

  const { sortOptions } = useSortOptions([
    "created_at",
    "name",
    "duration",
    "status",
  ]);

  useEffect(() => {
    getJobQueryMetaData({ filters: { status: availableStatuses } });
  }, [availableStatuses]);

  const defaultQuery: JobFilterOptions = useMemo(
    () => ({
      status: [],
      projectName: [],
      type: [],
      inputLanguage: [],
      outputLanguage: [],
      durationStart: 0,
      durationEnd: jobQueryMetaData.maxDuration,
      deliveryStart: TODAY_MIDNIGHT,
      deliveryEnd: TODAY_MIDNIGHT,
      uploadedStart: TODAY_MIDNIGHT,
      uploadedEnd: TODAY_MIDNIGHT,
      clientIds: [],
    }),
    [jobQueryMetaData]
  );

  const {
    onScroll,
    onSearch,
    onFilterChange,
    onSort,
    loading: isQueryLoading,
  } = useQuery<JobFilterOptions>({
    queryLimit: JOBS_INITIAL_QUERY_LIMIT,
    queryOffset: JOBS_INITIAL_QUERY_OFFSET,
    order: JOBS_INITIAL_ORDER,
    orderBy: JOBS_INITIAL_ORDER_BY,
    onBuildQuery,
    onResetOffset: () => {
      setJobRows([]);
    },
  });

  const { headCells } = useHeadCells({
    headCellsKeys,
    styles: {
      name: { width: "20%" },
      id: { width: "5%", textAlign: "center" },
      contextMenu: { width: "6%", maxWidth: "auto" },
      client: { width: "18%" },
    },
    tableContextCallBack: getTableContext,
    userStatus: user?.status,
  });

  const { filtersScheme } = useFilters({
    metaData: { ...jobQueryMetaData, jobTypes },
    filterKeys,
    customJobStatuses: availableStatuses,
  });
  const [jobRows, setJobRows] = useState<AvailableJobRow[]>([]);

  useEffect(() => {
    if (_.isArray(jobs) && !isJobsLoading) {
      const newJobRows = jobs.map(createJobRow);
      setJobRows(newJobRows);
    }
  }, [jobs]);

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<JobFilterOptions>;
  }) {
    if (query.search || query.filters) {
      const _queryFilters = { ...query.filters };
      _queryFilters.status = _queryFilters.status || availableStatuses;
      query.filters = _queryFilters;
    }
    await getJobs({ query });
  }

  const createJobRow = (job: Job): AvailableJobRow => {
    const shouldDisabled = job.assignments?.some((assignment: UsersJobs) => {
      const userHasAssignment = assignment.idUser === user?.idUser;
      if (userHasAssignment) {
        const isPending = assignment.assignStatus === AssignStatus.pending;
        const isAssignmentInWork = assignment.taskStatus === TaskStatus.work;
        const isQcAssignment = assignment.task === AssignmentTask.qc;
        const relevantStageToDisable =
          (isQcAssignment && job.status === JobStatus.pending_review) ||
          (!isQcAssignment && job.status === JobStatus.ready);
        return !isAssignmentInWork && isPending && relevantStageToDisable;
      }
      return false;
    });
    return {
      id: job.idJob,
      name: job.name,
      client: job.client,
      type: job.type.typeName,
      status: job.status,
      duration: job.duration || 0,
      uploaded: new Date(job.createdAt),
      delivery: new Date(job.delivery),
      contextMenu: null,
      income: getJobIncomeByStatus(job),
      job: job,
      task: job.userJob?.task,
      disabled: shouldDisabled,
      contextDisabled: shouldDisabled,
    };
  };

  const closeModal = () => {
    clearModalContent();
  };

  const openConfirmModal = (
    title: any,
    message: any,
    confirm: () => Promise<any>,
    closeAfterConfirm = true,
    modalType: ModalType
  ): void => {
    setModalType(modalType);
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  const getJobDoneModalTitle = (jobRow: AvailableJobRow) => {
    return (
      <Trans
        i18nKey="take_job_modal_title"
        values={{
          jobName: jobRow.name,
        }}
        components={{
          strong: <strong style={{ marginLeft: "5px" }} />,
          br: <br />,
        }}
      />
    );
  };

  const getJobDoneModalText = (jobRow: AvailableJobRow) => {
    return (
      <Trans
        i18nKey="take_job_modal_text"
        values={{
          jobName: jobRow.name,
          price: jobRow.income,
          deadline: format(new Date(jobRow.delivery), DATE_FORMAT_1),
        }}
        components={{ strong: <strong /> }}
      />
    );
  };

  const onUpdateJob = (jobRow: AvailableJobRow) => {
    const updatedRowIndex = jobs.findIndex((job) => job.idJob === jobRow.id);
    const newRows = [...jobRows];
    newRows[updatedRowIndex].disabled = true;
    newRows[updatedRowIndex].contextDisabled = true;
    setJobRows(newRows);
  };

  const onJobTakeConfirm = async (jobRow: AvailableJobRow) => {
    try {
      await AssignmentService.communityMemberRequestJob({
        idJob: jobRow.id,
        task: getTaskByJobProgress(jobRow.job),
      });
      await onUpdateJob(jobRow);
      toastSuccess(t("request_assignment_success"));
    } catch (e: any) {
      toastError(t("request_assignment_fail"), e);
    }
  };

  const handleTakeJob = async (jobRow: AvailableJobRow) => {
    openConfirmModal(
      getJobDoneModalTitle(jobRow),
      getJobDoneModalText(jobRow),
      () => onJobTakeConfirm(jobRow),
      true,
      "info"
    );
  };

  function getTableContext(row: AvailableJobRow): ContextOption[] {
    return [
      {
        name: t("take"),
        action: () => handleTakeJob(row),
        disabled: row.contextDisabled,
      },
    ];
  }

  return (
    <Grid
      className={"AvailableJobs Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("available_jobs")}</h1>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${jobRows?.length} ${t("from")} ${totalJobs}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          isLoading={isQueryLoading}
          direction={t("dir") as "ltr" | "rtl"}
          onSearch={onSearch}
          filters={filtersScheme}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          onSortChange={onSort}
          sortOptions={sortOptions}
        />
      </Grid>
      <Grid item xs={11}>
        <GeneralTable
          headCells={headCells}
          rows={jobRows}
          onLoadMore={onScroll}
          loading={isQueryLoading}
          hasMore={hasMore}
          allowSelect={false}
        />
      </Grid>
    </Grid>
  );
};

export default AvailableJobs;
