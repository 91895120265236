import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { STTConfigPage, ClientSTT } from "@sumit-platforms/types";
import { PageSettings } from "../core/SettingsPage/SettingsPage";
import { getSTTModelPageInputs } from "./pageSettings/stt/sttPage";

export interface STTModelSetttingsMetaData {
  sttModels: any[];
}

export interface STTModelSetttingsActions {
  onConfirm: () => void;
  onCancel: () => void;
}
export interface UseSTTModelSettingsInput<TMetaData, TActions> {
  settings?: ClientSTT;
  metadata?: TMetaData;
  actions?: TActions;
}

export const useSTTModelSettings = ({
  settings,
  metadata,
  actions,
}: UseSTTModelSettingsInput<
  STTModelSetttingsMetaData,
  STTModelSetttingsActions
>) => {
  const _handleGetSTTModelPageInputs = ({
    key,
    value,
    t,
  }: {
    key: string;
    value: any;
    t: any;
  }) => {
    const pages = getSTTModelPageInputs({
      key,
      args: new STTConfigPage(value),
      t,
      metadata,
      actions,
    });
    return pages;
  };
  const { t } = useTranslation();

  const [pages, setPages] = useState<PageSettings<ClientSTT>[]>(
    settings
      ? _.entries(settings).map((entry) =>
          _handleGetSTTModelPageInputs({
            key: entry[0],
            value: entry[1],
            t,
          })
        )
      : []
  );

  const [settingsReplica, setSettingsReplica] = useState<ClientSTT | null>(
    null
  );

  const resetPages = () => {
    if (settingsReplica) {
      setPages(
        settingsReplica
          ? Object.entries(settingsReplica).map((entry) =>
              _handleGetSTTModelPageInputs({
                key: entry[0],
                value: entry[1],
                t,
              })
            )
          : []
      );
    }
  };

  useEffect(() => {
    if (settings) {
      setPages(
        Object.entries(settings).map((entry) =>
          _handleGetSTTModelPageInputs({
            key: entry[0],
            value: entry[1],
            t,
          })
        )
      );
      if (!settingsReplica) {
        setSettingsReplica({ ...settings });
      }
    }
  }, [settings]);

  return { pages, setPages, resetPages };
};
