import { useTranslation } from "react-i18next";
import { Box, ThemeProvider } from "@mui/material";
import { Job, JobStatus } from "@sumit-platforms/types";

import { bazarTheme } from "../../bazar-theme";
import { Button } from "../../core/Button/Button";

import "./JobsDeliveryStatus.scss";

interface JobsDeliveryStatusProps {
  jobs: Job[];
  onAddJobs: () => void;
  onShowJobs: () => void;
}

export const JobsDeliveryStatus = ({
  jobs,
  onAddJobs,
  onShowJobs,
}: JobsDeliveryStatusProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={bazarTheme}>
      <Box
        className={"JobsDeliveryStatus"}
        px={2}
        py={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-evenly"}
      >
        <Box>
          <p>{t("this_week_delivery_jobs_by_status")}:</p>
        </Box>
        <Box>
          <Box
            className={"jobsCount"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <p>{t("open")}:</p>
            <p>{jobs.filter((j) => j.status === JobStatus.ready)?.length}</p>
          </Box>
          <Box
            className={"jobsCount"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <p>{t("at_work")}:</p>
            <p>
              {jobs.filter((j) => j.status === JobStatus.transcribe)?.length}
            </p>
          </Box>
          <Box
            className={"jobsCount"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <p>{t("reviewing")}:</p>
            <p>{jobs.filter((j) => j.status === JobStatus.review)?.length}</p>
          </Box>
          <Box
            className={"jobsCount"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <p>{t("done")}:</p>
            <p>{jobs.filter((j) => j.status === JobStatus.done)?.length}</p>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"} gap={2}>
          <Button variant="outlined" onClick={onShowJobs}>
            {t("show_jobs")}
          </Button>
          <Button onClick={onAddJobs}>{t("add_jobs")}</Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
