import React, { useState } from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import { ClientJobMetadataForm } from "../../core/ClientJobMetadataForm/ClientJobMetadataForm";
import {
  ClientJobMetadata,
  clientJobMetadataOptions,
} from "@sumit-platforms/types";
import { Button } from "../../core/Button/Button";
import { useTranslation } from "react-i18next";
import { Modal } from "../../core/Modal/Modal";

interface JobMetadataModalProps {
  metadata?: ClientJobMetadata;
  onConfirm: (updatedMetadata?: ClientJobMetadata) => Promise<void>;
  onCancel: () => void;
}
export const ClientJobMetadataModal = ({
  metadata,
  onConfirm,
  onCancel,
}: JobMetadataModalProps) => {
  const [updatedMetadata, setUpdatedMetadata] = useState(metadata);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirm = async (metadata?: ClientJobMetadata) => {
    setLoading(true);
    const formattedMetadata = formatKeysToSnakeCase(metadata);
    await onConfirm(formattedMetadata);
    setLoading(false);
  };

  const formatKeysToSnakeCase = (metadata?: ClientJobMetadata) => {
    return metadata?.map((obj) => ({ ...obj, key: _.snakeCase(obj["key"]) }));
  };

  return (
    <Modal closeModal={onCancel} type={"info"}>
      <Grid
        container
        width={"36rem"}
        maxHeight={"30rem"}
        padding={2}
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("edit_job_metadata")}
          </Typography>
        </Grid>
        <Grid
          container
          display={"flex"}
          direction={"column"}
          alignItems={"center"}
          maxHeight={"60%"}
          width={"100%"}
        >
          <ClientJobMetadataForm
            metadata={updatedMetadata}
            onChange={setUpdatedMetadata}
          />
          <Grid
            container
            width={"100%"}
            px={2}
            justifyContent={"center"}
          ></Grid>
        </Grid>
        <Grid
          item
          pb={2}
          py={2}
          display={"flex"}
          width={"50%"}
          height={"fit-content"}
          marginTop={"auto"}
          justifyContent={"space-evenly"}
        >
          <Button variant={"outlined"} onClick={onCancel}>
            {t("cancel") as string}
          </Button>
          <Button
            onClick={() => handleConfirm(updatedMetadata)}
            loading={loading}
            disabled={updatedMetadata === metadata}
          >
            {t("confirm") as string}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
