import config from "../../../config";
import _ from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { JobStatus, StatusPercents } from "@sumit-platforms/types";

import {
  getOperaStatusVisual,
  secondsToTimeString,
} from "@sumit-platforms/ui-bazar/utils";

import { JobService } from "@sumit-platforms/ui-bazar/services";

import { SpinningLoader } from "@sumit-platforms/ui-bazar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceDisappointed } from "@fortawesome/pro-light-svg-icons";

import "./DurationModal.scss";

const jobService = JobService({ config });

export const DurationModal = () => {
  const { t } = useTranslation();
  const [statusPercents, setStatusPercents] = useState<StatusPercents[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getStatusPrecent = async () => {
      try {
        setIsLoading(true);
        const _statusPercents = await jobService.getJobsStatusPercents([
          JobStatus.transcribe,
          JobStatus.stt_fail,
          JobStatus.stt,
          JobStatus.review,
          JobStatus.ready,
          JobStatus.pending_review,
          JobStatus.pending,
        ]);
        setStatusPercents(_statusPercents);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    getStatusPrecent();
  }, []);

  const groupStatusPercentsByProccessing = () => {
    return _.entries(
      _.groupBy(
        statusPercents.map((s) => ({
          ...s,
          title: getOperaStatusVisual({
            status: s.status,
          }).statusTitle,
        })),
        "title"
      )
    ).map((entry) => {
      const [_key, grouped] = entry;
      if (grouped.length > 1) {
        return {
          status: grouped[0].status,
          title: grouped[0].title,
          percents: _.sumBy(grouped, (g) => g.percents),
          duration: _.sumBy(grouped, (g) => g.duration),
        };
      } else return grouped[0];
    });
  };

  const statusesGroupedByProccessing = useMemo(() => {
    return groupStatusPercentsByProccessing();
  }, [statusPercents]);

  return (
    <Box className="DurationModal" py={2} px={6}>
      <Box
        className="title"
        display={"flex"}
        justifyContent={"center"}
        pt={2}
        pb={4}
      >
        {t("total_jobs_duration_per_status")}
      </Box>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"start"}>
        {isLoading ? (
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <Box width={100} height={100}>
              <SpinningLoader />
            </Box>{" "}
          </Box>
        ) : statusesGroupedByProccessing.length === 0 ? (
          <Box
            py={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <FontAwesomeIcon
              size={"3x"}
              icon={faFaceDisappointed}
              className="icon"
            />
            <Box pt={3}>{t("could_not_retrieve_data")}</Box>
          </Box>
        ) : (
          statusesGroupedByProccessing.map((s, i) => {
            return (
              <Grid
                key={i}
                container
                px={2}
                py={1}
                mb={2}
                className="durationSquare"
                display={"flex"}
                sx={{
                  backgroundColor: getOperaStatusVisual({
                    status: s.status,
                  }).statusColor,
                }}
              >
                <Grid
                  className={"statusName"}
                  item
                  display={"flex"}
                  xs={8}
                  alignItems={"center"}
                >
                  {t(s.title)}
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  flexDirection={"column"}
                  xs={4}
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                >
                  <Box>{secondsToTimeString(s.duration)}</Box>
                  <Box className={"durationPercentages"}>{`${Math.round(
                    s.percents
                  )}%`}</Box>
                </Grid>
              </Grid>
            );
          })
        )}
      </Box>
    </Box>
  );
};
