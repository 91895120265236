import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Button, Input, MultiSelect, Switch } from "@sumit-platforms/ui-bazar";
import { User, UserRole } from "@sumit-platforms/types";

import "./EditUserModal.scss";

export interface EditUserModalProps {
  confirm: (user: Partial<User>, idRole: UserRole) => void;
  cancel: () => void;
  user: User;
}

export const EditUserModal: FC<EditUserModalProps> = ({
  confirm,
  cancel,
  user,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [userRoles, setUserRoles] = useState<UserRole[]>(
    user.roles?.map((r) => r.idRole) || []
  );

  const [isContact, setIsContact] = useState<boolean>(false);
  const handleOnConfirm = async () => {
    setIsLoading(true);
    if (!_.isEmpty(userRoles)) confirm({}, userRoles[0]);
    setIsLoading(false);
  };

  return (
    <Grid className={"EditUserModal"} container width={"30rem"}>
      <Grid container px={4} pt={4} pb={3}>
        <Box width={"100%"}>
          <Box pb={3} display={"flex"} justifyContent={"center"}>
            <h4>{t("edit_user")}</h4>
          </Box>
          <Box>
            <p>
              <Trans
                i18nKey="you_can_edit_the_details_of"
                values={{ name: `${user.firstName} ${user.lastName}` }}
                components={{ italic: <i />, bold: <strong /> }}
              />
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid container px={4} pb={3}>
        <Grid item xs={8} pb={2}>
          <Box>
            <Input
              disabled
              value={`${user.firstName} ${user.lastName}`}
              type="text"
              onChange={_.noop}
            />
          </Box>
        </Grid>
        <Grid item xs={8} pb={2}>
          <Box>
            <Input disabled value={user.email} type="text" onChange={_.noop} />
          </Box>
        </Grid>
        <Grid item xs={8} pb={2}>
          <Box className={"userRoleInput"}>
            <MultiSelect
              selected={userRoles}
              setSelected={(selected) => setUserRoles(selected)}
              placeholder={t("select_user_role")}
              isMulti={false}
              options={[
                UserRole.CLIENT_ADMIN,
                UserRole.CLIENT_USER,
                UserRole.CS_MANAGER,
              ].map((role) => ({
                label: t(role.toLowerCase()),
                value: role,
              }))}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          pb={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <p>{t("define_as_a_contact")}:</p>
          </Box>
          <Box>
            <Switch
              checked={isContact}
              onChange={(e) => setIsContact(e.target.checked)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          disabled={_.isEmpty(userRoles)}
          onClick={handleOnConfirm}
          color="primary"
          loading={isLoading}
        >
          {t("save_changes")}
        </Button>
      </Grid>
    </Grid>
  );
};
