import _ from "lodash";
import React, { FC, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Button } from "./../../core/Button/Button";
import { MultiSelect } from "./../../core/MultiSelect/MultiSelect";
import { ValidationLang } from "@sumit-platforms/types";

import "./SelectLanguageModal.scss";

export interface SelectLanguageModalProps {
  langOptions: ValidationLang[];
  onConfirm: (language: string) => void;
  onCancel: () => void;
}

export const SelectLanguageModal: FC<SelectLanguageModalProps> = ({
  langOptions,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [language, setLanguage] = useState<string[]>([]);

  const isValid = useMemo<boolean>(() => {
    return !_.isEmpty(language);
  }, [language]);

  const handleOnConfirm = async () => {
    if (!isValid) return;
    setIsLoading(true);
    onConfirm(language[0]);
    setIsLoading(false);
  };

  return (
    <Grid className={"SelectLanguageModal"} container>
      <Grid container px={4} pt={4} pb={3}>
        <Box width={"100%"}>
          <Box pb={3} display={"flex"} justifyContent={"center"}>
            <h4>{t("select_language")}</h4>
          </Box>
          <Box>
            <p>
              <Trans i18nKey="select_language" />
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid container px={4} pb={3}>
        <Grid item xs={8} pb={2}>
          <Box className={"selectLanguageInput"}>
            <MultiSelect
              selected={language}
              setSelected={(selected) => setLanguage(selected)}
              placeholder={t("select_language")}
              isMulti={false}
              options={langOptions.map((lang) => ({
                label: t(lang),
                value: lang,
              }))}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={onCancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button
          disabled={!isValid}
          onClick={handleOnConfirm}
          color="primary"
          loading={isLoading}
        >
          {t("confirm")}
        </Button>
      </Grid>
    </Grid>
  );
};
