import { TFunction } from "i18next";
import { faAlignJustify } from "@fortawesome/pro-light-svg-icons";
import { Align, IntervalsGeneralPage } from "@sumit-platforms/types";
import { alignOptions, fontFamilyOptions, fontSizeOptions } from "../../shared";
import { PageSettings } from "./../../../../core/SettingsPage/SettingsPage";

export const getGeneralPageInputs: (
  args: IntervalsGeneralPage,
  t: TFunction
) => PageSettings<{ general: IntervalsGeneralPage }> = (
  args: IntervalsGeneralPage,
  t: TFunction
) => {
  return {
    settings: { general: args },
    pageTab: t("settings_intervals_general"),
    sections: [
      {
        title: t("settings_intervals_general"),
      },
      {
        inputs: [
          {
            isColumn: true,
            label: `${t("settings_intervals_general_preset_name")}:`,
            type: "text",
            key: "general.presetName",
          },
        ],
      },
      {
        inputs: [
          {
            label: `${t("settings_intervals_general_font")}:`,
            type: "select",
            options: fontFamilyOptions,

            key: "general.font.fontFamily",
          },
          {
            type: "select",
            options: fontSizeOptions,

            key: "general.font.fontSize",
          },
          {
            type: "toggle",
            exclusive: true,
            buttons: [
              ...alignOptions,
              {
                value: Align.justify,
                label: faAlignJustify,
              },
            ],
            key: "general.font.align",
          },
        ],
      },
      // {
      //   inputs: [
      //     {
      //       label: `${t("settings_intervals_letters_spacing")}:`,
      //       type: "select",
      //       options: fontSizeOptions,
      //       key: "general.letterSpacing",
      //     },
      //     {
      //       label: `${t("settings_intervals_rows_spacing")}:`,
      //       type: "select",
      //       options: fontSizeOptions,
      //       key: "general.rowsSpacing",
      //     },
      //     {
      //       label: `${t("settings_intervals_paragraph_spacing")}:`,
      //       type: "select",
      //       options: fontSizeOptions,
      //       key: "general.paragraphSpacing",
      //     },
      //   ],
      // },
    ],
  };
};
