import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import {
  Job,
  JobCustomMetadata,
  JobCustomMetadataItem,
} from "@sumit-platforms/types";
import { Input } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";

interface JobMetadataProps {
  selectedJobs: Job[];
  updatedJob: Partial<Job>;
  handleChange: (field: keyof Job, value: any) => any;
}

const JobMetadata = ({
  selectedJobs,
  updatedJob,
  handleChange,
}: JobMetadataProps) => {
  const [isMetadataInputsOpen, setIsMetadataInputsOpen] = useState(false);
  const { t } = useTranslation();

  const metadataToRender = useMemo(
    () =>
      updatedJob?.client?.jobMetadata || selectedJobs[0].client?.jobMetadata,
    [selectedJobs, updatedJob]
  );

  const onChange = (e: any, key: string) => {
    const value = e.target?.value || "";
    const newMetadata = [...(updatedJob?.customMetadata || [])] || [];
    const itemIndex = newMetadata.findIndex(
      (item: JobCustomMetadataItem) => item.key === key
    );
    if (itemIndex !== -1) {
      newMetadata[itemIndex] = {
        ...newMetadata[itemIndex],
        value,
      } as JobCustomMetadataItem;
    } else {
      newMetadata.push({ key, value } as JobCustomMetadataItem);
    }

    handleChange("customMetadata", newMetadata);
  };

  const getMatchedJobMetadataValue = ({
    clientMetadataItem,
  }: {
    clientMetadataItem: ClientJobMetadataItem;
  }) =>
    updatedJob?.customMetadata?.find(
      (jobMetadataItem) => jobMetadataItem.key === clientMetadataItem.key
    )?.value || "";

  return (
    <Box className={"metadataWrapper"}>
      <Box
        className="metadataCollapse"
        onClick={() => setIsMetadataInputsOpen(!isMetadataInputsOpen)}
      >
        {t("metadata")}
        <FontAwesomeIcon
          style={{ marginLeft: "6px" }}
          size={"sm"}
          icon={isMetadataInputsOpen ? faChevronDown : faChevronRight}
        />
      </Box>
      {isMetadataInputsOpen && (
        <Grid container justifyContent={"space-between"} pt={2} width={"100%"}>
          {metadataToRender?.map((clientMetadataItem) => {
            const type = clientMetadataItem.type || "text";
            const jobValue = getMatchedJobMetadataValue({ clientMetadataItem });
            return (
              <Grid
                item
                key={`${clientMetadataItem.key}-${type}`}
                width={"47%"}
                py={"2px"}
              >
                <Input
                  type={(type || "text") as any}
                  value={jobValue || ""}
                  onChange={(e) => onChange(e, clientMetadataItem.key)}
                  label={clientMetadataItem.key || ""}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default JobMetadata;
