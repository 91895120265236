import { TFunction } from "i18next";
import { PageSettings } from "../../../core/SettingsPage/SettingsPage";
import { STTConfigPage, STTModel } from "@sumit-platforms/types";
import _ from "lodash";
import {
  STTModelSetttingsActions,
  STTModelSetttingsMetaData,
} from "../../useSTTModelSettings";

export const getSTTModelPageInputs = ({
  key,
  args,
  t,
  metadata,
  actions,
}: {
  key: string;
  args: STTConfigPage;
  t: TFunction;
  metadata?: STTModelSetttingsMetaData;
  actions?: STTModelSetttingsActions;
}): PageSettings<STTModel> => {
  const settings = {};
  const onConfirm = actions?.onConfirm;
  const onCancel = actions?.onCancel;
  const sttModels =
    metadata?.sttModels?.map((m: any) => ({
      value: m.model,
      label: t(m.name),
    })) || [];
  _.set(settings, key, args);
  return {
    settings,
    sections: [
      {
        title: t("settings_stt_stt_model"),
        inputs: [
          {
            label: t("settings_stt_stt_model"),
            type: "select",
            options: sttModels,
            key: `${key}.sttModelId`,
            isColumn: true,
          },
        ],
      },
      {
        inputs: [
          {
            label: t("settings_stt_stt_split_subtitles"),
            type: "switch",
            key: `${key}.splitSubtitles`,
          },
        ],
      },
      {
        isBottom: true,
        inputs: [
          {
            type: "button",
            buttonLabel: "cancel",
            variant: "outlined",
            color: "primary",
            onClick: onCancel,
            key: "",
          },
          {
            type: "button",
            buttonLabel: "confirm",
            variant: "contained",
            color: "primary",
            onClick: onConfirm,
            key: "",
          },
        ],
      },
    ],
    path: key,
  };
};
