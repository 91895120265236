import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SpinningLoader } from "../SpinningLoader/SpinningLoader";
import { Switch } from "../Switch/Switch";

interface RestoreJobHeaderProps {
  isLoading: boolean;
  autoSaveChecked: boolean;
  handleToggleAutoSaves: () => void;
}

export const RestoreJobHeader = ({
  isLoading,
  autoSaveChecked,
  handleToggleAutoSaves,
}: RestoreJobHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="restoreJobHeader">
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display="flex" alignItems="center">
          <label
            className={"autoSaveSwitchLabel"}
            htmlFor={"toggle auto saves"}
          >
            {t("include_auto_saves")}
          </label>
          <Switch
            checked={autoSaveChecked}
            onChange={handleToggleAutoSaves}
            color="primary"
            id={"toggle auto saves"}
            disabled={isLoading}
          />
        </Box>
        {isLoading && (
          <Box width={20} sx={{ marginLeft: "auto" }}>
            <SpinningLoader />
          </Box>
        )}
      </Box>
    </div>
  );
};
