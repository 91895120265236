type Languages =
  | "he-IL"
  | "en-US"
  | "ar-IL"
  | "es-ES"
  | "ru-RU"
  | "pt-BR"
  | "pt-PT"
  | "fr-FR"
  | "hi-IN"
  | "de-DE";

interface LanguagesOptions {
  label: string;
  value: Languages;
}

const languages: LanguagesOptions[] = [
  {
    label: "Hebrew",
    value: "he-IL",
  },
  {
    label: "English",
    value: "en-US",
  },
  {
    label: "Arabic (IL)",
    value: "ar-IL",
  },
  {
    label: "Spanish (ES)",
    value: "es-ES",
  },
  {
    label: "Russian",
    value: "ru-RU",
  },
  {
    label: "Portuguese (BR)",
    value: "pt-BR",
  },
  {
    label: "French",
    value: "fr-FR",
  },
  {
    label: "Hindi",
    value: "hi-IN",
  },
  {
    label: "German",
    value: "de-DE",
  },
];

export { languages, Languages };
