import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  DecisionsPage,
  GeneralPage,
  HeaderFooterPage,
  InformationPage,
  NotesPage,
  SpeakersDocxConfig,
  RemarksPage,
  SpeakersPage,
  SubjectsPage,
  TableOfSubjectsPage,
} from "@sumit-platforms/types";
import { getGeneralPageInputs } from "./pageSettings/docx/general";
import { getHeaderFooterPageInputs } from "./pageSettings/docx/headerFooter";
import { getInformationPageInputs } from "./pageSettings/docx/information";
import { getTableOfSubjectsPageInputs } from "./pageSettings/docx/tableOfSubjects";
import { getRemarksPageInputs } from "./pageSettings/docx/remarks";
import { getSubjectsPageInputs } from "./pageSettings/docx/subjects";
import { getSpeakersPageInputs } from "./pageSettings/docx/speakers";
import { getDecisionPageInputs } from "./pageSettings/docx/decisions";
import { getNotesPageInputs } from "./pageSettings/docx/notes";
import { PageSettings } from "../core/SettingsPage/SettingsPage";

export type ProtocolDocxSettingsPages =
  | { general: GeneralPage }
  | { headerFooter: HeaderFooterPage }
  | { information: InformationPage }
  | { tableOfSubjects: TableOfSubjectsPage }
  | { remarks: RemarksPage }
  | { subjects: SubjectsPage }
  | { speakers: SpeakersPage }
  | { decisions: DecisionsPage }
  | { notes: NotesPage };

export interface UseSpeakersDocxSettingsInput<TMetaData, TActions> {
  settings?: SpeakersDocxConfig;
  metadata?: TMetaData;
  actions?: TActions;
}

export const useSpeakersDocxSettings = ({
  settings,
}: UseSpeakersDocxSettingsInput<any, any>) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState<PageSettings<ProtocolDocxSettingsPages>[]>(
    [
      getGeneralPageInputs(new GeneralPage(settings?.general), t),
      getHeaderFooterPageInputs(
        new HeaderFooterPage(settings?.headerFooter),
        t
      ),
      getInformationPageInputs(new InformationPage(settings?.information), t),
      getTableOfSubjectsPageInputs(
        new TableOfSubjectsPage(settings?.tableOfSubjects),
        t
      ),
      getRemarksPageInputs(new RemarksPage(settings?.remarks), t),
      getSubjectsPageInputs(new SubjectsPage(settings?.subjects), t),
      getSpeakersPageInputs(new SpeakersPage(settings?.speakers), t),
      getDecisionPageInputs(new DecisionsPage(settings?.decisions), t),
      getNotesPageInputs(new NotesPage(settings?.notes), t),
    ]
  );

  const [settingsReplica, setSettingsReplica] =
    useState<SpeakersDocxConfig | null>(null);

  const resetPages = () => {
    if (settingsReplica) {
      setPages([
        getGeneralPageInputs(settingsReplica.general, t),
        getHeaderFooterPageInputs(settingsReplica.headerFooter, t),
        getInformationPageInputs(settingsReplica.information, t),
        getTableOfSubjectsPageInputs(settingsReplica.tableOfSubjects, t),
        getRemarksPageInputs(settingsReplica.remarks, t),
        getSubjectsPageInputs(settingsReplica.subjects, t),
        getSpeakersPageInputs(settingsReplica.speakers, t),
        getDecisionPageInputs(settingsReplica.decisions, t),
        getNotesPageInputs(settingsReplica.notes, t),
      ]);
    }
  };

  useEffect(() => {
    if (settings) {
      setPages([
        getGeneralPageInputs(settings.general, t),
        getHeaderFooterPageInputs(settings.headerFooter, t),
        getInformationPageInputs(settings.information, t),
        getTableOfSubjectsPageInputs(settings.tableOfSubjects, t),
        getRemarksPageInputs(settings.remarks, t),
        getSubjectsPageInputs(settings.subjects, t),
        getSpeakersPageInputs(settings.speakers, t),
        getDecisionPageInputs(settings.decisions, t),
        getNotesPageInputs(settings.notes, t),
      ]);
      if (!settingsReplica) {
        setSettingsReplica({ ...settings });
      }
    }
  }, [settings]);

  return { pages, setPages, resetPages };
};
