import React from "react";
import { Chip as MuiChip, ThemeProvider } from "@mui/material";

import { bazarTheme } from "../../bazar-theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

export interface BadgeProps {
  label: string;
  style?: React.CSSProperties;
}

export const Badge = ({ label, style }: BadgeProps) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <MuiChip
        className={"BazarBadge"}
        color={"secondary"}
        sx={{
          height: "24px",
          fontSize: "12px",
          ...style,
        }}
        label={label}
      />
    </ThemeProvider>
  );
};
