import _ from "lodash";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { FC, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { TimerPicker, TimerPickerReadyOnly } from "@sumit-platforms/ui-bazar";
import {
  Word,
  SubtitlesRange,
  JobWithData,
  ShortcutAction,
} from "@sumit-platforms/types";

import { handleKeyboardShortcut } from "../../utils/keyboardShortcuts";
import { focusAndSetCursor } from "../../utils/focusAndScroll";
import { createVtt } from "../../utils/createVtt";

import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";
import TimeService from "../../services/TimeService";
// import TrackingService from "../../services/TrackingService";
// import FeatureFlagsService from "../../services/FeatureFlagsService";

import RangeValidation from "../RangeValidation/RangeValidation";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";

interface Props {
  job: JobWithData;
  ranges: SubtitlesRange[];
  range: SubtitlesRange;
  rangeIndex: number;
  setFocusedRangeIndex?: (i: number) => void;
  rangesCount: number;
  onPressEnter: (options: {
    rangeIndex: number;
    updatedRangeWords: Word[];
    selectedWordIndex: number;
    wordCharIndex: number;
    event: React.KeyboardEvent;
    breakIsolated: boolean;
  }) => void;
  breakLineInsideRange: (rangeIndex: number) => void;
  mergeRange: (
    rangeIndex: number,
    updatedRangeWords: Word[],
    mergeWithNextRange: boolean
  ) => void;
  deleteRange: (rangeIndex: number) => void;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text";
  }) => void;
  isPassed: boolean;
  isCurrentPlayingRange: boolean;
  updateRangeWords: (rangeIndex: number, rangeWords: Word[]) => void;
  direction: "ltr" | "rtl";
  disabled?: boolean;
  featureFlags?: {
    experimentalSubtitlesEditor?: boolean;
    useNewKeyboardShortcuts?: boolean;
  };
}
const RangeSubtitles: FC<Props> = ({
  job,
  ranges,
  range,
  rangeIndex,
  setFocusedRangeIndex,
  rangesCount,
  onPressEnter,
  breakLineInsideRange,
  mergeRange,
  deleteRange,
  isPassed,
  updateRangeWords,
  updateRangeTimes,
  isCurrentPlayingRange,
  direction,
  disabled,
  featureFlags,
}) => {
  const { t } = useTranslation();

  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [oldPlainWords, setOldPlainWords] = useState("");
  const [plainWords, setPlainWords] = useState("");

  const [rangeTimes, setRangeTimesState] = useState<{ [key: string]: string }>({
    start: "",
    end: "",
  });

  useEffect(() => {
    if (!range) return;
    setRangeAndPlainWords({ setOld: true });
    setRangeTimes();
  }, []);

  useEffect(() => {
    if (!range) return;
    const isFocused = document.activeElement === textInputRef.current;
    if (!isFocused || !isChanged) {
      setRangeAndPlainWords({ setOld: true });
    }
  }, [job, range, range.words.length]);

  useEffect(() => {
    if (isCurrentPlayingRange && range.id === ranges[rangeIndex].id) {
      const rangeString = range.words.map((word) => word.word).join(" ");
      if (plainWords !== rangeString) {
        const subtitles = createVtt(ranges, true, plainWords, rangeIndex);
        MediaService.setSubtitles(subtitles);
      }
    }
  }, [isCurrentPlayingRange, plainWords]);

  const getCursorFixedPositionAfterTrim = useCallback(
    (cursorPosition: number) => {
      const isBeginningOfWord =
        cursorPosition === 0 || plainWords[cursorPosition - 1] === " ";
      const rangeFirstHalfTrimmedLength = plainWords
        .slice(0, cursorPosition)
        .trim()
        .split(" ")
        .filter((word) => word)
        .join(" ").length;
      const cursorDiff =
        rangeFirstHalfTrimmedLength +
        (isBeginningOfWord ? 1 : 0) -
        cursorPosition;
      return cursorDiff;
    },
    [plainWords]
  );

  const getSelectedWordIndex = useCallback(
    (cursorPosition: number, words: Word[]) => {
      const trimmedWordCount = plainWords
        .trim()
        .split(" ")
        .filter((word) => word).length;
      const cursorDiff =
        cursorPosition > 0
          ? getCursorFixedPositionAfterTrim(cursorPosition)
          : 0;
      let rangeWordIndex = 0;
      let wordCharIndex: number = cursorPosition + cursorDiff;

      while (
        wordCharIndex > 0 &&
        wordCharIndex > words[rangeWordIndex].word.length
      ) {
        const wordToCheck = words[rangeWordIndex].word.length;
        wordCharIndex = wordCharIndex - (wordToCheck + 1); // +1 for space
        rangeWordIndex++;
      }

      return { rangeWordIndex, wordCharIndex };
    },
    [getCursorFixedPositionAfterTrim, plainWords]
  );

  const handleBreakRange = useCallback(
    async (event: React.KeyboardEvent, breakIsolated = false) => {
      const { selectionStart } = event.target as HTMLTextAreaElement;
      const updatedRangeWords = EditorService.getRangeWordsFromMultilineString(
        range,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
        selectionStart || 0,
        updatedRangeWords
      );

      onPressEnter({
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex: rangeWordIndex,
        wordCharIndex,
        event,
        breakIsolated,
      });
    },
    [
      getSelectedWordIndex,
      oldPlainWords,
      onPressEnter,
      plainWords,
      range,
      rangeIndex,
      rangesCount,
    ]
  );
  const handleIsolatedBreakRange = useCallback(
    async (event: React.KeyboardEvent) => {
      handleBreakRange(event, true);
    },
    [handleBreakRange]
  );

  const handlePressShiftEnter = useCallback(
    async (
      event: React.KeyboardEvent,
      selectionStart: number,
      selectionEnd?: number
    ) => {
      const updatedRangeWords = EditorService.getRangeWordsFromMultilineString(
        range,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      const { rangeWordIndex, wordCharIndex } = getSelectedWordIndex(
        selectionStart || 0,
        updatedRangeWords
      );

      onPressEnter({
        rangeIndex,
        updatedRangeWords,
        selectedWordIndex: rangeWordIndex,
        wordCharIndex,
        event,
        breakIsolated: false,
      });
    },
    [
      getSelectedWordIndex,
      oldPlainWords,
      onPressEnter,
      plainWords,
      range,
      rangeIndex,
      rangesCount,
    ]
  );

  const handleMergeRange = useCallback(
    (mergeWithNextRange = false) => {
      const updatedRangeWords = EditorService.getRangeWordsFromMultilineString(
        range,
        plainWords,
        oldPlainWords,
        rangesCount
      );
      mergeRange(rangeIndex, updatedRangeWords, mergeWithNextRange);
    },
    [mergeRange, oldPlainWords, plainWords, ranges, rangeIndex, rangesCount]
  );

  const handleMergeRangeKeyStroke = useCallback(
    async (e: React.KeyboardEvent, mergeWithNextRange = false) => {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      if (rangeIndex === 0) return;

      if (selectionStart !== 0 || selectionEnd !== 0) return;
      handleMergeRange(mergeWithNextRange);
    },
    [handleMergeRange, rangeIndex]
  );

  const handleMergeWithNextRangeKeyStroke = useCallback(
    async (e: React.KeyboardEvent) => {
      const { selectionStart } = e.target as HTMLTextAreaElement;
      const textLength = _.get(e, "target.textLength");

      if (selectionStart !== textLength) return;

      handleMergeRange(true);
    },
    [handleMergeRange]
  );

  const handleDeleteRange = useCallback(async () => {
    deleteRange(rangeIndex);
  }, [deleteRange, rangeIndex]);

  const setRangeAndPlainWords = useCallback(
    ({
      rangeWords = range.words,
      isRangeEmpty = false,
      setOld = false,
    }: {
      rangeWords?: Word[];
      isRangeEmpty?: boolean;
      setOld?: boolean;
    } = {}) => {
      const rangeMultilineString = rangeWords
        .map((word, i) => {
          let wordText = word.word;
          if (_.has(word, "line_ix") && rangeWords[i + 1]) {
            const lineBreak = word.line_ix !== rangeWords[i + 1].line_ix;
            wordText = lineBreak ? `${wordText}\n` : wordText;
          }
          return wordText;
        })
        .join(" ")
        .replace(/\n /g, "\n");

      if (isRangeEmpty) {
        handleMergeRange();
        return;
      }
      setPlainWords(rangeMultilineString);
      if (setOld) setOldPlainWords(rangeMultilineString);
    },
    [handleMergeRange, range.words]
  );

  const jumpToWord = useCallback(
    (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => {
      let { selectionStart } = e.target as HTMLTextAreaElement;

      const editedWords = plainWords.split(" ");
      const lengths = editedWords.map((word) => word.length);

      let clickedWord = -1;
      while (selectionStart > 0) {
        clickedWord++;
        selectionStart = selectionStart - lengths[clickedWord] - 1;
      }
      if (selectionStart === 0) clickedWord++;

      if (range.words && range.words[clickedWord]) {
        MediaService.setOffset(range.words[clickedWord].start_time);
      }
    },
    [plainWords, range.words]
  );

  const handleJumpToWordKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      jumpToWord(e);
    },
    [jumpToWord]
  );

  const setRangeTimes = useCallback(() => {
    try {
      const start = TimeService.getTimeStringFromSecs(range.st, false, true);
      const end = TimeService.getTimeStringFromSecs(range.et, false, true);
      setRangeTimesState({
        start,
        end,
      });
    } catch (err) {
      Sentry.captureException({
        err,
        rangesCount,
        rangeLength: range.words.length,
        rangeIndex,
      });
    }
  }, [range.et, range.st, range.words.length, rangeIndex, rangesCount]);

  const handleRangeTimeChange = useCallback(
    (
      time: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      position: "start" | "end",
      method: "button" | "text"
    ) => {
      const newTime = _.isString(time) ? time : time.target.value;

      setRangeTimesState({
        ...rangeTimes,
        [position]: newTime,
      });
    },
    [rangeTimes]
  );

  const handleRangeTimeBlur = useCallback(
    (position: "start" | "end") => {
      const timeInSec = TimeService.getTimeNumberFromString(
        rangeTimes[position]
      );
      updateRangeTimes({ rangeIndex, [position]: timeInSec, method: "text" });
      setIsChanged(true);
    },
    [rangeIndex, rangeTimes, updateRangeTimes]
  );

  const addSubtractRangeTime = useCallback(
    (operator: "add" | "subtract", position: "start" | "end") => {
      let timeToAdd = MediaService.frameLength || 0.1;
      if (operator === "subtract") {
        timeToAdd = -timeToAdd;
      }

      const updatedTimeInSecs = TimeService.getFixedFrameRateTime({
        time: range[position === "start" ? "st" : "et"],
        timeToAdd,
        frameRate: MediaService.frameLength,
        operator,
      });

      const newTimeString = TimeService.getTimeStringFromSecs(
        updatedTimeInSecs,
        false,
        true
      );
      setRangeTimesState({
        ...rangeTimes,
        [position]: newTimeString,
      });

      updateRangeTimes({
        rangeIndex,
        [position]: updatedTimeInSecs,
        method: "button",
      });
    },
    [range, rangeIndex, rangeTimes, updateRangeTimes]
  );

  const updateRanges = useCallback(() => {
    if (isChanged) {
      const updatedRangeWords = EditorService.getRangeWordsFromMultilineString(
        range,
        plainWords,
        oldPlainWords,
        rangesCount
      );

      updateRangeWords(rangeIndex, updatedRangeWords);
      setOldPlainWords(plainWords);
    }

    // TrackingService.reportEvent("text_edit_end", {
    //   room_id: job.roomId,
    //   text_changed: isChanged,
    // });
  }, [
    isChanged,
    oldPlainWords,
    plainWords,
    range,
    rangeIndex,
    rangesCount,
    updateRangeWords,
  ]);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newInputString = e.target.value === "" ? "." : e.target.value;

      setIsChanged(true);
      setPlainWords(newInputString);
      // setFocusedRangeIndex(rangeIndex);
    },
    []
  );

  const handleBreakLine = useCallback(
    (e: React.KeyboardEvent) => {
      breakLineInsideRange(rangeIndex);
    },
    [breakLineInsideRange, rangeIndex]
  );

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (rangeIndex === ranges.length - 1) {
        return;
      }
      const rangeIndexToFocus = rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    },
    [rangeIndex, ranges.length]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (rangeIndex === 0) {
        return;
      }
      const rangeIndexToFocus = rangeIndex - 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    },
    [rangeIndex]
  );

  const handleArrowsKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      const textLength = _.get(e, "target.textLength");

      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.key === "ArrowLeft") ||
          (direction === "rtl" && e.key === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.key === "ArrowRight") ||
          (direction === "rtl" && e.key === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength || 0);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [direction, rangeIndex]
  );

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      SAVE_JOB: updateRanges,
      BREAK_RANGE: handleBreakRange,
      BREAK_TO_ISOLATED_RANGE: handleIsolatedBreakRange,
      BREAK_RANGE_LINE: handleBreakLine,
      MERGE_RANGE: handleMergeRangeKeyStroke,
      MERGE_WITH_NEXT_RANGE: handleMergeWithNextRangeKeyStroke,
      DELETE_ENTIRE_RANGE: handleDeleteRange,
      CURSOR_RIGHT_LEFT: handleArrowsKeystroke,
      PREVENT_CUT: EditorService.preventCut,
      JUMP_TO_WORD: handleJumpToWordKeyStroke,
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
    },
    ref: textInputRef.current,
  });

  if (!range) return null;

  if (_.isEmpty(range.words)) return null;

  /* --- Range Manipulators --- */

  /* --- Range Manipulators --- */

  /* --- Helpers --- */

  /* --- Helpers --- */

  /* --- Time Manipulators --- */

  /* --- Time Manipulators --- */

  /* --- Editor Shortcuts --- */

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (featureFlags?.useNewKeyboardShortcuts) return;
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [], false)) {
      handleBreakRange(e);
      return;
    }

    // if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [], false)) {
    //   rangeBreakLine(rangeIndex);
    //   return;
    // }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === 0]
      )
    ) {
      if (rangeIndex === 0) return;
      handleMergeRangeKeyStroke(e, false);
      return;
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Backspace"],
        [selectionStart === 0, selectionEnd === textLength, rangesCount > 1]
      )
    ) {
      //Preventing default when selecting all
      // Deletion of entire range
      // deleteRange();
      return;
    }

    if (
      handleKeyboardShortcut(e, ["Delete"], [selectionStart === textLength])
    ) {
      handleMergeRange(true); //TODO: BUG here
      // change the shortcut to something else
      // if im in the end i mergeing the next range into mine
      // focusAndSetCursor(rangeIndex, textLength);
    }

    if (
      handleKeyboardShortcut(
        e,
        ["Delete" || "Backspace"], //TODO:change this shortcut to mod+shift+backspace
        [e.ctrlKey || e.metaKey, e.shiftKey]
      )
    ) {
      handleDeleteRange(); // delete entire range
    }

    if (handleKeyboardShortcut(e, ["Tab"])) {
      if (
        (e.nativeEvent.shiftKey && rangeIndex === 0) ||
        (!e.nativeEvent.shiftKey && rangeIndex === ranges.length - 1)
      ) {
        return;
      }
      const rangeIndexToFocus = e.nativeEvent.shiftKey
        ? rangeIndex - 1
        : rangeIndex + 1;
      focusAndSetCursor(rangeIndexToFocus, 0);
    }

    if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
      const goBack =
        selectionStart === 0 &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowLeft") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowRight"));
      const goForward =
        selectionStart === textLength &&
        ((direction === "ltr" && e.nativeEvent.code === "ArrowRight") ||
          (direction === "rtl" && e.nativeEvent.code === "ArrowLeft"));

      if (goBack) {
        const rangeIndexToFocus = rangeIndex - 1;
        const rangeToFocus = document.getElementById(
          `range-${rangeIndexToFocus}`
        );
        const rangeToFocusLength = _.get(rangeToFocus, "value.length");
        focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength);
      }
      if (goForward) {
        const rangeIndexToFocus = rangeIndex + 1;
        focusAndSetCursor(rangeIndexToFocus, 0);
      }

      if (goBack || goForward) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey || e.metaKey], false)) {
      updateRanges();
    }

    if (handleKeyboardShortcut(e, ["KeyX"], [e.ctrlKey || e.metaKey])) {
      return;
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (featureFlags?.useNewKeyboardShortcuts) return;
    const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
    const textLength = _.get(e, "target.textLength");

    if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"], [e.shiftKey])) {
      handlePressShiftEnter(e, selectionStart); // break line inside a range
      return;
    }
  };

  /* --- Editor Shortcuts --- */

  /* --- Event Handlers --- */

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!e.altKey) return;
    jumpToWord(e);
  };

  const handleFocus = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // setFocusedRangeIndex(rangeIndex);
    // TrackingService.reportEvent("text_edit_start", { room_id: job.roomId });
  };

  const handleBlur = async () => {
    // setFocusedRangeIndex(-1);
    updateRanges();
  };

  /* --- Event Handlers --- */

  return (
    <div
      className={classNames("textRange", "subtitles", direction, {
        error: !_.isEmpty(range.validation?.errors),
        passed: isPassed,
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
    >
      <div className="rangeTimes subtitles">
        {featureFlags?.experimentalSubtitlesEditor ? (
          <>
            <TimerPickerReadyOnly
              className={classNames({
                ltr: direction === "ltr",
                overlapping:
                  range.validation?.errors?.overlapping_prev ||
                  range.validation?.errors?.start_after_end,
              })}
              value={range.st}
              step={MediaService.frameLength || 0.1}
              deductTime={() => addSubtractRangeTime("subtract", "start")}
              addTime={() => addSubtractRangeTime("add", "start")}
              disabled={disabled}
              key={`${range.id}_start`}
            />
            <TimerPickerReadyOnly
              className={classNames({
                ltr: direction === "ltr",
                overlapping:
                  range.validation?.errors?.overlapping_next ||
                  range.validation?.errors?.start_after_end,
              })}
              value={range.et}
              step={MediaService.frameLength || 0.1}
              deductTime={() => addSubtractRangeTime("subtract", "end")}
              addTime={() => addSubtractRangeTime("add", "end")}
              disabled={disabled}
              key={`${range.id}_end`}
            />
          </>
        ) : (
          <>
            <TimerPicker
              className={classNames({
                ltr: direction === "ltr",
                overlapping:
                  range.validation?.errors?.overlapping_prev ||
                  range.validation?.errors?.start_after_end,
              })}
              value={range.st}
              handleChange={(time: string) =>
                handleRangeTimeChange(time, "start", "text")
              }
              handleBlur={() => handleRangeTimeBlur("start")}
              step={MediaService.frameLength || 0.1}
              deductTime={() => addSubtractRangeTime("subtract", "start")}
              addTime={() => addSubtractRangeTime("add", "start")}
              disabled={disabled}
            />
            <TimerPicker
              className={classNames({
                ltr: direction === "ltr",
                overlapping:
                  range.validation?.errors?.overlapping_next ||
                  range.validation?.errors?.start_after_end,
              })}
              value={range.et}
              handleChange={(time: string) =>
                handleRangeTimeChange(time, "end", "text")
              }
              handleBlur={() => handleRangeTimeBlur("end")}
              step={MediaService.frameLength || 0.1}
              deductTime={() => addSubtractRangeTime("subtract", "end")}
              addTime={() => addSubtractRangeTime("add", "end")}
              disabled={disabled}
            />
          </>
        )}
      </div>

      <div className="rangeText">
        <div className={classNames("textContainer", { disabled })}>
          <div
            id={`rangeFocuser-${range.id}`}
            style={{ width: 1, height: 1 }}
          ></div>
          {!disabled && (
            <textarea
              id={`range-${rangeIndex}`}
              ref={textInputRef}
              className="textRangeField"
              value={plainWords}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              onClick={handleClick}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          )}
          <div className="dummyRange">{plainWords}</div>
          {range.validation && (
            <RangeValidation
              validation={range.validation}
              direction={direction}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RangeSubtitles;
