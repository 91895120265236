import config from "../config";
import axios from "axios";
import { ValidationJobTypes, ValidationsConfig } from "@sumit-platforms/types";

const validationPresetsEndpoint = `${config.server.host}/${config.server.validationPresets}`;

interface AddValidationProps {
  settings: ValidationsConfig;
  idClient: number;
  jobType: keyof ValidationJobTypes;
  isDefault: boolean;
}

interface SaveValidationProps {
  idValidationPreset: number;
  settings?: ValidationsConfig;
  jobType?: keyof ValidationJobTypes;
  isDefault?: boolean;
}

const ValidationPresetService = () => {
  const addValidationPreset = async ({
    settings,
    idClient,
    jobType,
    isDefault,
  }: AddValidationProps) => {
    try {
      const response = await axios.post(validationPresetsEndpoint, {
        settings,
        idClient,
        jobType,
        isDefault,
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const saveValidationPreset = async ({
    idValidationPreset,
    settings,
    jobType,
    isDefault,
  }: SaveValidationProps) => {
    try {
      const response = await axios.put(
        `${validationPresetsEndpoint}/${idValidationPreset}`,
        {
          settings,
          jobType,
          isDefault,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteValidationPreset = async (idValidationPreset: number) => {
    try {
      const response = await axios.delete(
        `${validationPresetsEndpoint}/${idValidationPreset}`
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return { addValidationPreset, saveValidationPreset, deleteValidationPreset };
};

export default ValidationPresetService();
