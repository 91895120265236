import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { Button, Input } from "@sumit-platforms/ui-bazar";
import { Client } from "@sumit-platforms/types";

import "./EditClientModal.scss";

export interface EditClientModalProps {
  confirm: (client: Partial<Client>) => void;
  cancel: () => void;
  client: Client;
}

export const EditClientModal: FC<EditClientModalProps> = ({
  confirm,
  cancel,
  client,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [clientData, setClientData] = useState<Partial<Client>>({
    contactName: client.contactName || "",
    contactEmail: client.contactEmail || "",
    contactPhone: client.contactPhone || "",
    yearlyTarget: client.yearlyTarget || 0,
  });

  const handleOnConfirm = async () => {
    setIsLoading(true);
    await confirm(clientData);
    setIsLoading(false);
  };

  return (
    <Grid className={"EditClientModal"} container width={"36rem"}>
      <Grid container px={4} pt={4} pb={3}>
        <Box width={"100%"}>
          <Box pb={3} display={"flex"} justifyContent={"center"}>
            <h4>{t("edit_client_details")}</h4>
          </Box>
        </Box>
      </Grid>
      <Grid container px={4} pb={3}>
        <Grid item xs={6} pb={2} px={2}>
          <Box>
            <Box className={"inputLabel"} pb={1}>
              {t("primary_contact_name")}:
            </Box>
            <Input
              value={clientData.contactName}
              type="text"
              onChange={(e) =>
                setClientData({ ...clientData, contactName: e.target.value })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6} pb={2} px={2}>
          <Box>
            <Box className={"inputLabel"} pb={1}>
              {t("annual_income_target")}:
            </Box>
            <Input
              value={clientData.yearlyTarget}
              type="number"
              min={0}
              onChange={(e) =>
                setClientData({
                  ...clientData,
                  yearlyTarget: Number(e.target.value),
                })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6} pb={2} px={2}>
          <Box>
            <Box className={"inputLabel"} pb={1}>
              {t("primary_phone_number")}:
            </Box>
            <Input
              value={clientData.contactPhone}
              type="text"
              onChange={(e) =>
                setClientData({ ...clientData, contactPhone: e.target.value })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={6} pb={2} px={2}>
          <Box>
            <Box className={"inputLabel"} pb={1}>
              {t("primary_email")}:
            </Box>
            <Input
              value={clientData.contactEmail}
              type="text"
              onChange={(e) =>
                setClientData({ ...clientData, contactEmail: e.target.value })
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button onClick={handleOnConfirm} color="primary" loading={isLoading}>
          {t("save_changes")}
        </Button>
      </Grid>
    </Grid>
  );
};
