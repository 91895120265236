import { ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Client } from "@sumit-platforms/types";
import { faCog, faPenToSquare, faList } from "@fortawesome/pro-light-svg-icons";
import { format } from "date-fns";
import { bazarTheme } from "../../bazar-theme";
import { InformationBox } from "../InformationBox/InformationBox";
import { getPriceStringWithCurrency } from "../../utils/formatters";

interface ClientOverviewProps {
  client: Client;
  onEditClientClick: () => void;
  onClientSettingsClick: () => void;
  onEditJobMetadataClick: () => void;
}

export const ClientOverview = ({
  client,
  onEditClientClick,
  onClientSettingsClick,
  onEditJobMetadataClick,
}: ClientOverviewProps) => {
  const { t } = useTranslation();
  const imageSrc = client.image || "/client.svg";
  const primaryTitle = client.name;
  const primarySubtitleStack = [
    { label: format(new Date(client.createdAt), "dd/MM/yyyy") },
  ];
  const informationStack = [
    { label: client.contactName || "" },
    { label: client.contactEmail || "" },
    { label: client.contactPhone || "" },
  ];
  const rightSectionStack = [
    {
      title: t("annual_income_target"),
      value:
        getPriceStringWithCurrency({
          currency: "ILS",
          price: client.yearlyTarget,
        }) || "-",
    },
    {
      title: t("extract_access"),
      value: t(
        client.settings?.servicesAccess?.extractVer3 ? "enabled" : "disabled"
      ),
    },
  ];
  const boxAction = [
    {
      icon: faList,
      onClick: onEditJobMetadataClick,
    },
    {
      icon: faCog,
      onClick: onClientSettingsClick,
    },
    {
      icon: faPenToSquare,
      onClick: onEditClientClick,
    },
  ];
  return (
    <ThemeProvider theme={bazarTheme}>
      <InformationBox
        boxAction={boxAction}
        imageSrc={imageSrc}
        primaryTitle={primaryTitle}
        primarySubtitleStack={primarySubtitleStack}
        leftBottomDetailsStack={informationStack}
        rightSectionStack={rightSectionStack}
      />
    </ThemeProvider>
  );
};
