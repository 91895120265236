import React, { useMemo } from "react";
import {
  Invoice,
  InvoiceUrgency,
  Job,
  JobInvoice,
  JobStatus,
} from "@sumit-platforms/types";
import { Input, MultiSelect, Switch } from "@sumit-platforms/ui-bazar";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

interface JobPaymentEditProps {
  selectedJobs: Job[];
  updatedJobInvoice: Partial<JobInvoice>;
  handleJobInvoiceChange: ({
    key,
    value,
  }: {
    key: keyof JobInvoice;
    value: any;
  }) => any;
  createNewInvoice: ({
    idClient,
    invoiceNumber,
  }: {
    idClient: number;
    invoiceNumber: string;
  }) => Promise<Invoice>;
}

const JobInvoiceEdit = ({
  selectedJobs,
  updatedJobInvoice,
  handleJobInvoiceChange,
  createNewInvoice,
}: JobPaymentEditProps) => {
  const { t } = useTranslation();

  const idClient = useMemo(() => {
    return selectedJobs[0].client?.idClient || selectedJobs[0].idClient;
  }, [selectedJobs]);

  const clientsInvoicesOptions = useMemo(() => {
    const client = selectedJobs ? selectedJobs[0].client : null;
    const options =
      client?.invoices?.map((invoice) => ({
        value: invoice.idInvoice,
        label: invoice.invoiceNumber,
      })) || [];
    const noInvoiceItem: any = {
      value: "no_invoice",
      label: t("no_invoice"),
    };
    options.unshift(noInvoiceItem);
    return options;
  }, [selectedJobs]);

  const urgencyOptions = useMemo(() => {
    return Object.entries(InvoiceUrgency).map(([key, value]) => ({
      value,
      label: _.capitalize(key),
    }));
  }, []);

  const isAllJobsDone = useMemo(() => {
    return _.every(selectedJobs, (job) => job.status === JobStatus.done);
  }, [selectedJobs]);

  const handleCreateNewInvoice = async (invoiceNumber: string) => {
    if (!idClient) return;
    try {
      const newInvoice = await createNewInvoice({ idClient, invoiceNumber });
      clientsInvoicesOptions.push({
        value: newInvoice.idInvoice,
        label: newInvoice.invoiceNumber,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"} gap={2}>
      <MultiSelect
        label={t("invoice_number")}
        className={"invoiceNumberSelectInput"}
        options={clientsInvoicesOptions}
        onChange={(e) =>
          handleJobInvoiceChange({
            key: "idInvoice",
            value: e?.target?.value,
          })
        }
        selected={[updatedJobInvoice.idInvoice || ""]}
        placeholder={t("select_invoice_number")}
        isMulti={false}
        addNewOption={{
          onApprove: handleCreateNewInvoice,
        }}
      />
      <MultiSelect
        label={t("urgency")}
        className={"urgencySelectInput"}
        options={urgencyOptions}
        onChange={(e) =>
          handleJobInvoiceChange({ key: "urgency", value: e?.target?.value })
        }
        selected={[updatedJobInvoice.urgency || ""]}
        placeholder={t("select_urgency")}
        isMulti={false}
      />
    </Box>
  );
};

export default JobInvoiceEdit;
