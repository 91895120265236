import _ from "lodash";
import { Job, JobTranslationType } from "@sumit-platforms/types";
import { TableCell } from "./TableCell";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { secondaryColor } from "../../bazar-theme";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

export const JobTypeCell = ({
  inputLanguage,
  outputLanguage,
  translation,
  autoTranscriptDelivery,
  type,
  autoTranscriptIndication,
}: Pick<
  Job,
  | "inputLanguage"
  | "outputLanguage"
  | "translation"
  | "autoTranscriptDelivery"
  | "type"
> & {
  autoTranscriptIndication: "icon" | "text";
}) => {
  const { t } = useTranslation();

  const languageBrief = useMemo(() => {
    if (_.isEmpty(inputLanguage)) return "-";

    const inputLang: string = inputLanguage[0];
    const outputLang: string = _.isEmpty(outputLanguage)
      ? inputLang
      : outputLanguage[0];

    let primaryText = outputLang;

    if (
      translation === JobTranslationType.TARGET &&
      !_.isEmpty(outputLanguage)
    ) {
      primaryText = `${inputLang} -> ${outputLang}`;
    }

    return primaryText;
  }, [translation, outputLanguage, inputLanguage]);

  const typeBrief = useMemo(() => {
    let primaryText = type.typeName ? t(type.typeName) : "-";
    if (autoTranscriptDelivery && autoTranscriptIndication === "text") {
      primaryText = `${primaryText} (${t("auto")})`;
    }
    return primaryText;
  }, [type, autoTranscriptDelivery]);

  return (
    <TableCell
      primaryText={typeBrief}
      secondaryText={t(languageBrief)}
      toolTip={
        !autoTranscriptDelivery && autoTranscriptIndication === "icon"
          ? {
              icon: <FontAwesomeIcon icon={faCheckCircle} />,
              content: <>{t("transcribe_by_sumit_ai_team")}</>,
              color: secondaryColor,
            }
          : undefined
      }
    />
  );
};
