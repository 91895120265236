import { TFunction } from "i18next";
import { SubjectsPage } from "@sumit-platforms/types";
import {
  alignOptions,
  decorationOptions,
  fontSizeOptions,
  getListTypeOptions,
} from "../shared";
import { PageSettings } from "./../../../core/SettingsPage/SettingsPage";

export const getSubjectsPageInputs: (
  args: SubjectsPage,
  t: TFunction
) => PageSettings<{
  subjects: SubjectsPage;
}> = (args, t) => ({
  settings: { subjects: args },
  pageTab: t("settings_protocol_subjects"),
  sections: [
    {
      title: t("settings_protocol_subjects"),
      paragraph: t("settings_protocol_subjects_paragraph_1"),
      titleHasActivate: true,
      inputs: [
        {
          type: "switch",
          key: "subjects.subjects.isActive",
        },
      ],
    },
    {
      disableKey: "subjects.subjects.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_subjects_sign")}:`,
          isColumn: true,
          type: "toggle",
          exclusive: true,
          key: "subjects.sign",
          buttons: getListTypeOptions(t),
        },
      ],
    },
    {
      disableKey: "subjects.subjects.isActive",
      inputs: [
        {
          label: `${t("settings_protocol_subjects_font")}:`,
          type: "select",
          options: fontSizeOptions,
          key: "subjects.font.fontSize",
        },
        {
          type: "toggle",
          exclusive: false,
          buttons: decorationOptions,
          key: "subjects.font.textDecoration",
        },
        {
          type: "toggle",
          exclusive: true,
          buttons: alignOptions,
          key: "subjects.font.align",
        },
        {
          type: "colorPicker",
          key: "subjects.font.color",
        },
      ],
    },
  ],
});
