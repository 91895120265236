import _ from "lodash";
import config from "../../config";
import React, { useEffect, useMemo, useRef } from "react";
import {
  faCircleCheck,
  faFileExport,
  faPaperclip,
  faStream,
  faTruckFast,
  faHistory,
} from "@fortawesome/pro-light-svg-icons";
import {
  AttachmentsModal,
  ConfirmModal,
  ExportJobModal,
  ModalType,
  JobRestoreModal,
  CustomModalButton,
} from "@sumit-platforms/ui-bazar";

import {
  useFeatureFlag,
  useJob,
  useNavigateOutPrompt,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { useAlert, useModal } from "@sumit-platforms/ui-bazar/store";
import {
  AttachmentService,
  exportService as ExportService,
  JobService,
  UserService,
  MediaService,
} from "@sumit-platforms/ui-bazar/services";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Grid } from "@mui/material";
import Editor from "@sumit-platforms/editor";

import {
  Attachment,
  AttachmentType,
  AutoSttRetakeStatus,
  ExportOptions,
  JobRevision,
  JobStatus,
  JobTranscriptionSource,
  JobTranslationType,
  JobWithData,
  SaveTriggers,
  SttType,
  User,
  UserRole,
  UserSettings,
} from "@sumit-platforms/types";
import {
  getJobTypesOption,
  getTcOffsetByStartTime,
} from "@sumit-platforms/ui-bazar/utils";

import { useGlobalData, useUser, useNavigationBlocker } from "../../store";
import assignmentService from "../../services/assignmentService";

import "./Job.scss";

const exportService = ExportService({ config });
const jobService = JobService({ config });
const userService = UserService({ config });
const mediaService = MediaService({ config });
const attachmentService = AttachmentService({ config });

const Job = () => {
  //GLOBAL STATE
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const unmountNavigationBlocker = useNavigateOutPrompt({
    useModal,
    onBeforeConfirm: async () => {
      await onBeforeNavigateOutConfirm();
    },
  });
  const { jobTypes, setToast } = useGlobalData();
  const { setBlockNavModal, blockNavModal, clearBlockNavModal } =
    useNavigationBlocker();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const experimentalSubtitlesEditor = useFeatureFlag(
    "experimentalSubtitlesEditor"
  );
  const timecodePickerFF = useFeatureFlag("timecodePicker_opera");
  const useNewKeyboardShortcuts = useFeatureFlag("newKeyboardShortcuts");
  const allowDownloadMedia = useMemo(
    () => user?.roles?.some((role) => role.idRole === UserRole.CS_MANAGER),
    [user]
  );

  const editorRef = useRef<{
    saveJob?: () => Promise<void>;
    setIsReadOnly?: React.Dispatch<React.SetStateAction<boolean>>;
    isChangesSaved?: boolean;
  }>(null);

  const { idJob = null } = useParams();
  const { job, setJob, jobSettings, getJobRevisions } = useJob({
    config,
    idJob: Number(idJob),
  });
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { toastInfo, toastSuccess, toastError } = useToast({ setToast });

  const onDoneConfirm = async (job: JobWithData) => {
    try {
      if (job?.activeAssignment?.task && job.idJob) {
        await saveJob(job, SaveTriggers.JOB_DONE);
        await assignmentService.doneAssignment({
          idJob: job.idJob,
          task: job?.activeAssignment?.task,
        });
      } else {
        throw "job data is corrupted.";
      }
      toastSuccess(t("done_assignment_success"));
    } catch (e) {
      toastError(t("done_assignment_failed"), e);
    } finally {
      closeModal();
      navigate("/my-jobs");
    }
  };

  const getJobDoneModalText = (job: JobWithData) => (
    <Trans
      i18nKey="done_assign_job_modal_text"
      values={{ jobName: job.name }}
      components={{ strong: <strong /> }}
    />
  );

  const handleDoneJob = async (job: JobWithData) => {
    openConfirmModal({
      title: t("done_assign_job_modal_title"),
      message: getJobDoneModalText(job),
      confirm: _.noop,
      customButtons: [
        {
          shouldShowLoader: true,
          text: t("confirm"),
          onBeforeConfirm: () => onDoneConfirm(job),
        },
      ],
    });
  };
  const jobRestoreApprove = async (revision: JobRevision) => {
    toastInfo(t("restoring_job"));
    try {
      const result = await jobService.restoreJob({
        idJobRevision: revision.idJobRevision,
      });

      const newJob = {
        ...job,
        data: result.dataJson,
      } as JobWithData;
      setJob(newJob);

      closeModal();
      toastSuccess(t("job_restored"));
    } catch (e) {
      toastError(t("job_restore_failed"));
    }
  };

  const onRestoreJobClick = (job: JobWithData) => {
    setModalContent(
      <JobRestoreModal
        idJob={job.idJob}
        onApprove={jobRestoreApprove}
        onCancel={closeModal}
        getJobRevisions={getJobRevisions}
      />
    );
  };
  const handleTranslationJobRejectStt = (job: JobWithData) => {
    openConfirmModal({
      title: t("reject_stt_modal_title_text"),
      message: t("reject_stt_modal_paragraph_text"),
      confirm: async () => await onTranslationJobRejectSttConfirm(job),
    });
  };

  const onTranslationJobRejectSttConfirm = async (job: JobWithData) => {
    await jobService.onTranslationJobRejectAutoStt(job.idJob);
    if (editorRef.current?.setIsReadOnly) {
      editorRef.current.setIsReadOnly(true);
    }
  };

  const handleOpenExportModal = () => {
    if (!job) return;

    setModalContent(
      <ExportJobModal
        jobTypes={jobTypes}
        jobType={job.type.typeName}
        jobName={`${job.name} - ${job.outputLanguage[0]}`}
        templates={job.client?.templates}
        confirm={async (fileName, options) =>
          await handleExportJob({
            jobIds: [job.idJob],
            fileName,
            format: options.format,
            showSpeakers: options.showSpeakers,
            interval: options.interval,
            flip: options.flip,
            tc: options.tc,
            idDocxTemplate: options.idDocxTemplate,
            encoding: options.encoding,
            docxDefaultSettings: options.docxDefaultSettings,
            zeroSubtitle: options.zeroSubtitle,
            emptySubtitle: options.emptySubtitle,
            autoBreak: options.autoBreak,
          })
        }
        tcOffset={getTcOffsetByStartTime(job.tcOffsets)}
        cancel={closeModal}
        selectedJobs={[job]}
        disabled={false}
      />
    );
  };

  const openAttachmentsModal = (job: JobWithData) => {
    setModalContent(
      <AttachmentsModal
        cancel={closeModal}
        removeAttachment={(idAttachment) =>
          handleAttachmentRemove(idAttachment, job)
        }
        handleDownloadAttachment={handleDownloadAttachment}
        attachments={job.attachments || []}
        upload={async (attachment: File) =>
          await handleAttachmentUpload(attachment, job)
        }
        update={async (note: string) => {
          await handleJobEdit(job.idJob, { note });
        }}
        note={job.note || ""}
      />
    );
  };

  const shouldRenderRejectSttButton = useMemo(() => {
    if (!job) return false;
    const { status, jobTranslation } = job;
    const allowedJobStatuses = [JobStatus.ready, JobStatus.transcribe];
    const isNotManualFlow = jobTranslation?.sttType !== SttType.MANUAL;
    const alreadyReceived =
      jobTranslation?.autoSttRetake === AutoSttRetakeStatus.RECEIVED;
    const isTranslationJob = job.translation === JobTranslationType.TARGET;
    const isDataImported =
      job.transcriptionSource === JobTranscriptionSource.SOURCE_FILE;
    return (
      isTranslationJob &&
      isNotManualFlow &&
      !alreadyReceived &&
      allowedJobStatuses.includes(status) &&
      !isDataImported
    );
  }, [job]);

  const jobActions = useMemo(() => {
    const _jobActions = [
      {
        key: "restoreJob",
        label: t("restore_job"),
        icon: faHistory,
        onClick: onRestoreJobClick,
      },
      {
        key: "syncTimes",
        label: t("sync_times"),
        icon: faStream,
        onClick: (job: JobWithData) =>
          openAlignerModal({ confirm: () => handleRunAligner(job) }),
        hide: !!(job && !getJobTypesOption(job).allowAligner),
      },
      {
        key: "notes",
        label: t("notes"),
        icon: faPaperclip,
        onClick: openAttachmentsModal,
      },
      {
        key: "export",
        label: t("export"),
        icon: faFileExport,
        onClick: handleOpenExportModal,
      },
    ];
    if (job?.activeAssignment?.idUser === user?.idUser) {
      _jobActions.unshift({
        key: "doneJob",
        label: t("done"),
        icon: faCircleCheck,
        onClick: handleDoneJob,
      });
    }
    if (shouldRenderRejectSttButton) {
      _jobActions.unshift({
        key: "order",
        label: t("reject_stt_button_text"),
        icon: faTruckFast,
        onClick: handleTranslationJobRejectStt,
        fullButton: true,
      } as any);
    }
    return _jobActions;
  }, [job, user]);

  // useEffect(() => {
  //   if (editorRef?.current?.saveJob) {
  //     if (!blockNavModal) {
  //       setBlockNavModal(handleNavigateOut);
  //     }
  //   }
  // }, [editorRef, handleNavigateOut]);

  useEffect(() => {
    return () => {
      clearBlockNavModal();
    };
  }, []);

  const handleDownloadAttachment = async (attachment: Attachment) => {
    await attachmentService.handleDownloadAttachment(attachment.idAttachment);
  };

  const handleJobEdit = async (
    idJob: number,
    updates: Partial<JobWithData>
  ) => {
    if (!idJob) return;
    try {
      await jobService.update([idJob], updates);
      setJob((prevJob: any) => {
        return { ...prevJob, note: updates?.note };
      });
      toastSuccess(t("job_update_success"));
    } catch (err) {
      console.log(err);
      toastError(t("job_update_failed"));
    }
  };

  const removeAttachment = async (
    idJob: number,
    idAttachment: number
  ): Promise<void> => {
    try {
      await jobService.removeAttachmentFromJob(idJob, idAttachment);
      setJob((prevJob: any) => {
        const newAttachments = prevJob.attachments.filter(
          (att: Attachment) => att.idAttachment !== idAttachment
        );
        return {
          ...prevJob,
          attachments: newAttachments,
        };
      });
      toastSuccess(t("delete_attachment_success"));
    } catch (err) {
      console.error(err);
      toastError(t("delete_attachment_failed"));
    }
  };

  const handleAttachmentRemove = (idAttachment: number, job?: JobWithData) => {
    openConfirmModal({
      title: t("delete_attachment"),
      message: t("are_you_sure"),
      confirm: async () => {
        const idJob = job?.idJob;
        if (idJob) await removeAttachment(idJob, idAttachment);
      },
    });
  };

  const uploadAttachment = async (
    type: AttachmentType,
    attachment: File,
    jobIds: number[],
    idClient: number
  ) => {
    try {
      const _attachment: Attachment = await jobService.addAttachmentToJob(
        type,
        jobIds,
        idClient,
        attachment
      );
      setJob((prevJob: any) => {
        const newAttachments = [...(prevJob.attachments || []), _attachment];
        return {
          ...prevJob,
          attachments: newAttachments,
        };
      });
      toastSuccess(t("updating_uploads_success"));
      return _attachment;
    } catch (e) {
      toastError(t("updating_uploads_fail"));
      return null;
    }
  };

  const handleAttachmentUpload = (attachment: File, job: JobWithData) => {
    const clientId = job?.client.idClient;
    return uploadAttachment(
      AttachmentType.NOTE,
      attachment,
      [job.idJob],
      clientId
    );
  };

  const downloadMedia = async (idMedia: number) => {
    const url = await mediaService.downloadMediaById(idMedia);
    if (url && typeof url === "string") {
      window.open(url, "_blank");
    } else {
      toastError(t("download_media_error"));
    }
  };

  const saveJob = async (job: JobWithData, saveMethod: SaveTriggers) => {
    if (!idJob) return;
    try {
      toastInfo(t("saving"));
      await jobService.saveJobData({
        idJob: Number(idJob),
        jobData: job.data,
        tcOffsets: job.tcOffsets,
        saveTrigger: saveMethod,
      });
      toastSuccess(t("save_success"));
    } catch (err) {
      toastError(t("save_fail"), err);
    }
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openConfirmModal = ({
    title,
    message,
    confirm,
    closeAfterConfirm = true,
    modalType,
    customButtons,
  }: {
    title: string;
    message: string | JSX.Element;
    confirm: () => void;
    closeAfterConfirm?: boolean;
    modalType?: ModalType;
    customButtons?: CustomModalButton[];
  }): void => {
    setModalType(modalType || "danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
        customButtons={customButtons}
      />
    );
  };

  const handleRunAligner = async (updatedJob: JobWithData) => {
    if (!idJob || !updatedJob) return;
    try {
      toastInfo(t("saving"));
      await jobService.saveJobData({
        idJob: Number(idJob),
        jobData: updatedJob.data,
        tcOffsets: updatedJob.tcOffsets,
        saveTrigger: SaveTriggers.ALIGNER_REQUEST,
      });
      toastInfo(t("running_aligner"));
      await jobService.runAligner(Number(idJob));
      toastSuccess(t("running_aligner_success"));
      navigate("/");
    } catch (err) {
      toastError(t("running_aligner_failed"), err);
      console.error(err);
    }
  };

  const updateUserSettings = async (newUserSettings: Partial<UserSettings>) => {
    const updatedSettings = await userService.updateUserSettings(
      newUserSettings
    );
    setUser({ ...(user as User), settings: updatedSettings });
  };

  const handleExportJob = async (
    exportArgs: ExportOptions & {
      jobIds: number[];
      fileName: string;
    }
  ) => {
    try {
      await exportService.exportJob(exportArgs);
    } catch (err) {
      toastError(t("export_failed"));
    } finally {
      closeModal();
    }
  };

  const openAlignerModal = ({
    confirm,
    closeAfterConfirm,
  }: {
    confirm: () => void;
    closeAfterConfirm?: boolean;
  }): void => {
    setModalType("info");
    setModalContent(
      <ConfirmModal
        title={t("aligner")}
        message={t("aligner_confirmation_message")}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  function handleNavigateOut({ to }: { to: string }) {
    if (editorRef?.current?.isChangesSaved) {
      return unmountNavigationBlocker({ to });
    } else {
      navigate(to);
    }
  }

  async function onBeforeNavigateOutConfirm() {
    if (editorRef.current?.saveJob) await editorRef.current?.saveJob();
  }

  return !job || !job.data || !job.media ? (
    <div>Loading Job...</div>
  ) : (
    <Grid container className="Job Page" px={2}>
      <Grid container justifyContent={"center"} py={2}>
        <Grid item xs={12} className="editorSection">
          <Editor
            job={job}
            jobSettings={jobSettings}
            setJob={(job: JobWithData) => setJob(job)}
            save={saveJob}
            showSubtitles={getJobTypesOption(job).showSubtitles}
            mode={getJobTypesOption(job).editorMode}
            jobActions={jobActions}
            toast={setAlert}
            useModal={useModal}
            ref={editorRef}
            featureFlags={{
              experimentalSubtitlesEditor,
              allowDownloadMedia,
              timecodePicker: timecodePickerFF,
              useNewKeyboardShortcuts,
            }}
            downloadMedia={downloadMedia}
            updateUserSettings={updateUserSettings}
            userSettings={user?.settings}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Job;
