import { Shortcut, ShortcutAction, ShortcutType } from "@sumit-platforms/types";

export const keyboardShortcuts: Shortcut[] = [
  {
    action: ShortcutAction.BREAK_ANNOTATION,
    keys: ["Control+Enter"],
    type: ShortcutType.EDITING,
    editorType: "protocol",
  },
  {
    action: ShortcutAction.CREATE_NEW_ANNOTATION,
    keys: ["$mod+Shift+Enter"],
    type: ShortcutType.EDITING,
    editorType: "protocol",
  },
  {
    action: ShortcutAction.BREAK_RANGE,
    keys: ["Enter"],
    type: ShortcutType.EDITING,
    editorType: "protocol",
  },
  {
    action: ShortcutAction.BREAK_TO_ISOLATED_RANGE,
    keys: ["Control+Enter"],
    type: ShortcutType.EDITING,
    editorType: "subtitles",
  },
  {
    action: ShortcutAction.BREAK_RANGE_LINE,
    keys: ["Shift+Enter"],
    type: ShortcutType.EDITING,
    editorType: "subtitles",
  },
  {
    action: ShortcutAction.CURSOR_RIGHT_LEFT,
    keys: ["ArrowLeft", "ArrowRight"],
    type: ShortcutType.NAVIGATION,
    editable: false,
    hide: true,
    editorType: "global",
  },
  {
    action: ShortcutAction.DELETE_ENTIRE_RANGE,
    keys: ["$mod+Shift+Backspace"],
    type: ShortcutType.EDITING,
    editorType: "subtitles",
  },
  {
    action: ShortcutAction.REVEAL_TRANSLATION,
    keys: ["Control+l"],
    type: ShortcutType.EDITING,
    editorType: "subtitlesTranslation",
  },
  {
    action: ShortcutAction.JUMP_TO_WORD,
    keys: ["Alt+Enter"],
    type: ShortcutType.NAVIGATION,
    editorType: "global",
  },
  {
    action: ShortcutAction.JUMP_TO_NEXT_RANGE,
    keys: ["Tab"],
    type: ShortcutType.NAVIGATION,
    editable: true, //TODO: double check that
    editorType: "global",
  },
  {
    action: ShortcutAction.JUMP_TO_PREV_RANGE,
    keys: ["Shift+tab"],
    type: ShortcutType.NAVIGATION,
    editable: true, //TODO: double check that
    editorType: "global",
  },
  {
    action: ShortcutAction.MERGE_RANGE,
    keys: ["Backspace"],
    type: ShortcutType.EDITING,
    editorType: "global",
    editable: false,
    hide: true,
  },
  {
    action: ShortcutAction.MERGE_WITH_NEXT_RANGE,
    keys: ["Delete"],
    type: ShortcutType.EDITING,
    editorType: "global",
    editable: false, //TODO: fix that to be editable
    hide: true,
  },
  {
    action: ShortcutAction.PLAY_BACKWARD,
    keys: ["Meta+,"],
    type: ShortcutType.PLAYER,
    editorType: "global",
  },
  {
    action: ShortcutAction.PLAY_FORWARD,
    keys: ["Meta+."],
    type: ShortcutType.PLAYER,
    editorType: "global",
  },
  {
    action: ShortcutAction.PREVENT_CUT,
    keys: ["Control+x", "Meta+x"],
    type: ShortcutType.EDITING,
    editable: false,
    hide: true,
    editorType: "global",
  },
  {
    action: ShortcutAction.SAVE_JOB,
    keys: ["Control+s", "Meta+s"], // $mod ?? check with dan
    type: ShortcutType.EDITING,
    editorType: "global",
  },
  {
    action: ShortcutAction.MARK_CURRENT_WORD,
    keys: ["Meta+k"],
    type: ShortcutType.TIMING,
    editorType: "global",
  },
  {
    action: ShortcutAction.TOGGLE_SPEAKERS_PANE,
    keys: ["Control+d"],
    type: ShortcutType.EDITING,
    editorType: "protocol",
  },
  {
    action: ShortcutAction.TOGGLE_PLAY,
    keys: ["Control+m", "Meta+m"],
    type: ShortcutType.PLAYER,
    editorType: "global",
  },
  {
    action: ShortcutAction.SHOW_PLAYER_CONTROLS,
    keys: ["Shift+Control+ArrowUp"],
    type: ShortcutType.PLAYER,
    editorType: "global",
  },
  {
    action: ShortcutAction.HIDE_PLAYER_CONTROLS,
    keys: ["Shift+Control+ArrowDown"],
    type: ShortcutType.PLAYER,
    editorType: "global",
  },
  {
    action: ShortcutAction.OPEN_KEYBOARD_SHORTCUTS_MODAL,
    keys: ["Shift+?"],
    type: ShortcutType.GLOBAL,
    editorType: "global",
  },
  {
    action: ShortcutAction.CLOSE_MODAL,
    keys: ["Escape"],
    type: ShortcutType.GLOBAL,
    editable: false,
  },
];

export const macosShortcuts = [
  "command+tab", // Switch between open applications
  "command+option+esc", // Force quit applications
  "command+shift+q", // Log out
  "command+option+m", // Minimize all windows
  "command+space", // Open Spotlight search
  "command+t", // Open new tab (browser)
  "command+w", // Close current tab (browser)
  "command+shift+t", // Reopen last closed tab (browser)
  "command+l", // Focus address bar (browser)
  "command+n", // Open a new window (Finder)
  "command+shift+n", // Create a new folder (Finder)
  "command+i", // Show information of selected item (Finder)
  "command+r", // Refresh or reload (Finder/browser)
  "command+p", // Print
  "command+f", // Find
  "command+h", // Hide application
  "command+option+h", // Hide other applications
  "command+d", // Duplicate (Finder)
];

export const windowsShortcuts = [
  "alt+tab", // Switch between open applications
  "ctrl+alt+delete", // Open Task Manager or security options
  "alt+f4", // Close the current window
  "windows+d", // Show desktop
  "windows+l", // Lock the screen
  "ctrl+t", // Open new tab (browser)
  "ctrl+w", // Close current tab (browser)
  "ctrl+shift+t", // Reopen last closed tab (browser)
  "ctrl+tab", // Switch to next tab (browser)
  "ctrl+shift+tab", // Switch to previous tab (browser)
  "ctrl+l", // Focus address bar (browser)
  "alt+d", // Focus address bar (browser)
  "ctrl+n", // Open a new window (File Explorer)
  "ctrl+shift+n", // Create a new folder (File Explorer)
  "alt+enter", // Open properties of the selected item (File Explorer)
  "f2", // Rename selected item (File Explorer)
  "f5", // Refresh the active window (File Explorer)
  "ctrl+p", // Print
  "ctrl+f", // Find
  "ctrl+h", // Find and replace
  "ctrl+r", // Refresh (browser)
  "ctrl+d", // Bookmark (browser)
];
