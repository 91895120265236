import { format } from "date-fns";
import _ from "lodash";

import React, { FC, MouseEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid, InputLabel, Box } from "@mui/material";

import {
  BonusType,
  Invoice,
  Job,
  JobBonus,
  JobInvoice,
  JobStatus,
  jobTypesOptions,
  ResolvedJobStatuses,
} from "@sumit-platforms/types";
import {
  Button,
  ButtonGroupOption,
  Input,
  Option,
  Switch,
  TimecodePicker,
  TimerPicker,
} from "@sumit-platforms/ui-bazar";
import {
  calculatePriceByMinutes,
  formatMinAndMaxForDatetimeLocale,
  getSecondsFromTimecode,
  getTimecodeFromSeconds,
  getTimeNumberFromString,
} from "@sumit-platforms/ui-bazar/utils";

import {
  DATE_INPUT_FORMAT,
  DATETIME_INPUT_FORMAT,
} from "@sumit-platforms/ui-bazar/constants";
import SegmentInputsGroup from "./SegmentInputsGroup";
import { useUser } from "../../../../store/user";
import JobMetadata from "./JobMetadata";
import JobInvoiceEdit from "./JobInvoiceEdit";
import "./JobEditModal.scss";
import { useFeatureFlag } from "@sumit-platforms/ui-bazar/hooks";

type PriceTypeOption = "total" | "minute";
export interface JobEditModalProps {
  confirm: ({
    newJobValue,
    jobBonus,
    jobInvoice,
  }: {
    newJobValue?: Partial<Job>;
    jobBonus?: Partial<JobBonus>;
    jobInvoice?: Partial<JobInvoice>;
  }) => void;
  cancel: () => void;
  job?: Partial<Job>;
  validations?: Option[];
  transcribePriceDisabled: boolean;
  qcPriceDisabled: boolean;
  selectedJobs: Job[];
  createNewInvoice: ({
    idClient,
    invoiceNumber,
  }: {
    idClient: number;
    invoiceNumber: string;
  }) => Promise<Invoice>;
}

export const JobEditModal: FC<JobEditModalProps> = ({
  confirm,
  cancel,
  job,
  selectedJobs,
  validations,
  transcribePriceDisabled,
  qcPriceDisabled,
  createNewInvoice,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [updatedJob, setUpdatedJob] = useState(job);
  const [isLoading, setIsLoading] = useState(false);
  const [priceType, setPriceType] = useState<PriceTypeOption>("total");
  const [bonusType, setBonusType] = useState(
    job?.jobBonus?.bonusType || BonusType.FIXED
  );
  const [updatedBonus, setUpdatedBonus] = useState(job?.jobBonus);
  const [updatedInvoice, setUpdatedInvoice] = useState<
    Partial<JobInvoice | undefined>
  >(job?.jobInvoice);

  const isSameClientJobs = useMemo(() => {
    const client = selectedJobs ? selectedJobs[0].client : null;
    return selectedJobs.every(
      (job: Job) => job?.client?.idClient === client?.idClient
    );
  }, [selectedJobs]);
  const shouldRenderMetadataInputs = useMemo(() => {
    if (isSameClientJobs) {
      const client = selectedJobs ? selectedJobs[0].client : null;
      const isClientHasJobMetadata = !_.isEmpty(client?.jobMetadata);
      return isClientHasJobMetadata;
    }
    return false;
  }, [selectedJobs]);

  const timecodePickerFF = useFeatureFlag("timecodePicker_opera");

  const formatDateTimeField = (value: any) =>
    value
      ? format(new Date(value || new Date()), DATETIME_INPUT_FORMAT)
      : undefined;

  const formatDateField = (value: any) =>
    value
      ? format(new Date(value || new Date()), DATE_INPUT_FORMAT)
      : undefined;

  const handlePriceChange = (field: keyof Job, value: string) => {
    const newPrice =
      priceType === "total"
        ? +value
        : priceType === "minute"
        ? calculatePriceByMinutes(+value, job?.duration)
        : null;
    if (newPrice) {
      handleChange(field, newPrice);
    }
  };

  const getPriceValue = (field: keyof Job) => {
    if (!updatedJob || !updatedJob[field] || !_.isArray(updatedJob.media))
      return null;
    const totalPrice = updatedJob[field] as number;
    const durationInMinutes = (updatedJob.duration || 0) / 60;
    if (!durationInMinutes) return null;
    if (priceType === "minute") {
      return +(totalPrice / durationInMinutes).toFixed(1);
    } else if (priceType === "total") {
      return totalPrice;
    }
  };

  const getBonusValue = (of: "qc" | "transcribe") => {
    if (!updatedBonus) return "";
    const localBonusInputValue = updatedBonus[of];
    if (!localBonusInputValue) return "";
    if (bonusType === BonusType.PERCENTAGE) {
      return +localBonusInputValue.toFixed(1);
    }
    if (bonusType === BonusType.FIXED) {
      return +localBonusInputValue.toFixed(1);
    }
    return updatedBonus[of] || "";
  };

  const handleBonusChange = (of: "qc" | "transcribe", newValue: any) => {
    const bonus = updatedBonus || {
      createdBy: user?.idUser,
      bonusType: bonusType,
    };
    setUpdatedBonus({ ...bonus, [of]: +newValue } as JobBonus);
  };
  const handleJobInvoiceChange = ({
    key,
    value,
  }: {
    key: keyof JobInvoice;
    value: any;
  }) => {
    setUpdatedInvoice((prev) => ({ ...prev, [key]: value } as JobInvoice));
  };

  const handleBonusSegmentChange = (
    e: MouseEvent<HTMLElement>,
    newValue: any
  ) => {
    if (!newValue) return;
    if (updatedBonus && newValue) {
      setUpdatedBonus((prev) => ({ ...prev, bonusType: newValue } as JobBonus));
    }
    setBonusType(newValue);
  };

  const jobFields = useMemo(() => {
    const allowRepresentative = _.every(
      selectedJobs,
      (job) => jobTypesOptions[job?.type?.typeName || ""]?.allowRepresentative
    );
    return [
      {
        key: "name",
        type: "text",
        inputProps: {
          label: t("name"),
        },
      },
      {
        key: "delivery",
        type: "datetime-local",
        formatter: formatDateTimeField,
        inputProps: {
          disabled: false,
          label: t("client_delivery_date"),
          min: formatMinAndMaxForDatetimeLocale(new Date()),
        },
      },
      {
        key: "transcribeDelivery",
        type: "datetime-local",
        formatter: formatDateTimeField,
        inputProps: {
          disabled: false,
          label: t("transcribe_delivery_date"),
          min: formatMinAndMaxForDatetimeLocale(new Date()),
        },
      },
      {
        key: "createdAt",
        type: "date",
        formatter: formatDateField,
        inputProps: {
          disabled: true,
          label: t("job_date"),
        },
      },
      {
        key: "validationPreset.idValidationPreset",
        type: "select",
        formatter: (value: any) => (_.isArray(value) ? value : [value]),
        inputProps: {
          label: t("validation"),
          placeholder: t("select_validation"),
          options: validations,
          disabled: _.isEmpty(validations),
        },
      },
      {
        key: "tcOffsets",
        label: t("tc_offset"),
        type: "timecode",
        formatter: (value: number[][] | null) =>
          getTimecodeFromSeconds(0, { tcOffsets: value }),
        updateFormatter: (value: number) => [[0, value]],
      },
      {
        key: "prices",
        type: "segmentInputs",
        segment: {
          onChange: (e: MouseEvent<HTMLElement>, newValue: any) => {
            if (!newValue) return;
            setPriceType(newValue);
          },
          disabled: qcPriceDisabled,
          value: priceType,
          label: t("price_by"),
          buttons: [
            { value: "total", label: t("total") },
            { value: "minute", label: t("minute"), disabled: !job },
          ],
        },
        inputs: [
          {
            key: "transcribePrice",
            value: getPriceValue("transcribePrice"),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handlePriceChange("transcribePrice", e.target.value);
            },
            suffix: t("ils"),
            label: t("transcribe_price"),
            min: 0,
            disabled: transcribePriceDisabled || !priceType,
            type: "number",
          },
          {
            key: "qcPrice",
            value: getPriceValue("qcPrice"),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handlePriceChange("qcPrice", e.target.value);
            },
            label: t("qc_price"),
            suffix: t("ils"),
            min: 0,
            type: "number",
            disabled: qcPriceDisabled || !priceType,
          },
        ],
      },
      {
        key: "bonus",
        type: "segmentInputs",
        segment: {
          onChange: handleBonusSegmentChange,
          disabled: transcribePriceDisabled,
          value: bonusType,
          label: t("bonus_type"),
          buttons: [
            { value: BonusType.FIXED, label: t("fixed") },
            { value: BonusType.PERCENTAGE, label: t("percentage") },
          ],
        },
        inputs: [
          {
            key: "job.bonus.transcribe",
            value: getBonusValue("transcribe"),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handleBonusChange(
                "transcribe",
                Math.round(parseFloat(e.target.value))
              );
            },
            suffix: bonusType === BonusType.PERCENTAGE ? "%" : t("ils"),
            label: t("transcribe_bonus"),
            min: 0,
            disabled: transcribePriceDisabled || !bonusType,
            type: "number",
          },
          {
            key: "job.bonus.qc",
            value: getBonusValue("qc"),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              handleBonusChange("qc", Math.round(parseFloat(e.target.value)));
            },
            label: t("qc_bonus"),
            suffix: bonusType === BonusType.PERCENTAGE ? "%" : t("ils"),
            min: 100,
            type: "number",
            disabled: qcPriceDisabled || !bonusType,
          },
        ],
      },
      {
        key: "deliveryPages",
        type: "number",
        hidden: !allowRepresentative,
        formatter: (number: string) =>
          _.isNil(number) ? number : _.floor(+number),
        inputProps: {
          label: t("delivery_pages"),
          min: 0,
          step: 1,
          disabled: !ResolvedJobStatuses.includes(
            job?.status || ("" as JobStatus)
          ),
        },
      },
      {
        key: "representative",
        type: "switch",
        hidden: !allowRepresentative,
        inputProps: {
          label: t("representative"),
          style: { display: "flex" },
        },
      },
      { key: "jobInvoice", type: "jobInvoice", fullWidth: true },
      {
        key: "customMetadata",
        type: "customMetadata",
        fullWidth: true,
      },
    ];
  }, [
    job?.status,
    priceType,
    qcPriceDisabled,
    updatedJob,
    bonusType,
    updatedBonus,
  ]);
  const handleChange = async (field: keyof Job, value: any) => {
    setUpdatedJob((prevJob) => {
      return {
        ...prevJob,
        [field]: value,
      };
    });
  };

  const handleOnConfirm = async () => {
    setIsLoading(true);
    try {
      await confirm({
        newJobValue: updatedJob,
        jobBonus: updatedBonus,
        jobInvoice: updatedInvoice,
      });
    } catch (e) {
      console.log("err :", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid className={"JobEditModal"} container width={"36rem"}>
      <Grid container px={4} pt={4} pb={2} width={"100%"}>
        {jobFields.map((field) => {
          if (field.hidden) return null;
          return (
            <Grid
              item
              xs={field.fullWidth ? 12 : 6}
              pb={2}
              px={2}
              key={`${field.key}-${field.type}`}
            >
              {/* Text */}
              {field.type === "text" && (
                <Input
                  {...field.inputProps}
                  value={_.get(updatedJob, field.key)}
                  onChange={(e) => handleChange(field.key, e?.target?.value)}
                />
              )}
              {/* Switch */}
              {field.type === "switch" && (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  pt={2}
                >
                  <Switch
                    {...field.inputProps}
                    checked={_.get(updatedJob, field.key)}
                    onChange={(e) =>
                      handleChange(field.key, e?.target?.checked)
                    }
                  />
                </Box>
              )}
              {/* Date */}
              {["datetime-local", "date"].includes(field.type) && (
                <Input
                  {...field.inputProps}
                  value={
                    field.formatter
                      ? field.formatter(_.get(updatedJob, field.key))
                      : _.get(updatedJob, field.key)
                  }
                  type={(field.type as "datetime-local") || "date"}
                  onChange={(e) => handleChange(field.key, e?.target?.value)}
                />
              )}
              {/* Number */}
              {field.type === "number" && (
                <Input
                  {...field.inputProps}
                  type="number"
                  value={
                    field.formatter
                      ? field.formatter(_.get(updatedJob, field.key))
                      : _.get(updatedJob, field.key)
                  }
                  onChange={(e) => handleChange(field.key, e?.target?.value)}
                />
              )}
              {/* Select */}
              {field.type === "select" && (
                <Input
                  {...field.inputProps}
                  type={"select"}
                  value={
                    field.formatter
                      ? field.formatter(_.get(updatedJob, field.key))
                      : _.get(updatedJob, field.key)
                  }
                  onChange={(e) => handleChange(field.key, e?.target?.value)}
                />
              )}
              {field.type === "segmentInputs" && (
                <SegmentInputsGroup
                  segment={{
                    disabled: field.segment?.disabled as boolean,
                    buttons: field.segment?.buttons as ButtonGroupOption[],
                    value: field.segment?.value as string,
                    label: field.segment?.label,
                    onChange: field.segment?.onChange as any,
                  }}
                  inputs={field.inputs as any}
                />
              )}
              {field.type === "timecode" && (
                <>
                  <InputLabel shrink>{field.label}</InputLabel>
                  {timecodePickerFF ? (
                    <TimecodePicker
                      value={
                        field.formatter
                          ? field.formatter(_.get(updatedJob, field.key))
                          : _.get(updatedJob, field.key)
                      }
                      handleBlur={_.noop}
                      handleChange={(value) => {
                        return handleChange(
                          field.key,
                          field.updateFormatter
                            ? field.updateFormatter(
                                getSecondsFromTimecode(value)
                              )
                            : getSecondsFromTimecode(value)
                        );
                      }}
                    />
                  ) : (
                    <TimerPicker
                      value={
                        field.formatter
                          ? field.formatter(_.get(updatedJob, field.key))
                          : _.get(updatedJob, field.key)
                      }
                      step={1}
                      handleBlur={_.noop}
                      handleChange={(value) => {
                        return handleChange(
                          field.key,
                          field.updateFormatter
                            ? field.updateFormatter(
                                getTimeNumberFromString(value)
                              )
                            : getTimeNumberFromString(value)
                        );
                      }}
                      showMillis={false}
                    />
                  )}
                </>
              )}
              {field.type === "customMetadata" &&
                shouldRenderMetadataInputs && (
                  <JobMetadata
                    selectedJobs={selectedJobs}
                    updatedJob={updatedJob || {}}
                    handleChange={handleChange}
                  />
                )}
              {field.type === "jobInvoice" && isSameClientJobs && (
                <JobInvoiceEdit
                  selectedJobs={selectedJobs}
                  updatedJobInvoice={updatedInvoice || {}}
                  handleJobInvoiceChange={handleJobInvoiceChange}
                  createNewInvoice={createNewInvoice}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        pb={4}
        gap={2}
      >
        <Button onClick={cancel} variant="outlined">
          {t("cancel")}
        </Button>
        <Button onClick={handleOnConfirm} color="primary" loading={isLoading}>
          {t("confirm")}
        </Button>
      </Grid>
    </Grid>
  );
};
