import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  HeaderFooterPage,
  IntervalsAndTimingPage,
  TableDocxConfig,
  IntervalsGeneralPage,
  IntervalsSpeakersPage,
} from "@sumit-platforms/types";
import { getGeneralPageInputs } from "./pageSettings/docx/intervals/general";
import { getHeaderFooterPageInputs } from "./pageSettings/docx/headerFooter";
import { getSpeakersPageInputs } from "./pageSettings/docx/intervals/speakers";
import { PageSettings } from "../core/SettingsPage/SettingsPage";
import { getIntervalsAndTiming } from "./pageSettings/docx/intervals/intervalsAndTiming";

export type IntervalsDocxSettingsPages =
  | {
      general: IntervalsGeneralPage;
    }
  | { headerFooter: HeaderFooterPage }
  | { speakers: IntervalsSpeakersPage }
  | { intervalsAndTiming: IntervalsAndTimingPage };

export interface UseTableDocxSettingsInput {
  settings?: TableDocxConfig;
}

export const useTableDocxSettings = ({
  settings,
}: UseTableDocxSettingsInput) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState<
    PageSettings<IntervalsDocxSettingsPages>[]
  >([
    getGeneralPageInputs(new IntervalsGeneralPage(settings?.general), t),
    getHeaderFooterPageInputs(new HeaderFooterPage(settings?.headerFooter), t),
    getSpeakersPageInputs(new IntervalsSpeakersPage(settings?.speakers), t),
    getIntervalsAndTiming(
      new IntervalsAndTimingPage(settings?.intervalsAndTiming),
      t
    ),
  ]);

  const [settingsReplica, setSettingsReplica] =
    useState<TableDocxConfig | null>(null);

  const resetPages = () => {
    if (settingsReplica) {
      setPages([
        getGeneralPageInputs(
          new IntervalsGeneralPage(settingsReplica?.general),
          t
        ),
        getHeaderFooterPageInputs(
          new HeaderFooterPage(settingsReplica?.headerFooter),
          t
        ),
        getSpeakersPageInputs(
          new IntervalsSpeakersPage(settingsReplica?.speakers),
          t
        ),
        getIntervalsAndTiming(
          new IntervalsAndTimingPage(settingsReplica?.intervalsAndTiming),
          t
        ),
      ]);
    }
  };

  useEffect(() => {
    if (settings) {
      setPages([
        getGeneralPageInputs(new IntervalsGeneralPage(settings?.general), t),
        getHeaderFooterPageInputs(
          new HeaderFooterPage(settings?.headerFooter),
          t
        ),
        getSpeakersPageInputs(new IntervalsSpeakersPage(settings?.speakers), t),
        getIntervalsAndTiming(
          new IntervalsAndTimingPage(settings?.intervalsAndTiming),
          t
        ),
      ]);
      if (!settingsReplica) {
        setSettingsReplica({ ...settings });
      }
    }
  }, [settings]);

  return { pages, setPages, resetPages };
};
