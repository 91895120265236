import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { Alert } from "@mui/material";

import { EnchancedAlert } from "../../store/alert";
import { Button } from "./../../core/Button/Button";
import { Form } from "./../../core/Form/Form";

export const ResetPasswordForm = ({ authService }: { authService: any }) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<EnchancedAlert>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnReset = async () => {
    try {
      setIsLoading(true);
      const resetPasswordDetails = resetPasswordForm.useForm.getValues();
      await authService.resetPassword(resetPasswordDetails.email);
      setAlert({
        message: t("password_reset_success"),
        severity: "success",
      });
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setAlert({ message: err.message, severity: "error" });
    }
  };

  type resetPasswordType = {
    email: string;
  };

  const resetPasswordUseForm = useForm<resetPasswordType>({
    mode: "onChange",
    shouldUnregister: false,
  });
  const resetPasswordForm = {
    fields: [
      {
        key: "email",
        label: t("email"),
        type: "text",
        required: true,
      },
    ],
    useForm: resetPasswordUseForm,
  };

  return (
    <div className="authContainer">
      <div className="headerTitle">
        <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
      </div>
      <div className="splitContainer">
        <div className="userPasswordContainer">
          <div className="styledForm">
            <Form
              className="center"
              form={resetPasswordForm}
              onSubmit={handleOnReset}
            />
            <Button onClick={handleOnReset} loading={isLoading}>
              {t("reset_password")}
            </Button>
          </div>
        </div>
        <div className="linksContainer">
          <Link className="forgotPassword" to={"/login"}>
            {t("go_back")}
          </Link>
        </div>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </div>
      <div id="verifier-container" style={{ visibility: "hidden" }}></div>
    </div>
  );
};
