import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import authService from "./../../services/authService";
import { useUser } from "../../store/user";
import { UserRole } from "@sumit-platforms/types";

const Guest = () => {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (authService.auth.currentUser && user?.roles) {
      if (
        user?.roles?.includes(UserRole.CS_MANAGER) ||
        user?.roles?.includes(UserRole.ADMIN)
      ) {
        navigate("/");
      } else {
        navigate("/my-jobs");
      }
    }
  }, [user?.roles]);
  return <Outlet />;
};

export default Guest;
