import { useEffect, useState } from "react";
import _ from "lodash";
import { Metadata } from "@sumit-platforms/types";
import { metadataStore } from "../store/metadata.store";

import MetadataService from "../services/metadataService";
import { isBefore, subHours } from "date-fns";

export const useMetadata = ({
  config,
  fields,
}: {
  config: any;
  fields?: (keyof Metadata)[];
}): { metadata: Partial<Metadata> } => {
  const metadataService = MetadataService({ config });

  const { sttModels, setMetadata, lastUpdated } = metadataStore();

  const [defaultMetadataFields] = useState(["sttModels"]);

  useEffect(() => {
    getMetadata();
  }, [config]);

  const getMetadata = async () => {
    const oneHourAgo = subHours(new Date(), 1);
    if (lastUpdated && !isBefore(lastUpdated, oneHourAgo)) return;
    const metadata = await metadataService.getMetadata(
      (fields || defaultMetadataFields) as (keyof Metadata)[]
    );
    setMetadata(metadata);
  };

  return {
    metadata: {
      sttModels,
    },
  };
};
