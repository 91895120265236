import { TFunction } from "i18next";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faUnderline,
} from "@fortawesome/pro-light-svg-icons";
import { Align, CalendarsTypes, ListType } from "@sumit-platforms/types";
import { ButtonGroupOption } from "../../core/ToggleButtonGroup/ToggleButtonGroup";
import { Option } from "../../core/MultiSelect/MultiSelect";

export const listStyleOptions: ButtonGroupOption[] = [
  {
    value: "a",
    label: "A",
  },
  {
    value: "b",
    label: "B",
  },
  {
    value: "c",
    label: "C",
  },
];

export const alignOptions: ButtonGroupOption[] = [
  {
    value: Align.left,
    label: faAlignLeft,
  },
  {
    value: Align.center,
    label: faAlignCenter,
  },
  {
    value: Align.right,
    label: faAlignRight,
  },
];

export const fontSizeOptions = [
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "16",
    value: 16,
  },
  {
    label: "17",
    value: 17,
  },
  {
    label: "18",
    value: 18,
  },
  {
    label: "19",
    value: 19,
  },
  {
    label: "20",
    value: 20,
  },
];

export const fontFamilyOptions: Option[] = [
  { label: "Arial", value: "Arial" },
  { label: "FrankRuehl", value: "FrankRuehl" },
  { label: "Narkisim", value: "Narkisim" },
  { label: "David", value: "David" },
];

export const decorationOptions: ButtonGroupOption[] = [
  {
    value: "bold",
    label: faBold,
  },
  {
    value: "underline",
    label: faUnderline,
  },
  {
    value: "italic",
    label: faItalic,
  },
];

export const getListTypeOptions: (t: TFunction) => ButtonGroupOption[] = (
  t
) => [
  {
    value: "minus",
    label: ListType.minus,
  },
  {
    value: "brackets",
    label: ListType.brackets,
  },
  {
    value: "hebrew",
    label: ListType.hebrew,
  },
  {
    value: "none",
    label: t(ListType.none),
  },
];

export const getFormatOptions: (t: TFunction) => ButtonGroupOption[] = (t) => [
  {
    value: "list",
    label: t("settings_protocol_information_list"),
  },
  {
    value: "row",
    label: t("settings_protocol_information_row"),
  },
];

export const getCalendarOptions: (t: TFunction) => ButtonGroupOption[] = (
  t
) => [
  {
    value: CalendarsTypes.regular,
    label: t("settings_protocol_information_regular_calendar"),
  },
  {
    value: CalendarsTypes.hebrew,
    label: t("settings_protocol_information_hebrew_calendar"),
  },
  {
    value: CalendarsTypes.muslim,
    label: t("settings_protocol_information_muslim_calendar"),
  },
];
