import axios from "axios";
import { Tag } from "@sumit-platforms/types";

interface TagServiceProps {
  endpoint: { host: string; tags: string };
}
export const tagService = ({ endpoint }: TagServiceProps) => {
  const endpointUrl = `${endpoint.host}/${endpoint.tags}`;
  const search = async (term: string, entity: string): Promise<Tag[]> => {
    try {
      return (
        await axios.get<Tag[]>(`${endpointUrl}/search`, {
          params: {
            term,
            entities: [entity],
          },
        })
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const get = async (entity: string): Promise<Tag[]> => {
    try {
      return (
        await axios.get<Tag[]>(`${endpointUrl}`, {
          params: {
            entities: [entity],
          },
        })
      ).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const create = async (
    data: Partial<Tag>,
    idClient: number,
    onTagProgress?: (data: any) => void
  ): Promise<Tag> => {
    try {
      const response = await axios.post(endpointUrl, { ...data, idClient });
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    search,
    create,
    get,
  };
};
