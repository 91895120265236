import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  SxProps,
  Theme,
} from "@mui/material";
import classNames from "classnames";

import "./Pager.scss";

interface PagerProps {
  pages: {
    pageTab: string;
    pageContent: JSX.Element;
  }[];
  sx?: SxProps<Theme>;
}

export const Pager = ({ pages, sx }: PagerProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleOnChangeCurrentPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  return (
    <Grid className={"Pager"} container sx={sx}>
      <Grid item xs={3}>
        <List disablePadding className="pagerPageList">
          {pages.map((p, i: number) => (
            <ListItem disablePadding key={i + "nonstep"}>
              <ListItemButton
                onClick={() => handleOnChangeCurrentPage(i)}
                className={classNames([
                  "pagerPageListButton",
                  { current: currentPage === i },
                ])}
              >
                {p.pageTab}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9} className="pageWrapper">
        {pages[currentPage].pageContent}
      </Grid>
    </Grid>
  );
};
