import axios from "axios";

const MediaService = ({ config }: { config: any }) => {
  const endpoint = `${config.server.host}/${config.server.media}`;

  const downloadMediaById = async (idMedia: number) => {
    try {
      const { data } = await axios.get(`${endpoint}/download/${idMedia}`);
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    downloadMediaById,
  };
};

export default MediaService;
