import _ from "lodash";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import React, { FC, useCallback, useMemo } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { AssignmentTask, MyAssignment } from "@sumit-platforms/types";
import { Button } from "../../core/Button/Button";
import { DATE_FORMAT_1 } from "../../constants/globals";
import {
  getPriceStringWithCurrency,
  secondsToTimeString,
} from "../../utils/formatters";
import { grayColor3 } from "../../bazar-theme";
import {
  getJobStatusVisual,
  isJobReadyForQc,
  isJobReadyForTranscribe,
} from "../../utils/helpers";
import { getJobPriceStringWithBonus } from "../../utils/prices";

interface AssignmentRequestProps {
  assignment: MyAssignment;
  onAccept: () => void;
  onReject: () => void;
}

export const AssignmentRequest: FC<AssignmentRequestProps> = ({
  assignment,
  onAccept,
  onReject,
}) => {
  const { t } = useTranslation();

  const isValidForAccept = useMemo(() => {
    return isJobReadyForQc(assignment) || isJobReadyForTranscribe(assignment);
  }, [assignment]);

  const getTaskTitle = (task: AssignmentTask) => {
    switch (task) {
      case AssignmentTask.qc: {
        return t("quality_check");
      }
      case AssignmentTask.transcriber: {
        return t("transcribe");
      }
      default:
        return "";
    }
  };

  const getIncome = useCallback(() => {
    const of =
      assignment.assignment.task === AssignmentTask.qc ? "qc" : "transcribe";
    return getJobPriceStringWithBonus(assignment.job, of);
  }, [assignment]);
  const { statusColor, statusTitle } = useMemo(
    () => getJobStatusVisual(assignment.job.status),
    [assignment]
  );

  return (
    <Box>
      <Box
        sx={{
          width: "340px",
          height: "220px",
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 3,
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Grid container sx={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  top: "-5px",
                  left: "-5px",
                  fontSize: 10,
                  color: grayColor3,
                }}
              >
                #{assignment.job.idJob}
              </span>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                mb={2}
                sx={{
                  margin: 0,
                }}
              >
                <Tooltip title={assignment.job.name} placement={"top"}>
                  <Typography
                    mb={1}
                    sx={{
                      fontSize: 16,
                      maxWidth: "100%",
                      fontWeight: "bold",
                    }}
                    className="ellipsis"
                  >
                    {assignment.job.name}
                  </Typography>
                </Tooltip>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {getTaskTitle(assignment.assignment.task)}
                </Typography>{" "}
                <Typography
                  sx={{
                    fontSize: 10,
                  }}
                >{`${format(
                  new Date(
                    assignment.job.transcribeDelivery || assignment.job.delivery
                  ),
                  DATE_FORMAT_1
                )}`}</Typography>
              </Grid>
              <Grid container sx={{ textAlign: "start" }} px={2}>
                <Grid item xs={7} mb={1}>
                  <Typography sx={{ color: grayColor3, fontSize: 12 }}>
                    {t("type")}:
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>{`${
                    assignment.job?.type.typeName
                  } (${[
                    assignment.job.inputLanguage[0],
                    assignment.job.outputLanguage[0],
                  ]
                    .filter((l) => !_.isUndefined(l))
                    .join(" - ")})`}</Typography>
                </Grid>
                <Grid item xs={4} mb={1}>
                  <Typography sx={{ color: grayColor3, fontSize: 12 }}>
                    {" "}
                    {t("duration")}:
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {secondsToTimeString(assignment.job.duration || 0)}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ color: grayColor3, fontSize: 12 }}>
                    {t("client")}:
                  </Typography>
                  <Typography sx={{ fontSize: 14 }}>
                    {assignment.job?.client?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ color: grayColor3, fontSize: 10 }}>
                    {t("income")}:
                  </Typography>
                  <Typography sx={{ fontSize: 14, textWrap: "nowrap" }}>
                    {getIncome()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  fontSize: 12,
                  height: "30px",
                  width: "75px",
                  margin: "0 28px",
                }}
                variant={"outlined"}
                onClick={onReject}
              >
                {t("reject")}
              </Button>
              <Button
                onClick={onAccept}
                disabled={!isValidForAccept}
                sx={{
                  fontSize: 12,
                  height: "30px",
                  width: "575x",
                  margin: "0 28px",
                }}
                variant={"contained"}
              >
                {t("accept")}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
