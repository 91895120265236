import React, { useMemo } from "react";
import _ from "lodash";
import { JobRevision } from "@sumit-platforms/types";
import { formatRevisionToReadableDate } from "../../utils/date";
import { Box, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import prettyBytes from "pretty-bytes";

interface RestoreJobItem {
  revision: JobRevision;
  onClick: (jobRevision: JobRevision) => void;
  disabled: boolean;
  selected: boolean;
  height: number;
}

export const RestoreJobItem = ({
  revision,
  onClick,
  disabled,
  selected,
  height,
}: RestoreJobItem) => {
  const { t } = useTranslation();

  const date = useMemo(
    () => `${formatRevisionToReadableDate(revision.createdAt)}`,
    [revision]
  );
  const trigger = useMemo(
    () => _.capitalize(t(revision.trigger.toLowerCase()) as string),
    [revision]
  );

  const userSaved = useMemo(() => {
    if (!revision.user) return "";
    const { firstName, lastName } = revision.user;
    return `${firstName} ${lastName}`;
  }, [revision]);

  const size = useMemo(() => {
    return revision.size ? prettyBytes(+revision.size || 0) : "";
  }, [revision]);

  return (
    <ListItemButton
      disabled={disabled}
      onClick={() => onClick(revision)}
      disableRipple={true}
      sx={{ height, padding: 0 }}
      selected={selected}
    >
      <ListItemText
        primary={
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography component={"span"}>
              {date}
              {size ? ` - (${size})` : ""}
            </Typography>
          </Box>
        }
        sx={{ margin: "0 8px" }}
        secondaryTypographyProps={{ component: "div" }}
        secondary={
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography
              component={"span"}
              sx={{ fontSize: 14 }}
              color="textSecondary"
            >
              {trigger}
            </Typography>
            <Box display={"inline"} textAlign="right">
              <Typography
                component={"span"}
                variant="body2"
                color="textSecondary"
              >
                {userSaved}
              </Typography>
            </Box>
          </Box>
        }
      />
    </ListItemButton>
  );
};
