import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/pro-light-svg-icons";

import classNames from "classnames";
import { Peaks } from "wavesurfer.js/types/backend";

import { JobRange } from "@sumit-platforms/types";

import MediaService from "../../services/MediaService";
import TimeService from "../../services/TimeService";

import "./WaveformRanges.scss";

interface Props {
  ranges: JobRange[];
  peaks?: Peaks;
  updateRangeTimes: (options: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text" | "waveform";
  }) => void;
  jobId: string | number;
  focusRange: (rangeIndex: number, cursorPosition: number) => void;
  initialZoomValue: number;
  showZoomOption: boolean;
  initialPlaybackPosition?: number;
  disabled?: boolean;
}

const WaveformRangesV3: React.FC<Props> = ({
  ranges,
  peaks,
  updateRangeTimes,
  jobId,
  focusRange,
  initialPlaybackPosition = 0,
  initialZoomValue,
  showZoomOption,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [zoomValue, setZoomValue] = useState<number>(initialZoomValue);
  const updateRangeTimesStart = useRef<number | null>(null);

  useEffect(() => {
    MediaService.initWaveform({ peaks });

    updateWaveformRanges();

    return () => {
      //TODO: this use effect causing memory leak
      // uncomment this:
      // window["ws"] = MediaService.wavesurfer
      // then get inside a job
      // get outside a job
      // type "ws" in the browser console to see the instance lives

      // MediaService.wavesurfer?.destroy();
      MediaService.wavesurfer = null;
    };
  }, []);

  useEffect(() => {
    MediaService.wavesurfer?.un("waveform-ready", _.noop);
    MediaService.wavesurfer?.on("waveform-ready", () => {
      setIsLoading(false);
      savePeaks();
    });
    MediaService.wavesurfer?.un("ready", _.noop);
    MediaService.wavesurfer?.on("ready", () => {
      MediaService.wavesurfer?.setCurrentTime(initialPlaybackPosition);
      if (peaks) {
        setIsLoading(false);
      }
    });

    MediaService.wavesurfer?.un("region-dblclick", _.noop);
    MediaService.wavesurfer?.on("region-dblclick", (range: JobRange) =>
      onRangeDoubleClick(Number(range.id))
    );
    MediaService.wavesurfer?.un("loading", _.noop);
    MediaService.wavesurfer?.on("loading", (progress: number) =>
      setLoadingProgress((prevProgress) =>
        prevProgress > progress ? prevProgress : progress
      )
    );

    MediaService.wavesurfer?.un("error", _.noop);
    MediaService.wavesurfer?.on("error", (error: number) => console.log(error));
  }, [MediaService.wavesurfer]);

  useEffect(() => {
    MediaService.wavesurfer?.zoom(zoomValue);
  }, [zoomValue]);

  useEffect(() => {
    const waveformRanges = ranges.map((range, rangeIndex) => ({
      rangeIndex,
      start: range.st,
      end: range.et,
    }));

    if (waveformRanges.length > 0) {
      MediaService.createWaveformRanges({ ranges: waveformRanges, disabled });
    }
  }, [ranges]);

  const updateWaveformRanges = () => {
    const waveformRanges = ranges.map((range, rangeIndex) => ({
      rangeIndex,
      start: range.st,
      end: range.et,
    }));

    if (ranges?.length > 0) {
      MediaService.createWaveformRanges({ ranges: waveformRanges, disabled });
    }

    MediaService.wavesurfer?.un("region-update-end", _.noop);
    MediaService.wavesurfer?.on("region-update-end", handleUpdateRangeTimes);

    MediaService.wavesurfer?.un("region-updated", _.noop);
    MediaService.wavesurfer?.on("region-updated", preventRangeOverlapping);
    MediaService.wavesurfer?.on("region-updated", updateRegionTimes);
  };

  const savePeaks = () => {
    const savedPeaks = localStorage.getItem(jobId.toString());
    if (savedPeaks) return;

    const peaks = _.get(MediaService, "wavesurfer.backend.mergedPeaks");
    // FirebaseService.saveRoomPeaks(jobId, peaks as number[])
    if (peaks) {
      localStorage.setItem(jobId.toString(), JSON.stringify(peaks));
    }
  };

  const preventRangeOverlapping = (region: {
    data: { startLimit: number; endLimit: number };
    start: number;
    end: number;
    isDragging: boolean;
    isResizing: boolean;
  }) => {
    if (!region.data) return;

    if (!updateRangeTimesStart.current) {
      updateRangeTimesStart.current = Date.now();
    }

    const regionLength = region.end - region.start;

    if (region.start < region.data.startLimit) {
      region.start = region.data.startLimit;
      if (region.isDragging) {
        region.end = region.data.startLimit + regionLength;
      }
    }

    if (region.end > region.data.endLimit && region.data.endLimit) {
      region.end = region.data.endLimit;
      if (region.isDragging) {
        region.start = region.data.endLimit - regionLength;
      }
    }
  };

  const updateRegionTimes = (region: {
    start: number;
    end: number;
    attributes: { rangeTimes: string };
  }) => {
    region.attributes = {
      ...region.attributes,
      rangeTimes: `${TimeService.getTimeStringFromSecs(
        region.start,
        true,
        true
      )} - ${TimeService.getTimeStringFromSecs(region.end, true, true)}`,
    };
  };

  const handleUpdateRangeTimes = ({
    id,
    start,
    end,
  }: {
    id: string;
    start: number;
    end: number;
  }) => {
    const rangeIndex = Number(id);
    const updatedRanges = [...ranges];

    const startTimeFrameRateFixed = TimeService.getFixedFrameRateTime({
      time: start,
      frameRate: MediaService.frameLength,
    });
    const endTimeFrameRateFixed = TimeService.getFixedFrameRateTime({
      time: end,
      frameRate: MediaService.frameLength,
    });

    updateRangeTimes({
      rangeIndex,
      start: startTimeFrameRateFixed,
      end: endTimeFrameRateFixed,
      method: "waveform",
    });

    reportTimeUpdate({
      oldStart: ranges[rangeIndex].st,
      newStart: start,
      oldEnd: ranges[rangeIndex].et,
      newEnd: end,
    });
  };

  const reportTimeUpdate = ({
    oldStart,
    oldEnd,
    newStart,
    newEnd,
  }: {
    [key: string]: number;
  }) => {
    const isRangeDragging = oldStart !== newStart && oldEnd !== newEnd;
    const changedPosition = isRangeDragging
      ? "both"
      : oldStart !== newStart
      ? "start"
      : "end";
    const timeDiff =
      isRangeDragging || changedPosition === "start"
        ? newStart - oldStart
        : newEnd - oldEnd;

    const eventDuration = updateRangeTimesStart.current
      ? Date.now() - updateRangeTimesStart.current
      : 0;

    // TrackingService.reportEvent("range_time_change_start", {
    //   job_type: "subtitles",
    //   room_id: jobId,
    //   time_diff: timeDiff,
    //   time_position: changedPosition,
    //   change_method: "waveform",
    //   timestamp: updateRangeTimesStart.current,
    // });

    // TrackingService.reportEvent("range_time_change_end", {
    //   job_type: "subtitles",
    //   room_id: jobId,
    //   time_diff: timeDiff,
    //   time_position: changedPosition,
    //   change_method: "waveform",
    //   event_duration: eventDuration,
    // });

    updateRangeTimesStart.current = null;
  };

  const handleZoom = (zoom: "in" | "out", val?: number) => {
    if (!MediaService.wavesurfer) return;

    const newZoomValue = val
      ? val
      : zoom === "in"
      ? zoomValue + 10
      : zoomValue - 10;

    if (newZoomValue >= 0) {
      setZoomValue(newZoomValue);
    }
  };
  const onRangeDoubleClick = (rangeIndex: number) => {
    focusRange(rangeIndex, 0);
  };

  return (
    <div className="WaveformRanges">
      <div id="waveformWrapper" className={classNames({ hidden: isLoading })}>
        {showZoomOption && (
          <div className="zoomContainer">
            <div className="zoomIn zoomButton" onClick={() => handleZoom("in")}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
            <div
              className="zoomOut zoomButton"
              onClick={() => handleZoom("out")}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </div>
            <div className="zoomValue">{zoomValue}%</div>
          </div>
        )}
        <div className="rangesContainer"></div>
        <div id="waveform" className="waveformContainer"></div>
        <div id="wave-timeline"></div>
      </div>
      {isLoading && (
        <div className="loaderContainer">
          {/* <BarLoader isAnimating={true} progress={loadingProgress} /> */}
          Loading - {loadingProgress}%...
        </div>
      )}
    </div>
  );
};

export default WaveformRangesV3;
