/* eslint @typescript-eslint/no-var-requires: "off" */
// line above is for ignoring no-require at line 24-27.
import _ from "lodash";
import axios from "axios";

import { format } from "date-fns";

import {
  Job,
  Word,
  DocxTranscriptRange,
  Media,
  Annotation,
  SpeakerRange,
  JobRange,
  SubtitlesTranslationRange,
  SubtitlesRange,
  RangeValidationConfig,
  JobTypes,
  ValidationsConfigData,
  JobData,
} from "@sumit-platforms/types";

import { saveAs } from "file-saver";

import rangeValidations from "../validations/rangeValidations";

import TimeService from "./TimeService";
import MediaService from "./MediaService";
import { generateId } from "../utils/generateId";

import * as Diff from "diff";

const logger = console;

const getTempSpeakerName = (): string => {
  // const tempSpeakerName = i18n.t("unidentified_speaker");
  const tempSpeakerName = "unidentified_speaker";
  return `${tempSpeakerName}-${(Math.random() * 10000).toFixed(0)}`;
};

const getRangeWordsFromString = (
  rangeWords: Word[],
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const fullWordsArray = rangeWords.filter((i) => i.word !== "\n"); // Removing linebreaks
  const rangeNewWords = newInputString
    .trim()
    .split(" ")
    .filter((words) => words); // Removing whitespace
  const rangeOldWords = oldInputString
    .trim()
    .split(" ")
    .filter((word) => word); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  return updatedRangeWords;
};

const preventCut = (e: React.KeyboardEvent) => {
  // prevent cut from user
  e.preventDefault();
  e.stopPropagation();
  return;
};

const getRangeWordsFromMultilineString = (
  range: JobRange,
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const rangeWords = range.words;
  const fullWordsArray = rangeWords.filter((i) => i.word !== "\n"); // Removing linebreaks

  let wordCount = 0;
  const lineBreaks = newInputString
    .trim()
    .split("\n")
    .slice(0, -1)
    .map((line) => {
      const lineLength = line.trim().split(" ").length;
      wordCount = wordCount + lineLength;
      return wordCount;
    });
  lineBreaks.unshift(0);

  const rangeNewWords = newInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace
  const rangeOldWords = oldInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  // Add line index to words
  for (const [lineIndex, lineBreak] of lineBreaks.entries()) {
    const nextLineBreak = lineBreaks[lineIndex + 1]
      ? lineBreaks[lineIndex + 1]
      : updatedRangeWords.length;
    for (let i = lineBreak; i < nextLineBreak; i++) {
      if (!updatedRangeWords[i]) continue;
      updatedRangeWords[i].line_ix = lineIndex;
    }
  }

  return updatedRangeWords;
};

// const saveUserLastPosition = ({
//   jobId,
//   cursorPosition,
//   rangeIx,
//   playbackPosition,
//   scrollOffsetTop,
// }: {
//   jobId: string;
//   cursorPosition: number;
//   rangeIx: number;
//   playbackPosition: number;
//   scrollOffsetTop: number;
// }) => {
//   const newLastPosition = {
//     cursorPosition,
//     rangeIx,
//     playbackPosition,
//     scrollOffsetTop,
//   };
//   try {
//     localStorage.setItem(
//       `${jobId}/editorLastPosition`,
//       JSON.stringify(newLastPosition)
//     );
//   } catch (err) {
//     logger.error(err, "saveUserLastPosition");
//     clearLocalStorage({ preserveSettings: true });
//     saveUserLastPosition({ ...newLastPosition, jobId });
//   }
// };

const getLastPosition = (jobId: string | number) => {
  const defaultPosition = {
    cursorPosition: 0,
    rangeIx: 0,
    playbackPosition: 0,
    scrollOffsetTop: 0,
  };
  const lastPositionRaw = localStorage.getItem(`${jobId}/editorLastPosition`);
  const lastPosition = lastPositionRaw
    ? JSON.parse(lastPositionRaw)
    : defaultPosition;

  return lastPosition;
};

const calculateNewWordsTimes = (oldWords: Word[], newWords: string[]) => {
  const words = [];
  const wordsDiff = Diff.diffArrays(
    oldWords.map((w) => w.word),
    newWords
  );

  let wordIndex = 0;
  for (let i = 0; i < wordsDiff.length; i++) {
    const diff: any = wordsDiff[i];

    if (diff.removed) {
      if (!_.get(wordsDiff, `[${i + 1}].added`)) {
        wordIndex = wordIndex + diff.value.length;
      } else if (diff[i + 1]) {
        wordIndex = wordIndex + (diff.value.length - diff[i + 1].value.length);
      }
      continue;
    }

    for (const word of diff.value) {
      const oldWordObj =
        wordIndex > oldWords.length - 1
          ? _.last(oldWords)
          : oldWords[wordIndex];
      if (!oldWordObj) throw new Error("NOT GOOD!");
      const wordObj = {
        ...oldWordObj,
        id: generateId("w_"),
        speaker: oldWordObj.speaker,
        word: word,
        text: word,

        start: -1,
        end: -1,
        range_ix: -1,
      };

      if (!diff.removed && !diff.added) {
        wordObj.start_time = oldWordObj.start_time;
        wordObj.end_time = oldWordObj.end_time;
        wordObj.range_ix = oldWordObj.range_ix;
        wordIndex++;
        words.push(wordObj);
      }

      if (diff.added) {
        wordObj.start_time = oldWordObj.start_time;
        wordObj.end_time = oldWordObj.end_time;
        wordObj.range_ix = oldWordObj.range_ix;
        wordIndex++;
        words.push(wordObj);
      }
    }
  }

  return words;
};

// const getSelectedWordsIndex = (
//   plainWords: string,
//   selectionStart: number,
//   selectionEnd: number
// ): { startWordIndex: number; endWordIndex: number } => {
//   const editedWords = plainWords.split(" ");
//   const lengths = editedWords.map((word) => word.length);

//   let start = selectionStart;

//   let startWordIndex = -1;
//   while (start > 0) {
//     startWordIndex++;
//     start = start - lengths[startWordIndex] - 1;
//   }
//   if (start === 0) startWordIndex++;

//   let end = selectionEnd;

//   let endWordIndex = -1;
//   while (end > 0) {
//     endWordIndex++;
//     end = end - lengths[endWordIndex] - 1;
//   }
//   if (end === 0) endWordIndex++;

//   return { startWordIndex, endWordIndex };
// };

const generateRangesByTimeIntervals = (
  words: Word[],
  interval: number
): number[] => {
  const intervalRanges = [0];
  let currentInterval = interval;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (word.start_time <= currentInterval) {
      continue;
    }

    while (word.start_time >= currentInterval) {
      currentInterval = currentInterval + interval;
    }

    intervalRanges.push(i);
  }

  return intervalRanges;
};

const getWordsFromRanges = (ranges: JobRange[]) => {
  let undefindSpeakerCount = 1;
  const rangesWords = _.map(ranges, (r, i) => {
    const rangeSpeaker =
      r.speakerName || `Unidentified speaker ${undefindSpeakerCount}`;
    if (!r.speakerName) {
      undefindSpeakerCount++;
    }
    return _.map(r.words, (w) => ({
      ...w,
      range_ix: i,
      speaker: rangeSpeaker,
    }));
  });
  const words = _.flatten(rangesWords);
  return words;
};

const _getExportedRangeString = (
  words: Word[],
  injectSpeakerName: boolean,
  previousSpeaker: string | null
): string => {
  let currentSpeaker = previousSpeaker;
  return words
    .map((w, i) => {
      if (
        previousSpeaker &&
        injectSpeakerName &&
        w.speaker !== currentSpeaker
      ) {
        currentSpeaker = w.speaker;
        return `${i !== 0 ? "\r\n" : ""}${currentSpeaker}: ${w.word}`;
      } else {
        return w.word;
      }
    })
    .filter((w) => !!w && !!w.trim())
    .join(" ");
};

const reorderWordsRangeIndex = (words: Word[]): Word[] => {
  let runningIndex = 0;
  let currentIndex = words[0].range_ix;

  return words.map((word) => {
    if (word.range_ix > currentIndex) {
      currentIndex = word.range_ix;
      runningIndex++;
    }

    return { ...word, range_ix: runningIndex };
  });
};

const getJobLangKey = (type: keyof JobTypes): "input" | "output" => {
  const defaultLangKey = "output";
  const jobLangKey = defaultLangKey;
  // const jobLangKey = jobTypes[type].lang || defaultLangKey;
  return jobLangKey;
};

const getLangDirection = (lang: string[] | string): "rtl" | "ltr" => {
  const rtlLangs = ["he-IL", "iw-IL", "ar"];
  if (_.isArray(lang)) {
    return _.some(rtlLangs, (l) => lang[0].startsWith(l)) ? "rtl" : "ltr";
  } else {
    return _.some(rtlLangs, (l) => lang.startsWith(l)) ? "rtl" : "ltr";
  }
};

const getSpeakersFromWords = (words: Word[]): string[] => {
  return _.uniqBy(words, (word) => word.speaker).map((word) => word.speaker);
};

const getSplitMeetingWords = (
  words: Word[],
  wordIndex: number,
  wordCharIndex: number
): Word[] => {
  if (wordCharIndex === 0 || wordCharIndex === words[wordIndex].word.length)
    return _.clone(words);

  const updatedMeetingWords: Word[] = _.clone(words);
  const word = updatedMeetingWords[wordIndex];
  const newWord = _.clone(word);
  word.word = word.word.slice(0, wordCharIndex);
  newWord.word = newWord.word.slice(wordCharIndex, newWord.word.length);
  updatedMeetingWords.splice(wordIndex + 1, 0, newWord);

  return updatedMeetingWords;
};

const resetSubtitlesRanges = (words: Word[]) => {
  return _.map(words, (w) => ({ ...w, range_ix: 0 }));
};

const createNewSpeakerRange = ({
  words,
  st,
  et,
  speakerId = null,
  speakerName = null,
  speakerNameEdit,
  annotations = [],
}: {
  words: Word[];
  six?: number;
  eix?: number;
  st?: number;
  et?: number;
  speakerId?: null | string;
  speakerName?: null | string;
  speakerNameEdit?: boolean;
  annotations?: Annotation[];
}) => {
  const startTime = st || words[0].start_time;
  const endTime = et || words[words.length - 1].end_time;
  const newSpeakerRange = {
    id: generateId("r_"),
    six: 0,
    eix: 0,
    words: words,
    st: startTime,
    et: endTime,
    speakerName,
    speakerId,
    type: "speaker",
    annotations: annotations,
  } as SpeakerRange;
  if (speakerNameEdit) {
    newSpeakerRange.speakerNameEdit = speakerNameEdit;
  }
  return newSpeakerRange;
};

const createNewAnnotation = (rangeIndex: number, annotationIndex?: number) => {
  const newAnnotation = {
    id: generateId(),
    type: "note",
    text: "",
    range_ix: rangeIndex,
    temp: true,
  } as Annotation;

  return newAnnotation;
};

const validateJobRanges = async (
  ranges: JobRange[],
  validationConfig: ValidationsConfigData
) => {
  if (_.isEmpty(ranges) || _.isEmpty(validationConfig)) {
    return ranges;
  }

  const validationTests = _.intersection(
    _.keys(validationConfig),
    _.keys(rangeValidations)
  );

  const validationResults = await Promise.all(
    _.map(validationTests, (validationTestName) => {
      if (!_.has(rangeValidations, validationTestName)) return;

      const validationTest = _.get(rangeValidations, validationTestName);
      const clientValidationOptions = _.get(
        validationConfig,
        validationTestName
      );

      const jobParams = {
        jobType: validationConfig.jobType,
        lang: validationConfig.lang,
        frameLength: MediaService.frameLength,
      };
      const validationTestOptions = _.isObject(clientValidationOptions)
        ? {
            ...clientValidationOptions,
            ...jobParams,
          }
        : false;

      return validationTest(ranges, validationTestOptions);
    })
  );

  return validationResults;
};

export const reIndexWords = (words: Word[], rangeIndex: number) => {
  let lineIndex = 0;

  return words.map((word, i) => {
    if (words[i + 1] && words[i + 1].line_ix === word.line_ix) {
      word.line_ix = lineIndex;
    } else {
      word.line_ix = lineIndex;
      lineIndex++;
    }
    word.range_ix = rangeIndex;
    return word;
  });
};

export default {
  getTempSpeakerName,
  getRangeWordsFromString,
  getRangeWordsFromMultilineString,
  reorderWordsRangeIndex,
  getLangDirection,
  getJobLangKey,
  getSpeakersFromWords,
  getSplitMeetingWords,
  resetSubtitlesRanges,
  createNewSpeakerRange,
  createNewAnnotation,
  getWordsFromRanges,
  getLastPosition,
  validateJobRanges,
  reIndexWords,
  preventCut,
};
