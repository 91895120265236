import axios from "axios";
import { Metadata } from "@sumit-platforms/types";

const MetadataService = ({ config }: { config: any }) => {
  const endpoint = `${config.server.host}/${config.server.metadata}`;
  if (!endpoint) throw new Error("no config in mediaService");

  const getMetadata = async (
    fields: (keyof Metadata)[]
  ): Promise<Partial<Metadata>> => {
    try {
      const response = await axios.post<Partial<Metadata>>(endpoint, {
        fields,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return { getMetadata };
};

export default MetadataService;
